import Vue from 'vue';
import Vuex from 'vuex';
import DemoModule from './modules/DemoModule';
import ErrorModule from './modules/ErrorModule';
import LabelsModule from './modules/LabelsModule';
import UploadModule from './modules/UploadModule';
import SettingsModule from './modules/SettingsModule';
import QuestionnaireModule from './modules/QuestionnaireModule';
import TermsAndConditionsModule from './modules/TermsAndConditionsModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    DemoModule,
    ErrorModule,
    LabelsModule,
    UploadModule,
    SettingsModule,
    QuestionnaireModule,
    TermsAndConditionsModule,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
});

export * from '@/utils/StoreAccessor';