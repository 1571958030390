import { ApiError } from "../ApiError";

export async function parseFetchResponse(
  response: Response
): Promise<any> {
  if (!response.ok) {
      const redirected: boolean = false;
      switch (response.status) {
        default:
          // don't redirect or anything
          break;
      }
      throw new ApiError(response, redirected);
  }
  return response.json();
}

export function getAuthorizationToken(headers?: {}): RequestInit {
  headers = headers || headers;
  const config: RequestInit = {
    mode: 'cors',
    headers: {
      ...headers,
    },
    credentials: 'include'
  };
  return config;
}