import { PlotPhaseName } from "@/enums";
import { AllLabels } from "@/interfaces";


export function translatePhaseName(
    labels: AllLabels,
    phaseName: PlotPhaseName
): string {
  return labels.temp.enum.casePlotPhase[phaseName]
    || labels.temp.enum.casePlotPhase['Unknown']
    || 'Unknown';
}

export function translateMachineState(
  labels: AllLabels,
  state: string,
): string {
  return labels.temp.enum.machineState[state]
    || state;
}
