export class Percent {
  
  private _percentScalar: number;

  constructor(
    percentValue: number
  ) {
    this._percentScalar = percentValue / 100;
  }

  public toPercentString(decimalPlaces: number): string {
    return this.toPercentNumber().toFixed(decimalPlaces) + '%';
  }

  public toPercentNumber(): number {
    return this._percentScalar * 100;
  }
}