
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';


@Module({ name: 'TermsAndConditionsModule', namespaced: true })
export default class TermsAndConditionsModule extends VuexModule {
    public acceptedTermsAndConditions: boolean = false;

    @Mutation
    public setAcceptedTermsAndConditions(acceptedTermsAndConditions: boolean): void {
        this.acceptedTermsAndConditions = acceptedTermsAndConditions;
    }
}
