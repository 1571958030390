import { EventName } from "../EventName";
import { BaseEvent } from "./BaseEvent";

export class ToastErrorEvent extends BaseEvent {

    public message: string;

    constructor(
        message?: string
    ) {
        super();
        this.message = message || '';
    }

    public get name(): EventName {
        return EventName.ToastError;
    }
}