import { FileProcessedErrorType } from "@/enums";
import { CaseData, CaseDataDto, PlotDataPhase, PlotDataPoint, PlotEvent, ProcessedFile, ProcessedFileDto, ProcessedFileResult, ProcessedFileResultDto, QuestionnaireResult } from "@/interfaces";
import { DateWrapper, Percent, getWebServiceUrl } from "@/utils";
import { getAuthorizationToken, getCaseMode, getPlotEventType, getPlotPhaseName, parseFetchResponse } from "@/utils/funcs";
import { v4 as uuidv4 } from 'uuid';

export class CaseApi {

  public static async submitQuestionnaire(
    result: QuestionnaireResult
  ): Promise<void> {
    const init = getAuthorizationToken({ 'Content-Type': 'application/json' });
    init.body = JSON.stringify({ result: result });
    init.method = 'post';
    await this.fetch(`cases/questionnaire`, init);
  }

  public static async processFile(
    file: File,
    fileName: string,
    captchaToken: string
  ): Promise<ProcessedFileResult> {
    const formData = new FormData();
    formData.append('data', file);
    formData.append('fileName', fileName);
    formData.append('captchaToken', captchaToken);
    const init = getAuthorizationToken();
    init.body = formData;
    init.method = 'post';
    let dto: ProcessedFileResultDto;
    try  {
      dto = await this.fetch('cases/process', init);
    } catch (e: any) {
      dto = {
        file: undefined,
        error: undefined
      };
      try {
        const json = await e.response.json();
        dto.error = json.error;
      } catch {
        // ignore parsing error
      }
    }
    
    // convert dto
    let processedFile: ProcessedFile | undefined = undefined;
    let error: FileProcessedErrorType | undefined = undefined;
    if (dto.file) {
      processedFile = {
        ...dto.file,
        events: dto.file.events.map((eventDto) => {
          const result: PlotEvent = {
            ...eventDto,
            eventType: getPlotEventType(eventDto.eventType)
          }
          return result;
        }),
        graphData: dto.file.graphData.map((plotDataPointDto) => {
          const result: PlotDataPoint = {
            ...plotDataPointDto
          };
          return result;
        })
      };
    }
    if (dto.error && Object.values(FileProcessedErrorType).includes(dto.error as FileProcessedErrorType)) {
      error = dto.error as FileProcessedErrorType;
    }
    if (!processedFile && !error) {
      error = FileProcessedErrorType.Unknown;
    }

    return {
      file: processedFile,
      error: error
    };
  }

  public static async analyzeFiles(
    processedFiles: ProcessedFile[],
    captchaToken: string
  ): Promise<CaseData[]> {
    const processedFileDtos: ProcessedFileDto[] = processedFiles.map((processedFile) => {
      const dto: ProcessedFileDto = processedFile;
      return dto;
    });
    const init = getAuthorizationToken({ 'Content-Type': 'application/json' });
    init.body = JSON.stringify({ processedFiles: processedFileDtos, captchaToken: captchaToken });
    init.method = 'post';
    const dtos: CaseDataDto[] = await this.fetch(`cases/analyze`, init);
    const cases = dtos.map((caseDto) => {
      const caseData: CaseData = {
        ...caseDto,
        id: uuidv4(),
        caseMode: getCaseMode(caseDto.caseMode),
        rawCaseMode: caseDto.caseMode,
        timeWithinRange02: new Percent(caseDto.timeWithinRange02),
        timeWithinRange05: new Percent(caseDto.timeWithinRange05),
        timeWithinRange10: new Percent(caseDto.timeWithinRange10),
        timeWithinRange20: new Percent(caseDto.timeWithinRange20),
        caseDateTime: new DateWrapper(caseDto.caseDateTime),
        events: caseDto.events.map((eventDto) => {
          const result: PlotEvent = {
            ...eventDto,
            eventType: getPlotEventType(eventDto.eventType)
          }
          return result;
        }),
        graphData: caseDto.graphData.map((plotDataPointDto) => {
          const result: PlotDataPoint = {
            ...plotDataPointDto
          };
          return result;
        }),
        phases: caseDto.phases.map((phaseDto) => {
          const result: PlotDataPhase = {
            ...phaseDto,
            phaseName: getPlotPhaseName(phaseDto.phaseName)
          }
          return result;
        })
      };

      let timeOffset = 0;

      if (caseData.graphData.length > 0) {
        // time is expected to start at 0
        timeOffset = caseData.graphData[0].timeMs;
      }
      caseData.graphData = caseData.graphData.map((row) => {
        return {
          ...row,
          timeMs: row.timeMs - timeOffset
        };
      });
      caseData.events = caseData.events.map((event) => {
        return {
          ...event,
          timeMs: event.timeMs - timeOffset
        };
      });

      return caseData;
    });
    return cases;
  }

  private static async fetch<T>(url: string, init?: RequestInit): Promise<T> {
    if (!init) {
      init = getAuthorizationToken();
    }
    const baseUrl = await getWebServiceUrl();
    const response = await fetch(`${baseUrl}/api/` + url, init);
    return parseFetchResponse(response);
  }
}