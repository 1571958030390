import { CaseData } from '@/interfaces';
import { isBrainCoolDevice, isThermogardDevice } from '@/utils';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'SettingsModule', namespaced: true })
export default class SettingsModule extends VuexModule {
  public showAlarms: boolean = false;
  public showTargetTemperature: boolean = true;
  public showBathTemperature: boolean = false;
  public showPatientTemperature: boolean = true;
  public showMachinePower: boolean = false;
  public showNotes: boolean = true;
  public showState: boolean = true;
  public multiColorPatientTemperature: boolean = true;
  public tooltipEnabled: boolean = true;
  public tooltipRight: boolean = true;
  public customPatientTemperatureRange: [number, number] | null = null;
  public tallerGraph: boolean = false;
  public useBathTemperatureRange: boolean = false;
  public use12HourClock: boolean = true;

  public showSinglePhaseModified = false;
  private caseData: CaseData | null = null;
  private showSinglePhaseValue = false;

  @Mutation
  setShowTargetTemperature(showTargetTemperature: boolean): void {
    this.showTargetTemperature = showTargetTemperature;
  }

  @Mutation
  setShowAlarms(showAlarms: boolean): void {
    this.showAlarms = showAlarms;
  }

  @Mutation
  setShowNotes(showNotes: boolean): void {
    this.showNotes = showNotes;
  }

  @Mutation
  setShowBathTemperature(showBathTemperature: boolean): void {
    this.showBathTemperature = showBathTemperature;
  }

  @Mutation
  setShowPatientTemperature(showPatientTemperature: boolean): void {
    this.showPatientTemperature = showPatientTemperature;
  }

  @Mutation
  setShowMachinePower(showMachinePower: boolean): void {
    this.showMachinePower = showMachinePower;
  }

  @Mutation
  setShowState(showState: boolean): void {
    this.showState = showState;
  }

  public get showSinglePhase(): boolean {
    let isBrainCool = false;
    let isThermogard = false;
    if (this.caseData) {
      isBrainCool = isBrainCoolDevice(this.caseData);
      isThermogard = isThermogardDevice(this.caseData);
    }
    return this.showSinglePhaseValue
      || (!isThermogard && !isBrainCool)
      || (isBrainCool && !this.showSinglePhaseModified);
  }

  @Mutation
  setShowSinglePhase(showSinglePhase: boolean): void {
    this.showSinglePhaseModified = true;
    this.showSinglePhaseValue = showSinglePhase;
  }

  @Mutation
  setCaseData(caseData: CaseData | null): void {
    this.caseData = caseData;
  }

  @Mutation
  setMultiColorPatientTemperature(multiColorPatientTemperature: boolean): void {
    this.multiColorPatientTemperature = multiColorPatientTemperature;
  }

  @Mutation
  setTooltipEnabled(tooltipEnabled: boolean): void {
    this.tooltipEnabled = tooltipEnabled;
  }

  @Mutation
  setTooltipRight(tooltipRight: boolean): void {
    this.tooltipRight = tooltipRight;
  }

  @Mutation
  setCustomPatientTemperatureRange(range: [number, number] | null): void {
    this.customPatientTemperatureRange = range;
  }

  @Mutation
  setTallerGraph(tallerGraph: boolean): void {
    this.tallerGraph = tallerGraph;
  }

  @Mutation
  setUseBathTemperatureRange(useBathTemperatureRange: boolean): void {
    this.useBathTemperatureRange = useBathTemperatureRange;
  }

  @Mutation
  setUse12HourClock(use12HourClock: boolean): void {
    this.use12HourClock = use12HourClock;
  }
}