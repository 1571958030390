import { PlotEventType, TimelineItemType } from "@/enums";
import { AllLabels, CaseData, PlotEvent, QuestionnaireResult, TimelineItem } from "@/interfaces";
import { translatePhaseName } from "./EnumTranslationFuncs";
import { DateWrapper } from "../DateWrapper";

interface TimelineItemWrapper {
  timelineItem: TimelineItem;
  index: number;
}

export function getAllTimelineItems(
  caseData: CaseData,
  questionnaireResult: QuestionnaireResult | undefined,
  labels: AllLabels
): TimelineItem[] {
  const coreFieldTimelineItems = _getCoreFieldTimelineItems(caseData, labels);
  const phaseTimelineItems = _getPhaseTimelineItems(caseData, labels);
  const alarmTimelineItems = _getAlarmTimelineItems(caseData, labels);
  const questionnaireTimelineItems = _getQuestionnaiteTimelineItems(caseData, questionnaireResult, labels);
  const allTimelineItems = coreFieldTimelineItems
    .concat(phaseTimelineItems)
    .concat(alarmTimelineItems)
    .concat(questionnaireTimelineItems);
  const allTimelineItemsWrapped: TimelineItemWrapper[] = allTimelineItems.map((item, index) => {
    return {
      timelineItem: item,
      index: index
    };
  });
  const result = allTimelineItemsWrapped
    .sort((a, b) => {
      const timeDiff = a.timelineItem.time.minus(b.timelineItem.time);
      const indexDiff = a.index - b.index;
      return timeDiff || indexDiff;
    });
  return result
    .map((wrappedItem) => wrappedItem.timelineItem);
}

function _getCoreFieldTimelineItems(
  caseData: CaseData,
  labels: AllLabels
): TimelineItem[] {
  const result: TimelineItem[] = [];
  result.push({
    time: caseData.caseDateTime,
    message: labels.temp.casesTimelineCaseStarted,
    timeMs: 0,
    type: TimelineItemType.start
  });
  return result;
}

function _getPhaseTimelineItems(
  caseData: CaseData,
  labels: AllLabels
): TimelineItem[] {
  const caseStartTime = caseData.caseDateTime;
  return caseData.phases.map((phase) => {
    const point = caseData.graphData[phase.startIndex];
    return {
      time: caseStartTime.plusMilliseconds(point.timeMs),
      message: translatePhaseName(labels, phase.phaseName) + ' ' + labels.temp.casesTimelinePhaseStarted,
      timeMs: point.timeMs,
      type: TimelineItemType.phase
    };
  });
}

function _getQuestionnaiteTimelineItems(
  caseData: CaseData,
  questionnaireResult: QuestionnaireResult | undefined,
  labels: AllLabels
): TimelineItem[] {
  const result: TimelineItem[] = [];
  const caseStartTime = caseData.caseDateTime;
  if (questionnaireResult && questionnaireResult.hospitalArrivalTime) {
    const hospitalArrivalTime = new DateWrapper(questionnaireResult.hospitalArrivalTime);
    if (!hospitalArrivalTime.isNaN()) {
      const newTimelineItem: TimelineItem = {
        time: hospitalArrivalTime,
        message: labels.temp.casesTimelineHospitalArrival,
        timeMs: hospitalArrivalTime.minus(caseStartTime),
        type: TimelineItemType.additional
      }
      result.push(newTimelineItem);
    }
  }
  return result;
}

function _getAlarmTimelineItems(
  caseData: CaseData,
  labels: AllLabels
): TimelineItem[] {
  const caseStartTime = caseData.caseDateTime;
  const result: TimelineItem[] = [];
  caseData.events.forEach((event) => {
    let message: string = '';
    switch (event.eventType) {
      case PlotEventType.Alarm:
        message = _getAlarmMessage(event, labels);
        break;
      case PlotEventType.Alert:
        message = _getAlertMessage(event, labels);
        break;
      default:
        break;
    }
    if (message) {
      const newItem: TimelineItem = {
        time: caseStartTime.plusMilliseconds(event.timeMs),
        message: message,
        timeMs: event.timeMs,
        type: TimelineItemType.alarm
      };
      result.push(newItem);
    }
  });
  return result;
}

function _getAlarmMessage(
  event: PlotEvent,
  labels: AllLabels
): string {
  const description = _getAlarmDescription(event);
  if (description) {
    return description;
  } else {
    return labels.temp.commonAlarmAlarm;
  }
}

function _getAlertMessage(
  event: PlotEvent,
  labels: AllLabels
): string {
  const description = _getAlertDescription(event);
  if (description) {
    return description;
  } else {
    return labels.temp.commonAlertAlert;
  }
}

function _getAlarmDescription(
  event: PlotEvent
): string {
  switch (event.code) {
    case -1:
      return event.customMessage;
    case 1:
      // subcode is 0
      // value1 is vars->s32AvgValue
      // value2 is vars->s32InsValue
      return 'Primary patient temp. probe (T1) disconnected';
    case 2:
      // subcode is 0
      // value1 is vars->s32AvgValue
      // value2 is vars->s32InsValue
      return 'Secondary patient temp. probe (T2) disconnected';
    case 3:
      // subcode is 0
      // value1 is prim->s32AvgValue
      // value2 is sec->s32AvgValue
      return 'Primary and secondary patient temp. probes (T1 & T2) do not agree';
    case 4:
      // subcode is 0
      // value1 is "global_io_data.dig_ip[PUMP_DOOR_SWITCH]"
      return 'Roller pump lid open';
    case 5:
      // subcode is 0
      // value1 is "global_io_data.dig_ip[SALINE_LEVEL]"
      return 'Air trap fault';
    case 6:
      // subcode is
      //   1 ALARM_SUB_CODE_REASON_SPEED_ERROR_FAST
      //   2 ALARM_SUB_CODE_REASON_SPEED_ERROR_SLOW
      //   3 ALARM_SUB_CODE_REASON_TIMEOUT
      // value1 is s32PumpRevError
      // value2 is global_io_data.ana_op[ANA_O_PUMP_RATE]
      return 'Pump failure';
    case 7:
      // subcode is 0 or 1, value1 is patient temp, value2 is coolant temp
      // subcode is 0 if from MonitorPatientTemperature
      // subcode is 1 if from
      // temp is 1 bit = 0.01 C
      return 'Patient temp. high'; // remove? no screen in Figma
    case 8:
      // subcode is 0 or 1, value1 is patient temp, value2 is coolant temp
      // subcode is 0 if from MonitorPatientTemperature
      // subcode is 1 if from
      // temp is 1 bit = 0.01 C
      return 'Patient temp. low'; // remove? no screen in Figma
    case 11:
      // subcode is 0
      // value1 and value2 are 0
      return 'Primary patient temp. probe (T1) dislodged';
    case 12:
      // subcode is 0
      // value1 and value2 are 0
      return 'Secondary patient temp. probe (T2) dislodged';
    case 13:
      // subcode is 0
      // value1 is a 16bit sensor value
      //   either GLYCOL_EMPTY (0x50) or GLYCOL_CRAZY (0x40)
      return 'Coolant empty';
    case 14:
      // subcode is 0
      // value1 is StandbyTimerVal[standby_timer_limit_index]
      //   alarm trigger in minutes (15, 60)
      // value2 is S16GetProtected(S16ID_TOTAL_STANDBY_MINUTES)
      //   elapsed minutes in standby
      return event.value2 + ' minutes in STANDBY';
    default:
      return '';
  }
}

function _getAlertDescription(
  event: PlotEvent
): string {
  switch (event.code) {
    case -1:
      return event.customMessage;
    case 1:
      return 'Roller pump lid open';
    case 2:
      return 'Primary patient temp. probe (T1) disconnected';
    case 3:
      return 'Secondary patient temp. probe (T2) disconnected';
    case 4:
      return 'Coolant level low';
    case 5:
      return 'Air trap fault';
    case 6:
      return 'PRIME button activated';
    case 7:
      return 'Patient data log full'; // remove? no screen in Figma
    case 8:
      return 'Coolant empty';
    case 10:
      return 'Factory configuration not complete';
    default:
      return '';
  }
}