import { EventName } from "../EventName";
import { BaseEvent } from "./BaseEvent";

export class ExportGraphEvent extends BaseEvent {

    public exportCsv: boolean;

    constructor(
        exportCsv?: boolean
    ) {
        super();
        this.exportCsv = exportCsv ?? true;
    }

    public get name(): EventName {
        return EventName.ExportGraph;
    }
}