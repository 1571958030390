
export async function runInParallel<T>(maxInParallel: number, items: T[], action: (item: T) => Promise<void>): Promise<void> {
    // make a shallow copy so we can modify the items array
    const itemsLeftToRun = items.slice();
    // create a new promise that we manage
    let resolveCallback: () => void;
    let rejectCallback: (reason?: any) => void;
    const promise = new Promise<void>((resolve, reject) => {
        resolveCallback = resolve;
        rejectCallback = reject;
    });
    // create variables we'll use
    let hasCompleted = false;
    let promisesInProgress: number = 0;
    // create functions we'll use
    const tryStartNextItem: () => boolean = () => {
        if (hasCompleted) {
            // we're done, don't start a new one
            return false;
        }
        if (promisesInProgress >= maxInParallel) {
            // too many in progress, don't start a new one
            return false;
        }
        if (itemsLeftToRun.length === 0) {
            // nothing left to start
            return false;
        }
        // start the next item
        const newPromise = action(itemsLeftToRun.shift()!);
        promisesInProgress++;
        newPromise
            .then(() => {
                promisesInProgress--;
                onItemCompleted();
            })
            .catch((e) => {
                // error!
                promisesInProgress--;
                tryReject(e);
            });
        return true;
    };
    const tryReject: (error: any) => void = (error: any) => {
        if (!hasCompleted) {
            hasCompleted = true;
            rejectCallback(error);
        }
    };
    const tryResolve: () => void = () => {
        if (!hasCompleted) {
            hasCompleted = true;
            resolveCallback();
        }
    };
    const onItemCompleted: () => void = () => {
        // start any items that we can
        let startedItem: boolean = false;
        do {
            startedItem = tryStartNextItem();
        } while (startedItem);
        if (promisesInProgress === 0 && itemsLeftToRun.length === 0) {
            // finished all the items!
            tryResolve();
        }
    };

    // start
    // call on item completed, that will start all the items
    onItemCompleted();

    // wait for the promise
    await promise;
}