
import { Component, Vue, Watch } from 'vue-property-decorator';
import { retry } from '@/utils/funcs';
import { errorStore, labelsStore, termsAndConditionsStore } from './store';
import { eventService, FatalErrorEvent, monitorService } from './services';
import Loader from './components/ui/Loader.vue';
import AppError from './components/ui/AppError.vue';
import ErrorPage from './pages/ErrorPage/ErrorPage.vue';
import ProductNavigation from './components/ui/ProductNavigation.vue';
import TermsAndConditionsDialog from './components/ui/TermsAndConditionsDialog.vue';

@Component({
  components: {
    AppError,
    ErrorPage,
    Loader,
    ProductNavigation,
    TermsAndConditionsDialog,
  },
  methods: {
  }
})
export default class App extends Vue {

  // local variables
  public initialized: boolean = false;
  public successfullyInitialized: boolean = false;
  public darkMode: boolean = false;

  public get errorStatusCode(): number {
    return errorStore.statusCode;
  }
  public get redirected(): boolean {
    return errorStore.redirected;
  }
  public get isErrorState(): boolean {
    return errorStore.isErrorState;
  }

  public get acceptedTermsAndConditions(): boolean {
    return termsAndConditionsStore.acceptedTermsAndConditions;
  }

  public acceptTerms(): void {
    termsAndConditionsStore.setAcceptedTermsAndConditions(true);
  }

  @Watch('darkMode', {immediate: true})
  public onDarkModeChanged() {
    this.$vuetify.theme.dark = this.darkMode;
  }

  async created(): Promise<void> {
    if (window.matchMedia) {
      this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          const dark = event.matches;
          this.darkMode = dark;
      });
    }
    this._tryInitializing();
  }

  private async _tryInitializing(): Promise<void> {
    if (!this.successfullyInitialized) {
      this.initialized = false;
      try {
        await retry(() => this._initialize(), 2);
        this.successfullyInitialized = true;
      } catch (e) {
        eventService.publish(new FatalErrorEvent(e));
      } finally {
        this.initialized = true;
      }
    }
  }

  private async _initialize(): Promise<void> {
    monitorService.initialize();
    await labelsStore.fetchLabels();
  }
}

