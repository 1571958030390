import { PlotEventType, PlotPhaseName } from "@/enums";
import { CaseData } from "@/interfaces";
import { DateWrapper, Percent, getCaseMode } from "@/utils";

const rawCaseData = {
    "caseMode": "Normothermia",
    "caseDurationMs": 47767000,
    "firstTargetTemperature": 37,
    "caseDateTime": {
        "_rawValue": "2023-06-08T20:11:56",
        "_containsTimeZone": false,
        "_date": "2023-06-09T03:11:56.000Z"
    },
    "timeWithinRange02": {
        "_percentScalar": 0.2556353839245622
    },
    "timeWithinRange05": {
        "_percentScalar": 0.9619817392605897
    },
    "timeWithinRange10": {
        "_percentScalar": 1
    },
    "timeWithinRange20": {
        "_percentScalar": 1
    },
    "timeToTargetMs02": 13260000,
    "timeToTargetMs05": 11523000,
    "timeToTargetMs10": 9033000,
    "timeToTargetMs20": 5429000,
    "deviceSerialNumber": "2046",
    "deviceType": "BrainCool / IQool",
    "alarmCount": 2,
    "warmingRate": -0.71,
    "coolingRate": 0.71,
    "inductionTimeMs": 13260000,
    "rewarmingRate": 0,
    "feverBurden": 2.637180555555569,
    "isNewPatient": true,
    "containsNoRunData": false,
    "pumpHours": -1,
    "graphData": [
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 0,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 5000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 10000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 20000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 22000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 29000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 30000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 33000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 48000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.8,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 63000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.1,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 78000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.8,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 93000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.8,
            "patientTemp": 39.8,
            "power": 0,
            "timeMs": 108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 292000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 299000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 562000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 569000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 832000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 839000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1102000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1109000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1372000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1379000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.7,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.2,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.1,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1642000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1649000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 39.7,
            "power": 0,
            "timeMs": 1728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.2,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.9,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1912000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1919000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 1998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.9,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2182000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2189000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.9,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.9,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.9,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2452000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2459000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.6,
            "power": 0,
            "timeMs": 2463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2722000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2729000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.6,
            "patientTemp": 39.5,
            "power": 0,
            "timeMs": 2883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.6,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.6,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.6,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.3,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2992000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 2999000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.3,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.2,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3262000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.8,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3269000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.8,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.8,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.9,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.4,
            "power": 0,
            "timeMs": 3453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.8,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.7,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3532000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3539000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.5,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.3,
            "power": 0,
            "timeMs": 3738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3751000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3766000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3802000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3809000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.8,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 4.9,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.8,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 3993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4059000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4072000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4079000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.3,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4342000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4349000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.2,
            "power": 0,
            "timeMs": 4413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4555000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4585000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4612000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4619000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 5.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.1,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.3,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.7,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.9,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.7,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.3,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4882000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4889000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.5,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.3,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39.1,
            "power": 0,
            "timeMs": 4998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.9,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.9,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.5,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5152000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5159000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 6.8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.1,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.2,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 7.8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.1,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.7,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5421000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 39,
            "power": 0,
            "timeMs": 5422000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5429000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.7,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.7,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.7,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5565000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5595000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.3,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.4,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5625000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.5,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.6,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5655000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.7,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.8,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.9,
            "power": 0,
            "timeMs": 5692000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 8.9,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5699000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.1,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.2,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.6,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.7,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 9.8,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.1,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.2,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.5,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.6,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.7,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 10.9,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.1,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.2,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.3,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.3,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5962000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.3,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5969000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 5988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.6,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.7,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.8,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.3,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.8,
            "power": 0,
            "timeMs": 6111000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.5,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6126000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6141000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.2,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6156000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6171000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6186000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.7,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6196000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.7,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.8,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.8,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6232000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6239000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.2,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.3,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.7,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.9,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.1,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.2,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.5,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6482000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6502000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6509000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.1,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.7,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.6,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12,
            "patientTemp": 38.7,
            "power": 0,
            "timeMs": 6618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.9,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6621000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.7,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6636000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 11.8,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6651000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6666000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.1,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6681000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.2,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6696000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.3,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6711000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.4,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6726000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.5,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6741000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.6,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.6,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.7,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.7,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6772000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6779000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.8,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 12.9,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.1,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.3,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.4,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.6,
            "power": 0,
            "timeMs": 6888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.8,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 13.9,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.2,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.3,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 6993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.4,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7042000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7049000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.7,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.8,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.9,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.1,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.2,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.2,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7225000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7255000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.3,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7285000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.8,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.7,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7312000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7319000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.4,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.2,
            "patientTemp": 38.5,
            "power": 0,
            "timeMs": 7338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7349000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7364000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.1,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7379000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.2,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7394000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7409000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7424000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7439000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.6,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7454000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.7,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7469000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.8,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7484000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 14.9,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7499000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.1,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7514000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.1,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7529000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.2,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7544000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7559000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7582000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7589000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.6,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.8,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.8,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.1,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.2,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.4,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.5,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.6,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7781000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.6,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7796000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 38.4,
            "power": 0,
            "timeMs": 7811000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7826000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.6,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.7,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7852000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7859000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.4,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.5,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.6,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 15.9,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.1,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.2,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.3,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 7998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.4,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 8013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.5,
            "patientTemp": 38.3,
            "power": 0,
            "timeMs": 8028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.6,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.6,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.7,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.8,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.8,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 16.9,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8122000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8129000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.1,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.2,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.3,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.3,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.4,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.5,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.6,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.6,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.7,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.8,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.8,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.9,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.1,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.1,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8392000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8399000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 38.2,
            "power": 0,
            "timeMs": 8433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8662000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8669000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 38.1,
            "power": 0,
            "timeMs": 8763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8932000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8939000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 8988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 9003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 38,
            "power": 0,
            "timeMs": 9018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9202000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9209000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9472000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9479000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.9,
            "power": 0,
            "timeMs": 9573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9742000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9749000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 9990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10012000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10014000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10019000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.8,
            "power": 0,
            "timeMs": 10023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10282000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10289000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.7,
            "power": 0,
            "timeMs": 10338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10552000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10559000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10822000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10829000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 10863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 10998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11092000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11099000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11362000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11369000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 11508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11632000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11639000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 11808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11902000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11909000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 11988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12172000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12179000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12442000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12449000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 12543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12712000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12719000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12989000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 12993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13252000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13259000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 13353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13522000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13529000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13792000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13799000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 13998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14062000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14069000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 14118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14332000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14339000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14602000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14609000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14781000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14796000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14811000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14826000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 14841000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14872000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14879000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14951000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14966000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14981000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 14996000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15011000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15026000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15041000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15056000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15071000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15086000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15101000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15116000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15142000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15149000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15412000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15419000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15682000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15689000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15716000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 15731000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15746000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15761000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15776000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15791000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15806000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15821000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15836000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15851000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15866000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15881000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15896000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15911000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15926000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15952000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15959000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 15993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16222000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16229000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16492000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16499000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16762000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16769000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 16998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17032000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17039000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17302000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17309000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17572000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17579000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17842000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17849000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 17973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 17988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18035000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.4,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18065000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18112000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18119000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.5,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.4,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.3,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18382000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18389000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.1,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18652000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18659000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18922000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18929000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 18993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19192000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19199000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19462000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19469000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19732000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19739000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 19990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20002000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20009000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20272000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20279000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20542000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20549000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20812000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20819000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 20898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 20913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 20943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 20988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 21033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21082000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21089000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21352000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21359000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.7,
            "power": 0,
            "timeMs": 21378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21622000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21629000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21892000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21899000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 21993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22162000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22169000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22432000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22439000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22702000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22709000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 22908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22972000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22979000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 22998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23242000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23249000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23512000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23519000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 23613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23617000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23632000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23647000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23662000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23677000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23692000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23707000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23722000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23737000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23752000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23782000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23789000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23827000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23842000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23857000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23872000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23887000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23902000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23917000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23932000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23947000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23962000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23977000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 23992000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24007000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24022000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24052000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24059000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24322000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24329000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24495000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24525000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24555000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 24592000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24599000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24862000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24869000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 24993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25132000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25139000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25402000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25409000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25672000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25679000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25716000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 25731000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25746000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25761000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25776000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25791000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25806000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25821000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25827000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25842000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25857000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25872000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25887000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25902000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25917000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25942000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25949000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 25998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26212000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26219000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26337000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26352000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26367000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26382000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26397000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26412000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26427000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26432000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26447000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26482000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26489000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26752000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26759000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 26988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27022000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27029000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27292000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27299000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27306000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27321000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27336000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27351000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27375000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 27405000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27435000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27465000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27495000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27525000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27562000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27569000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27709000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27724000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27739000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27754000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27769000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27784000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27799000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27832000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27839000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 27993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28102000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28109000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28372000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28379000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28642000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28649000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28749000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28764000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28779000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28794000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28809000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28824000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28839000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28855000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28885000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28912000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28919000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 28998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29182000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29189000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29314000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29329000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29344000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29359000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29374000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29389000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29404000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29419000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29452000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29459000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29545000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29575000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 29597000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29612000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29627000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29642000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29657000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29672000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29687000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29722000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29729000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29935000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29965000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29992000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 29999000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30262000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30269000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 30361000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30376000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30391000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 30406000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30414000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30429000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30444000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30459000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30474000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30489000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30504000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30532000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30539000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 30795000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30802000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30809000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 30988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31072000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31079000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31122000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31137000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31152000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31167000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31182000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31197000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31212000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31227000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31242000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31342000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31349000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31575000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31612000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31619000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31882000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31889000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 31923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 31998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32152000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32159000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32422000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32429000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 32493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32676000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32692000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32699000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32814000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32829000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32844000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32859000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32874000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32889000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32904000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32919000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32934000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32962000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32969000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 32988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33232000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33239000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33502000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33509000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33772000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33779000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33821000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33836000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33851000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33866000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33881000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33896000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33902000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 33917000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33932000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33947000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33962000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33977000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 33992000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34007000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34042000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34049000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34199000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34214000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34229000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34244000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34259000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34274000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34281000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34312000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34319000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34582000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34585000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34589000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34664000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34679000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34694000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34709000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34724000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34739000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34754000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34769000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34784000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34799000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34814000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34829000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34852000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34859000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 34966000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34981000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 34996000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35011000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35026000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35041000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35056000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35071000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35086000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35122000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35129000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35274000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35289000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35304000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35319000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35327000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35342000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35357000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35392000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35399000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35478000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35493000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35508000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35598000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35662000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35665000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35669000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35771000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35786000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35801000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35816000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35831000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35846000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35861000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35876000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35891000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35906000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35932000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35939000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 35958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 35988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36093000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36108000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36202000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36209000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 36288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 36348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36472000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36479000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.6,
            "power": 0,
            "timeMs": 36665000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36695000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36742000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36749000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36772000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36787000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36802000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36817000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36832000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36847000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36862000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36877000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36892000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36907000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36922000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36937000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36952000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36967000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 36990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37012000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37019000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37115000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37145000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37175000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37205000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37235000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37241000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37256000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37282000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37289000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37540000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37552000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37559000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37731000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37746000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37761000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37776000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37791000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37810000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37822000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37829000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37847000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37862000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37877000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37892000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37907000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37922000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37937000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37952000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37967000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 37997000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38012000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38027000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38042000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38057000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38080000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38092000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38099000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38299000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38314000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38329000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38350000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38362000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38369000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38419000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38434000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38449000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38464000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38479000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38494000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38509000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38524000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38539000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38554000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38569000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38584000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38599000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38632000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38639000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38851000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38866000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38890000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38902000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38909000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38966000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38981000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 38996000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39011000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39026000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39041000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39056000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39071000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39086000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39101000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39116000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39131000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39146000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39160000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39172000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39179000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39377000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39392000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39407000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39430000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39442000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39449000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39501000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39516000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39531000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 17.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39546000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39561000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39576000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39591000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39606000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.2,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39621000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.3,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39636000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39651000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39666000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.5,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39681000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39700000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39712000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39719000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.7,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.8,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 18.9,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.5,
            "power": 0,
            "timeMs": 39783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39970000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 19.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39989000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 39993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40173000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40188000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40240000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40252000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40259000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40510000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40522000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40529000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40530000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40575000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40605000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.4,
            "power": 0,
            "timeMs": 40733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40780000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40790000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40792000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40799000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40800000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 40998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41050000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41062000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41069000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41070000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41320000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41330000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41332000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41339000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41340000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41399000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41414000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41429000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41444000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41459000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41474000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41489000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41504000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41519000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41523000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41538000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41590000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41602000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41609000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41610000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41613000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41628000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41643000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41658000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41673000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41688000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41703000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41718000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41733000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41748000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41763000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41778000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41793000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41808000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41823000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 41838000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41860000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41870000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41872000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41879000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41880000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 41978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 41982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 41997000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42012000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42027000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 42042000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 42057000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 42072000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42087000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.3,
            "power": 0,
            "timeMs": 42102000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42117000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42130000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42140000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42142000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42149000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42363000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42378000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42400000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42410000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42412000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42419000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42420000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42633000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42648000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42670000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42682000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42689000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42690000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42903000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42918000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42940000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42950000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42952000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42959000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42960000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42982000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 42997000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43012000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43027000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43042000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43057000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43072000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43087000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43102000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43117000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43121000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43136000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43151000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43166000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43181000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43196000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43210000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43220000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43222000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43229000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43230000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.7,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.8,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 20.9,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.1,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.2,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.4,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43443000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43458000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43480000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43492000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43499000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.2,
            "power": 0,
            "timeMs": 43500000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43713000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43728000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43750000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43760000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43762000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43769000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43770000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43953000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43968000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43983000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 43998000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44020000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44030000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44032000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44039000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44040000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44058000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44073000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44088000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44103000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44118000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44133000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44148000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44163000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44178000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44193000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44253000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44268000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44290000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44300000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44302000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44309000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44310000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44433000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44448000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44463000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44475000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44490000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44505000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44520000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44535000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44560000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44572000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44579000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44580000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44583000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44591000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44606000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44621000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44636000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44651000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44666000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44681000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44696000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44711000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44726000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44741000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44756000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44771000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44786000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44801000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44816000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44820000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44830000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44840000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44842000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44849000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44850000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44853000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44868000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44883000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44898000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44913000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44928000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44943000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44958000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.6,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44973000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 44988000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.7,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45003000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45018000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45033000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45048000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45063000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45078000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45100000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45110000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45112000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45119000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45123000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45138000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45153000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45168000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45183000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 45198000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45213000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45228000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45243000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45258000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45273000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45288000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45303000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45318000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45333000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45348000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45360000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45370000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45380000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45382000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45389000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45390000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45393000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45408000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45423000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45438000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45453000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45468000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45483000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45498000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45513000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45528000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45543000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45558000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45573000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45588000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45603000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45618000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45630000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45640000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45652000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45659000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45660000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45663000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45678000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45693000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45708000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45723000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45738000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45753000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45768000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45783000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45798000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45813000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45828000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45843000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45858000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45873000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45888000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45900000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45910000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45920000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45922000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45929000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45930000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45933000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45948000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45963000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 45978000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 45993000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 46008000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 46023000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46038000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46053000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46068000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46083000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46098000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46113000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46128000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46143000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46158000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46170000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46190000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46192000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46199000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46200000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46203000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46218000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46233000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46248000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46263000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46278000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46293000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46308000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46323000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46338000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46353000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46368000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46383000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46398000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46413000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46428000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46440000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46450000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46460000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46462000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46469000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46470000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46473000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46488000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46503000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46518000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46533000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46548000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46563000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46578000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46593000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46608000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46623000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46638000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46653000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46668000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46683000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46698000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46710000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46720000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46730000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46732000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46739000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46740000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46743000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46758000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46773000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46788000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46803000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46818000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46833000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46848000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46863000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46878000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46893000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46908000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46923000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 46938000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 46951000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 46954000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 46969000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 46980000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.6,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 46990000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47000000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47002000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47009000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47010000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47013000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47028000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47043000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47044000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47045000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.7,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47060000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47075000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47090000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47105000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47120000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47135000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47150000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47165000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47180000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47195000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 27,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47208000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47223000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47238000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47250000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47260000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47270000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47272000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47279000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47280000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.9,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47283000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47298000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 47313000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.6,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47328000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.2,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 47343000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 37.1,
            "power": 0,
            "timeMs": 47358000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47373000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.1,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47388000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.8,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47403000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47418000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47429000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 47444000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47459000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 21.9,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47464000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47479000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47494000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47509000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47524000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 47539000,
            "state": "Standby",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47550000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47553000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47568000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47569000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.1,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47570000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.2,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47585000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.3,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47600000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47615000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47620000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.5,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47635000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 22.9,
            "patientTemp": 36.9,
            "power": 0,
            "timeMs": 47650000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47665000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 23.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47680000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.4,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47695000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 24.9,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47707000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47722000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47737000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47752000,
            "state": "Run",
            "controlMode": "N/A"
        },
        {
            "targetTemp": 37,
            "bathTemp": 25.3,
            "patientTemp": 36.8,
            "power": 0,
            "timeMs": 47767000,
            "state": "Run",
            "controlMode": "N/A"
        }
    ],
    "events": [
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": "Start of cooling session"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Serial number: 2046"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Software versions: SW: 2.00.02/1.08/1.01"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Hypothermia settings: Temperature 35.0°C, Duration 00:00h"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Normothermia settings: Temperature 37.0°C, Duration 20:00h, Rate 0.10°C"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Treatment phase: CoolingDown"
        },
        {
            "timeMs": -1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Pads connected: --, Torso, Thigh"
        },
        {
            "timeMs": 0,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Induction phase ongoing."
        },
        {
            "timeMs": 0,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Treatment phase: Hold"
        },
        {
            "timeMs": 1000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Normothermia phase ongoing."
        },
        {
            "timeMs": 720000,
            "eventType": "Alarm",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " The patient temperature is high."
        },
        {
            "timeMs": 47463000,
            "eventType": "Alarm",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " The patient temperature (sensor 1) is not connected."
        },
        {
            "timeMs": 47464000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Treatment paused by alarm"
        },
        {
            "timeMs": 47550000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Treatment continued"
        },
        {
            "timeMs": 47720000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": " Alarm sound is paused by user"
        },
        {
            "timeMs": 47772000,
            "eventType": "Alert",
            "code": -1,
            "subCode": -1,
            "value1": 0,
            "value2": 0,
            "customMessage": "End of cooling session"
        }
    ],
    "phases": [
        {
            "phaseName": "Induction",
            "startIndex": 0,
            "endIndex": 1097
        },
        {
            "phaseName": "Maintenance",
            "startIndex": 1097,
            "endIndex": 3959
        }
    ],
    "files": [
        "BrainCoolLog_20230608_201155!36.dat"
    ],
    "headPadConnected": false,
    "torsoPadConnected": true,
    "thighPadConnected": true,
    "id": "2a735ebb-54fa-44d8-a907-201dff2d3ed0",
    "rawCaseMode": "NormothermiaCooling",
    "documentKeys": []
};

function _convertDateWrapper(rawDateWrapper: {
    _rawValue: string,
    _containsTimeZone: boolean,
    _date: string,
}): DateWrapper {
    return new DateWrapper(rawDateWrapper._rawValue);
}

function _convertPercent(rawPercent: {
    _percentScalar: number
}): Percent {
    return new Percent(rawPercent._percentScalar * 100);
}

export const brainCoolDemoCaseData: CaseData = {
    ...rawCaseData,
    caseMode: getCaseMode(rawCaseData.caseMode),
    caseDateTime: _convertDateWrapper(rawCaseData.caseDateTime),
    timeWithinRange02: _convertPercent(rawCaseData.timeWithinRange02),
    timeWithinRange05: _convertPercent(rawCaseData.timeWithinRange05),
    timeWithinRange10: _convertPercent(rawCaseData.timeWithinRange10),
    timeWithinRange20: _convertPercent(rawCaseData.timeWithinRange20),
    events: rawCaseData.events.map((rawEvent) => {
        return {
            ...rawEvent,
            eventType: rawEvent.eventType as PlotEventType
        };
    }),
    phases: rawCaseData.phases.map((rawPhase) => {
        return {
            ...rawPhase,
            phaseName: rawPhase.phaseName as PlotPhaseName
        };
    })
};