import { CaseApi } from "@/api";
import { FileProcessedErrorType, FileUploadStatusEnum } from "@/enums";
import { FileUploadStatus, ProcessedFile } from "@/interfaces";
import { termsAndConditionsStore, uploadStore } from "@/store";
import { runInParallel } from "@/utils";
import { captchaService } from "./CaptchaService";

export class UploadService {
  public async analyzeFiles(): Promise<void> {
    const processedFiles: ProcessedFile[] = [];
    const captchaToken = await captchaService.checkCaptcha();
    uploadStore.files.forEach((fileUploadStatus) => {
      if (fileUploadStatus.status === FileUploadStatusEnum.complete && fileUploadStatus.processedFile) {
        processedFiles.push(fileUploadStatus.processedFile);
      }
    });

    if (uploadStore.filesChangedSinceAnalyze) {
      const cases = await CaseApi.analyzeFiles(processedFiles, captchaToken);
      uploadStore.setCases(cases);
    }
    uploadStore.showSelectCasePage();
  }

  public async uploadFiles(): Promise<void> {
    // make sure they accepted the terms and conditions and didn't just hide the dialog or something
    if (!termsAndConditionsStore.acceptedTermsAndConditions) {
      // they didn't accept the terms and conditions
      // something malicious is going on, refresh the page
      window.location.reload();
      return;
    }
    const captchaToken = await captchaService.checkCaptcha();
    const fileUploadStatuses = uploadStore.files;
    await runInParallel(2, fileUploadStatuses, async (fileUploadStatus: FileUploadStatus) => {
      await this.tryUploadFile(fileUploadStatus, captchaToken);
    });
    // TODO: notify if no new files were uploaded?
  }

  public async remove(fileUploadStatus: FileUploadStatus): Promise<void> {
    uploadStore.removeFile(fileUploadStatus);
  }

  public async tryUploadFile(fileUploadStatus: FileUploadStatus, captchaToken: string): Promise<void> {
    try {
      await this._uploadFile(fileUploadStatus, captchaToken);
    } catch (e) {
      fileUploadStatus.errorType = FileProcessedErrorType.Unknown;
      fileUploadStatus.status = FileUploadStatusEnum.error;
    }
  }

  private async _uploadFile(fileUploadStatus: FileUploadStatus, captchaToken: string): Promise<void> {
    // make sure the file upload status is still part of the store
    if (uploadStore.files.indexOf(fileUploadStatus) === -1) {
      throw new Error('no longer in queue');
    }

    switch (fileUploadStatus.status) {
      case FileUploadStatusEnum.notStarted:
        // expected, don't exit
        break;
      case FileUploadStatusEnum.inProgress:
        // already in progress, don't upload again
        return;
      case FileUploadStatusEnum.complete:
        // already complete, don't upload again
        return;
      case FileUploadStatusEnum.error:
        // had an error uploading it before, try again (don't exit)
        break;
    }
    fileUploadStatus.status = FileUploadStatusEnum.inProgress;
    const processedFileResult = await CaseApi.processFile(fileUploadStatus.file, fileUploadStatus.file.name, captchaToken);
    if (processedFileResult.file && !processedFileResult.error) {
      fileUploadStatus.processedFile = processedFileResult.file;
      fileUploadStatus.status = FileUploadStatusEnum.complete;
    } else {
      fileUploadStatus.errorType = processedFileResult.error || FileProcessedErrorType.Unknown;
      fileUploadStatus.status = FileUploadStatusEnum.error;
    }
  }
}

export const uploadService = new UploadService();