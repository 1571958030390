export const tgxpDemoQuestionnaireData = {
    "caseType": "Cardiac",
    "peripheralType": "Quattro (4 balloon, femoral catheter)",
    "patientWeight": "80-89 kg",
    "patientMassUnit": "kg",
    "patientOutcome": "0 (No Symptoms)",
    "patientOutcomeUnit": "mRS",
    "customCaseType": "",
    "hospitalArrivalTime": "2016-02-27 03:20 PM",
    "documentKeys": []
};

