import { AllLabels } from "@/interfaces";
import { getWebServiceUrl } from "@/utils";
import { getAuthorizationToken, parseFetchResponse } from "@/utils/funcs";

export class LabelsApi {

    public static async getLabels(): Promise<AllLabels> {
        const labels = await this.fetch<AllLabels>('labels');
        return labels;
    }
    private static async fetch<T>(url: string): Promise<T> {
        const config = getAuthorizationToken();
        const baseUrl = await getWebServiceUrl();
        const response = await fetch(`${baseUrl}/api/` + url, config);
        return parseFetchResponse(response);
    }
}