import { FileUploadStatusEnum } from '@/enums';
import { CaseData, FileUploadStatus } from '@/interfaces';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'UploadModule', namespaced: true })
export default class UploadModule extends VuexModule {
  public showUpload: boolean = true;
  public showProcessing: boolean = false;
  public showSelectCase: boolean = false;
  public showResultsReady: boolean = false;
  public showResults: boolean = false;
  public files: FileUploadStatus[] = [];
  public cases: CaseData[] = [];
  public selectedCase: CaseData | null = null;
  public filesChangedSinceAnalyze: boolean = true;

  @Mutation
  public selectCase(caseData: CaseData | null): void {
    this.selectedCase = caseData;
  }

  @Mutation
  public setCases(cases: CaseData[]): void {
    // keep the old cases for any cases that are still composed of the same files
    const oldCases = this.cases;
    const newCases: CaseData[] = [];
    cases.forEach((newCase) => {
      // try to find a previously created case from the same files
      const matchingOldCase = oldCases.find((oldCase) => {
        if (oldCase.files.length != newCase.files.length) {
          return false;
        }
        if (newCase.files.some((newCaseFile) => !oldCase.files.includes(newCaseFile))) {
          // old case is missing a new file!
          return false;
        }
        if (oldCase.files.some((oldCaseFile) => !newCase.files.includes(oldCaseFile))) {
          // new case is missing an old file!
          return false;
        }
        return true;
      });
      if (matchingOldCase) {
        newCases.push(matchingOldCase);
      } else {
        newCases.push(newCase);
      }
    });
    this.cases = newCases;
    this.selectedCase = newCases.length > 0 ? newCases[0] : null;
    this.filesChangedSinceAnalyze = false;
  }

  @Mutation
  public removeFile(file: FileUploadStatus): void {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      const result = this.files.slice();
      result.splice(index, 1);
      this.files = result;
      this.filesChangedSinceAnalyze = true;
    }
  }

  @Mutation
  public addFiles(files: File[]): void {
    // shallow copy
    const newFileUploadStatuses: FileUploadStatus[] = this.files.slice();
    // add each new file if we don't already have a matching file name
    files.forEach((newFile) => {
      const existingIndex = newFileUploadStatuses.findIndex((existingFile) => {
        return existingFile.file.name === newFile.name;
      });
      if (existingIndex === -1) {
        // do not already have a file with this name
        newFileUploadStatuses.push({
          file: newFile,
          status: FileUploadStatusEnum.notStarted
        });
        this.filesChangedSinceAnalyze = true;
      }
    });
    this.files = newFileUploadStatuses;
  }

  @Mutation
  public showProcessingPage(): void {
    this.showUpload = false;
    this.showProcessing = true; 
    this.showSelectCase = false;
    this.showResultsReady = false;
    this.showResults = false;
  }

  @Mutation
  public showUploadPage(): void {
    this.showUpload = true;
    this.showProcessing = false;
    this.showSelectCase = false;
    this.showResultsReady = false;
    this.showResults = false;
  }

  @Mutation
  public showSelectCasePage(): void {
    this.showUpload = false;
    this.showProcessing = false;
    this.showSelectCase = true;
    this.showResultsReady = false;
    this.showResults = false;
  }

  @Mutation
  public showResultsReadyPage(): void {
    this.showUpload = false;
    this.showProcessing = false;
    this.showSelectCase = false;
    this.showResultsReady = true;
    this.showResults = false;
  }

  @Mutation
  public showResultsPage(): void {
    this.showUpload = false;
    this.showProcessing = false;
    this.showSelectCase = false;
    this.showResultsReady = false;
    this.showResults = true;
  }

}