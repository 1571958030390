let webServiceUrl = '';

async function readJson() {
  // TODO: prevent multiple requests in parallel?
  try {
    const result = await fetch('/settings.json');
    const data = await result.json();
    webServiceUrl = data.AppWebServiceUrl;
    if (window.location.href.includes("localhost")) {
      webServiceUrl = 'http://localhost:5010';
    }
  } catch (e) {
    // something went wrong?
    console.error('READ JSON ERROR');
    console.error(e);
  }
}

export async function getWebServiceUrl(): Promise<string> {
  if (webServiceUrl === '') {
    await readJson();
  }
  return webServiceUrl;
}