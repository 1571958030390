export enum PlotEventType {
    Alarm = 'Alarm',
    Alert = 'Alert',
    AlertClear = 'AlertClear',
    Malfunction = 'Malfunction',
    TcMalfunction = 'TcMalfunction',
    ProbeCalibration = 'ProbeCalibration',
    TimeChange = 'TimeChange',
    Misc = 'Misc',
    Error = 'Error',
  }