import Captcha from '@/components/ui/Captcha.vue';

export class CaptchaService {
  private _captcha?: Captcha;
  public register(captcha: Captcha): void {
    this._captcha = captcha;
  }

  public async checkCaptcha(): Promise<string> {
    if (!this._captcha) {
      throw new Error('Captcha not present');
    }
    // disable captcha
    return '';
    /*const token = await this._captcha.execute();
    return token;*/
  }
}

export const captchaService = new CaptchaService();