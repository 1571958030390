import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import DemoModule from '@/store/modules/DemoModule';
import ErrorModule from '@/store/modules/ErrorModule';
import LabelsModule from '@/store/modules/LabelsModule';
import UploadModule from '@/store/modules/UploadModule';
import SettingsModule from '@/store/modules/SettingsModule';
import QuestionnaireModule from '@/store/modules/QuestionnaireModule';
import TermsAndConditionsModule from '@/store/modules/TermsAndConditionsModule';

let rawStore: Store<any>;
let demoStore: DemoModule;
let errorStore: ErrorModule;
let labelsStore: LabelsModule;
let uploadStore: UploadModule;
let settingsStore: SettingsModule;
let questionnaireStore: QuestionnaireModule;
let termsAndConditionsStore: TermsAndConditionsModule;

function initialiseStores(store: Store<any>): void {
    rawStore = store;
    demoStore = getModule(DemoModule, store);
    errorStore = getModule(ErrorModule, store);
    labelsStore = getModule(LabelsModule, store);
    uploadStore = getModule(UploadModule, store);
    settingsStore = getModule(SettingsModule, store);
    questionnaireStore = getModule(QuestionnaireModule, store);
    termsAndConditionsStore = getModule(TermsAndConditionsModule, store);
}

export {
    initialiseStores,
    rawStore,
    demoStore,
    errorStore,
    labelsStore,
    uploadStore,
    settingsStore,
    questionnaireStore,
    termsAndConditionsStore,
}