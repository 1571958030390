import { PlotEventType, PlotPhaseName } from "@/enums";
import { CaseData } from "@/interfaces";
import { DateWrapper, Percent, getCaseMode } from "@/utils";

const rawCaseData = {
    "caseMode": "Cooling",
    "caseDurationMs": 242400000,
    "firstTargetTemperature": 33,
    "caseDateTime": {
        "_rawValue": "2016-02-27T15:50:00",
        "_containsTimeZone": false,
        "_date": "2016-02-27T23:50:00.000Z"
    },
    "timeWithinRange02": {
        "_percentScalar": 1
    },
    "timeWithinRange05": {
        "_percentScalar": 1
    },
    "timeWithinRange10": {
        "_percentScalar": 1
    },
    "timeWithinRange20": {
        "_percentScalar": 1
    },
    "timeToTargetMs02": 4500000,
    "timeToTargetMs05": 4080000,
    "timeToTargetMs10": 3420000,
    "timeToTargetMs20": 2220000,
    "deviceSerialNumber": "",
    "deviceType": "Thermogard XP",
    "alarmCount": 0,
    "warmingRate": -2.04,
    "coolingRate": 2.04,
    "inductionTimeMs": 4500000,
    "rewarmingRate": 0.29,
    "feverBurden": 0,
    "isNewPatient": true,
    "containsNoRunData": false,
    "pumpHours": -1,
    "graphData": [
        {
            "targetTemp": 33,
            "bathTemp": 0.65,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 0,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.48,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 60000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 120000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 180000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 240000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 300000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 360000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 420000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 480000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 540000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 600000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 660000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 720000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 780000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 840000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 900000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 960000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1020000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1080000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1140000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1200000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1260000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1320000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1380000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1440000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1500000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1560000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1620000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1680000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1740000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1800000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1860000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1920000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 1980000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2040000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2100000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2160000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2220000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2280000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2340000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2400000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2460000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2520000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2580000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2640000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2700000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2760000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2820000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2880000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 2940000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3000000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3060000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3120000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3180000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3240000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3300000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3360000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3420000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3480000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3540000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3600000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3660000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3720000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3780000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3840000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3900000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 3960000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4020000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4080000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4140000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4200000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4260000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4320000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4380000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4440000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4500000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4560000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4620000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4680000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4740000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4800000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4860000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4920000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 4980000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5040000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5100000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5160000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5220000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5280000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5340000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5400000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5460000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5520000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5580000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5640000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5700000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5760000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5820000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5880000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 5940000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6000000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6060000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6120000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6180000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6240000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6300000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6360000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6420000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6480000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6540000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6600000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6660000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6720000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6780000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6840000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6900000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 6960000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7020000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7080000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7140000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7200000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7260000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7320000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7380000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7440000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7500000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7560000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7620000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7680000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7740000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 0,
            "bathTemp": 0,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7800000,
            "state": "Off",
            "controlMode": "0.00"
        },
        {
            "targetTemp": 33,
            "bathTemp": 22.04,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7920000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.15,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 7980000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.26,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 8040000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.26,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 8100000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.26,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 8160000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 0,
            "power": 0,
            "timeMs": 8220000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 35.67,
            "power": 0,
            "timeMs": 8280000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.98,
            "patientTemp": 35.7,
            "power": 0,
            "timeMs": 8340000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.54,
            "patientTemp": 35.72,
            "power": 0,
            "timeMs": 8400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 35.72,
            "power": -2.5837592277115182,
            "timeMs": 8460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 35.73,
            "power": -12.375816065852955,
            "timeMs": 8520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.59,
            "patientTemp": 35.74,
            "power": -23.12712826333712,
            "timeMs": 8580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.04,
            "patientTemp": 35.74,
            "power": -30.36322360953463,
            "timeMs": 8640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 22.98,
            "patientTemp": 35.73,
            "power": -36.190746522849835,
            "timeMs": 8700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 21.31,
            "patientTemp": 35.72,
            "power": -40.91425326519024,
            "timeMs": 8760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 19.76,
            "patientTemp": 35.7,
            "power": -45.28409090909091,
            "timeMs": 8820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 18.42,
            "patientTemp": 35.67,
            "power": -49.04748365083878,
            "timeMs": 8880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 17.2,
            "patientTemp": 35.65,
            "power": -52.48933143669986,
            "timeMs": 8940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 15.98,
            "patientTemp": 35.62,
            "power": -55.92255125284738,
            "timeMs": 9000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 14.98,
            "patientTemp": 35.59,
            "power": -58.734682245654035,
            "timeMs": 9060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 13.98,
            "patientTemp": 35.57,
            "power": -61.562589107499285,
            "timeMs": 9120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 13.04,
            "patientTemp": 35.54,
            "power": -64.2123287671233,
            "timeMs": 9180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 12.15,
            "patientTemp": 35.52,
            "power": -66.73329525985152,
            "timeMs": 9240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 11.37,
            "patientTemp": 35.5,
            "power": -68.94285714285715,
            "timeMs": 9300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 10.59,
            "patientTemp": 35.48,
            "power": -71.15494568324758,
            "timeMs": 9360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 9.98,
            "patientTemp": 35.47,
            "power": -72.89104947097512,
            "timeMs": 9420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 9.26,
            "patientTemp": 35.44,
            "power": -74.92844876931883,
            "timeMs": 9480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 8.59,
            "patientTemp": 35.42,
            "power": -76.8327605956472,
            "timeMs": 9540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 8.04,
            "patientTemp": 35.39,
            "power": -78.38922327314417,
            "timeMs": 9600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 7.59,
            "patientTemp": 35.37,
            "power": -79.66733581875538,
            "timeMs": 9660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 7.04,
            "patientTemp": 35.35,
            "power": -81.2338593974175,
            "timeMs": 9720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 6.48,
            "patientTemp": 35.33,
            "power": -82.8308929084123,
            "timeMs": 9780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 6.04,
            "patientTemp": 35.31,
            "power": -84.08503303648376,
            "timeMs": 9840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 5.7,
            "patientTemp": 35.3,
            "power": -85.0574712643678,
            "timeMs": 9900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 5.31,
            "patientTemp": 35.28,
            "power": -86.17021276595746,
            "timeMs": 9960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 4.92,
            "patientTemp": 35.27,
            "power": -87.28789186079953,
            "timeMs": 10020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 4.54,
            "patientTemp": 35.25,
            "power": -88.37410071942446,
            "timeMs": 10080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 4.2,
            "patientTemp": 35.22,
            "power": -89.34331797235023,
            "timeMs": 10140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 3.87,
            "patientTemp": 35.2,
            "power": -90.28818443804035,
            "timeMs": 10200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 3.48,
            "patientTemp": 35.16,
            "power": -91.40219272937104,
            "timeMs": 10260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 3.15,
            "patientTemp": 35.13,
            "power": -92.34767542593127,
            "timeMs": 10320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 2.87,
            "patientTemp": 35.1,
            "power": -93.15028901734105,
            "timeMs": 10380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 2.65,
            "patientTemp": 35.06,
            "power": -93.77893518518519,
            "timeMs": 10440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 2.37,
            "patientTemp": 35.03,
            "power": -94.58441934549667,
            "timeMs": 10500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 2.15,
            "patientTemp": 35,
            "power": -95.21739130434783,
            "timeMs": 10560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.98,
            "patientTemp": 34.96,
            "power": -95.70516540917005,
            "timeMs": 10620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.92,
            "patientTemp": 34.93,
            "power": -95.87568980540226,
            "timeMs": 10680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.87,
            "patientTemp": 34.89,
            "power": -96.01628380343124,
            "timeMs": 10740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.7,
            "patientTemp": 34.84,
            "power": -96.50553290623179,
            "timeMs": 10800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.59,
            "patientTemp": 34.79,
            "power": -96.82123067949838,
            "timeMs": 10860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.48,
            "patientTemp": 34.74,
            "power": -97.13785046728974,
            "timeMs": 10920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.42,
            "patientTemp": 34.69,
            "power": -97.30915472360338,
            "timeMs": 10980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.26,
            "patientTemp": 34.63,
            "power": -97.77322004101964,
            "timeMs": 11040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.15,
            "patientTemp": 34.58,
            "power": -98.09272300469483,
            "timeMs": 11100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.04,
            "patientTemp": 34.52,
            "power": -98.41269841269842,
            "timeMs": 11160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.92,
            "patientTemp": 34.47,
            "power": -98.7636149543715,
            "timeMs": 11220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.81,
            "patientTemp": 34.41,
            "power": -99.08581539368917,
            "timeMs": 11280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.76,
            "patientTemp": 34.36,
            "power": -99.23213230950975,
            "timeMs": 11340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.65,
            "patientTemp": 34.31,
            "power": -99.55634427684117,
            "timeMs": 11400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.59,
            "patientTemp": 34.25,
            "power": -99.73333333333332,
            "timeMs": 11460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.48,
            "patientTemp": 34.21,
            "power": -100,
            "timeMs": 11520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.48,
            "patientTemp": 34.17,
            "power": -100,
            "timeMs": 11580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.92,
            "patientTemp": 34.12,
            "power": -98.75074360499701,
            "timeMs": 11640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.81,
            "patientTemp": 34.06,
            "power": -99.07628128724672,
            "timeMs": 11700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.7,
            "patientTemp": 34.01,
            "power": -99.40316323485526,
            "timeMs": 11760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.59,
            "patientTemp": 33.96,
            "power": -99.73102211595935,
            "timeMs": 11820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.48,
            "patientTemp": 33.91,
            "power": -100,
            "timeMs": 11880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.42,
            "patientTemp": 33.88,
            "power": -97.24385859796286,
            "timeMs": 11940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.2,
            "patientTemp": 33.85,
            "power": -97.90104947526235,
            "timeMs": 12000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.98,
            "patientTemp": 33.82,
            "power": -98.55942376950782,
            "timeMs": 12060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.81,
            "patientTemp": 33.77,
            "power": -99.06822963630898,
            "timeMs": 12120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.7,
            "patientTemp": 33.7,
            "power": -99.39759036144578,
            "timeMs": 12180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.59,
            "patientTemp": 33.64,
            "power": -99.72842486421241,
            "timeMs": 12240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.59,
            "patientTemp": 33.6,
            "power": -99.72809667673715,
            "timeMs": 12300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.26,
            "patientTemp": 33.58,
            "power": -97.7025392986699,
            "timeMs": 12360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.04,
            "patientTemp": 33.54,
            "power": -98.36561743341404,
            "timeMs": 12420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.81,
            "patientTemp": 33.49,
            "power": -99.06032130948772,
            "timeMs": 12480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.7,
            "patientTemp": 33.45,
            "power": -99.39301972685887,
            "timeMs": 12540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.65,
            "patientTemp": 33.4,
            "power": -99.54407294832828,
            "timeMs": 12600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 0.48,
            "patientTemp": 33.34,
            "power": -100,
            "timeMs": 12660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 1.48,
            "patientTemp": 33.3,
            "power": -97.01219512195122,
            "timeMs": 12720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 2.2,
            "patientTemp": 33.24,
            "power": -94.80757483200978,
            "timeMs": 12780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 4.87,
            "patientTemp": 33.2,
            "power": -86.63608562691131,
            "timeMs": 12840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 7.26,
            "patientTemp": 33.15,
            "power": -79.29555895865238,
            "timeMs": 12900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 9.26,
            "patientTemp": 33.1,
            "power": -73.12883435582823,
            "timeMs": 12960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 11.04,
            "patientTemp": 33.06,
            "power": -67.62899262899263,
            "timeMs": 13020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 12.59,
            "patientTemp": 33.03,
            "power": -62.834306793728864,
            "timeMs": 13080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 14.09,
            "patientTemp": 33,
            "power": -58.18461538461539,
            "timeMs": 13140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 15.15,
            "patientTemp": 32.97,
            "power": 0,
            "timeMs": 13200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 15.81,
            "patientTemp": 32.94,
            "power": 0,
            "timeMs": 13260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 17.31,
            "patientTemp": 32.93,
            "power": 0,
            "timeMs": 13320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 19.54,
            "patientTemp": 32.92,
            "power": 0,
            "timeMs": 13380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 21.76,
            "patientTemp": 32.9,
            "power": 0,
            "timeMs": 13440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 23.92,
            "patientTemp": 32.89,
            "power": 0,
            "timeMs": 13500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.04,
            "patientTemp": 32.88,
            "power": 0,
            "timeMs": 13560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.15,
            "patientTemp": 32.87,
            "power": 0,
            "timeMs": 13620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 32.87,
            "power": 0,
            "timeMs": 13680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 32.86,
            "power": 0,
            "timeMs": 13740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.86,
            "power": 14.660831509846863,
            "timeMs": 13800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.98,
            "patientTemp": 32.86,
            "power": 23.194748358862117,
            "timeMs": 13860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.7,
            "patientTemp": 32.86,
            "power": 31.07221006564555,
            "timeMs": 13920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.7,
            "patientTemp": 32.86,
            "power": 42.01312910284468,
            "timeMs": 13980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.87,
            "patientTemp": 32.86,
            "power": 54.814004376367585,
            "timeMs": 14040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 39.04,
            "patientTemp": 32.86,
            "power": 67.61487964989058,
            "timeMs": 14100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 40.2,
            "patientTemp": 32.87,
            "power": 80.2847754654984,
            "timeMs": 14160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 41.26,
            "patientTemp": 32.87,
            "power": 91.89485213581598,
            "timeMs": 14220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 42.09,
            "patientTemp": 32.88,
            "power": 100,
            "timeMs": 14280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 41.65,
            "patientTemp": 32.89,
            "power": 96.15806805708011,
            "timeMs": 14340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 41.2,
            "patientTemp": 32.9,
            "power": 91.20879120879124,
            "timeMs": 14400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 41.98,
            "patientTemp": 32.92,
            "power": 99.77973568281935,
            "timeMs": 14460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 40.37,
            "patientTemp": 32.93,
            "power": 82.02866593164275,
            "timeMs": 14520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 39.26,
            "patientTemp": 32.95,
            "power": 69.72375690607731,
            "timeMs": 14580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.98,
            "patientTemp": 32.96,
            "power": 55.53097345132739,
            "timeMs": 14640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.65,
            "patientTemp": 32.97,
            "power": 40.75304540420819,
            "timeMs": 14700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.31,
            "patientTemp": 32.99,
            "power": 25.749167591564937,
            "timeMs": 14760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 14820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 14880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.02,
            "power": -3.8745387453874582,
            "timeMs": 14940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.03,
            "power": -6.8244697202582305,
            "timeMs": 15000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.98,
            "patientTemp": 33.03,
            "power": -9.37596065170612,
            "timeMs": 15060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.31,
            "patientTemp": 33.03,
            "power": -11.435597909621894,
            "timeMs": 15120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.87,
            "patientTemp": 33.04,
            "power": -12.814996926859246,
            "timeMs": 15180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.7,
            "patientTemp": 33.04,
            "power": -13.33743085433313,
            "timeMs": 15240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.15,
            "patientTemp": 33.04,
            "power": -15.02765826674862,
            "timeMs": 15300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.26,
            "patientTemp": 33.04,
            "power": -14.689612784265513,
            "timeMs": 15360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.37,
            "patientTemp": 33.04,
            "power": -14.351567301782417,
            "timeMs": 15420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.26,
            "patientTemp": 33.04,
            "power": -14.689612784265513,
            "timeMs": 15480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.76,
            "patientTemp": 33.03,
            "power": -13.126344912388562,
            "timeMs": 15540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.09,
            "patientTemp": 33.03,
            "power": -12.111896710728562,
            "timeMs": 15600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.26,
            "patientTemp": 33.03,
            "power": -11.589302182600674,
            "timeMs": 15660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.7,
            "patientTemp": 33.02,
            "power": -10.20910209102092,
            "timeMs": 15720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.09,
            "patientTemp": 33.02,
            "power": -9.009840098400995,
            "timeMs": 15780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": -7.782220855121494,
            "timeMs": 15840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 15900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33,
            "power": -5.015384615384613,
            "timeMs": 15960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 16020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 16080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 16140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 16200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 16260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 16320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.98,
            "power": 3.6585365853659124,
            "timeMs": 16380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.98,
            "power": 6.208425720620866,
            "timeMs": 16440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 16500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 16560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 16620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 16680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 16740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 16800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.99,
            "power": 4.217536071032137,
            "timeMs": 16860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 16920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.99,
            "power": 9.766925638179751,
            "timeMs": 16980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 17040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 33,
            "power": 2.222222222222254,
            "timeMs": 17100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 17160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 33,
            "power": -0.24615384615384092,
            "timeMs": 17220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 17280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 17340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 17400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33.01,
            "power": -2.153183635804355,
            "timeMs": 17460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 17520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33.01,
            "power": -2.645339895416793,
            "timeMs": 17580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 17640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 17700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 17760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 17820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 17880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 17940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33.01,
            "power": -3.6911719470932005,
            "timeMs": 18000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 18060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 18120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 18180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33,
            "power": -4.492307692307695,
            "timeMs": 18240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 18300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33,
            "power": -5.015384615384613,
            "timeMs": 18360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 18420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 18480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 18540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 18600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 18660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 18720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 18780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 18840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 18900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 18960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 19020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 19080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 19140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 19200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 19260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 19320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 19380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 19440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 19500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 19560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 19620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 19680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 19740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 19800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 19860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 19920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 19980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 20040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 20100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 20160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 20220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33,
            "power": -4.338461538461539,
            "timeMs": 20280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 20340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33.01,
            "power": -1.9686250384497097,
            "timeMs": 20400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 20460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 20520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 20580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 20640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 20700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 20760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 20820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 20880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 20940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 21000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 21060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 21120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 21180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33.01,
            "power": -2.3069824669332517,
            "timeMs": 21240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 21300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 21360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33,
            "power": -4.676923076923075,
            "timeMs": 21420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 21480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 21540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 21600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 21660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 21720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 21780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 21840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 21900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 21960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 22020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 22080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 22140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 22200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 22260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 22320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33.01,
            "power": -3.6911719470932005,
            "timeMs": 22380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 22440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 22500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 22560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 22620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 22680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 22740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 22800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 33.01,
            "power": 5.22803114571745,
            "timeMs": 22860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 22920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 22980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 23040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 23100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 23160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 23220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 23280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 23340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 23400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 23460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33,
            "power": -5.692307692307697,
            "timeMs": 23520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 23580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 23640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 23700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 23760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 23820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 23880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 23940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 24000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 24060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33.01,
            "power": -2.4915410642878966,
            "timeMs": 24120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 24180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 24240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 24300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33,
            "power": -4.153846153846158,
            "timeMs": 24360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 24420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 24480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 24540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33.01,
            "power": -2.3069824669332517,
            "timeMs": 24600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 24660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 24720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 24780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 24840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 24900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 24960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 25020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 25080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 25140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 25200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 25260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 25320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 25380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 25440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 25500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 25560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 25620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 25680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 25740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 25800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 25860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 25920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 25980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 26040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 26100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 26160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 26220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 26280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 26340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 26400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 26460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 26520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 26580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 26640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 26700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 26760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 26820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.01,
            "power": -7.10550599815441,
            "timeMs": 26880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 26940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 27000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.02,
            "power": -6.273062730627315,
            "timeMs": 27060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.31,
            "patientTemp": 33.02,
            "power": -8.333333333333346,
            "timeMs": 27120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 27180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.04,
            "patientTemp": 33.02,
            "power": -9.16359163591637,
            "timeMs": 27240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 27300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 27360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.31,
            "patientTemp": 33.01,
            "power": -8.305136880959703,
            "timeMs": 27420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 27480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.26,
            "patientTemp": 33.02,
            "power": -8.487084870848713,
            "timeMs": 27540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.01,
            "power": -7.443863426637952,
            "timeMs": 27600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 27660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.01,
            "power": -7.597662257766838,
            "timeMs": 27720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.02,
            "power": -6.611316113161138,
            "timeMs": 27780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 27840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 27900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.02,
            "power": -6.088560885608868,
            "timeMs": 27960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.01,
            "power": -7.259304829283296,
            "timeMs": 28020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 28080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.01,
            "power": -7.259304829283296,
            "timeMs": 28140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 28200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.01,
            "power": -7.597662257766838,
            "timeMs": 28260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 28320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 28380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 28440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 28500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 28560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 28620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 28680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 28740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 28800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 28860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 28920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 28980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 29040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 29100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 29160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 29220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 29280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 29340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 29400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 29460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 29520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 29580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 29640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.02,
            "power": -6.611316113161138,
            "timeMs": 29700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 29760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.02,
            "power": -7.810578105781066,
            "timeMs": 29820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 29880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.37,
            "patientTemp": 33.02,
            "power": -8.14883148831489,
            "timeMs": 29940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 30000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.37,
            "patientTemp": 33.02,
            "power": -8.14883148831489,
            "timeMs": 30060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.02,
            "power": -7.287822878228796,
            "timeMs": 30120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.04,
            "patientTemp": 33.02,
            "power": -9.16359163591637,
            "timeMs": 30180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 30240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 30300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 30360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.02,
            "power": -7.287822878228796,
            "timeMs": 30420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 30480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.02,
            "power": -6.273062730627315,
            "timeMs": 30540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.03,
            "power": -7.654472794343689,
            "timeMs": 30600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.03,
            "power": -7.838917921918231,
            "timeMs": 30660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.87,
            "patientTemp": 33.02,
            "power": -9.686346863468641,
            "timeMs": 30720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 30780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.87,
            "patientTemp": 33.03,
            "power": -9.714110052259453,
            "timeMs": 30840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 30900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.09,
            "patientTemp": 33.02,
            "power": -9.009840098400995,
            "timeMs": 30960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.87,
            "patientTemp": 33.02,
            "power": -9.686346863468641,
            "timeMs": 31020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.87,
            "patientTemp": 33.02,
            "power": -9.686346863468641,
            "timeMs": 31080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 31140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.98,
            "patientTemp": 33.02,
            "power": -9.348093480934818,
            "timeMs": 31200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 31260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 31320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.98,
            "patientTemp": 33.02,
            "power": -9.348093480934818,
            "timeMs": 31380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.37,
            "patientTemp": 33.02,
            "power": -8.14883148831489,
            "timeMs": 31440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 31500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.31,
            "patientTemp": 33.02,
            "power": -8.333333333333346,
            "timeMs": 31560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 31620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 31680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.02,
            "power": -6.949569495694961,
            "timeMs": 31740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.02,
            "power": -6.273062730627315,
            "timeMs": 31800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 31860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 31920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 31980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 32040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 32100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.02,
            "power": -5.9348093480934905,
            "timeMs": 32160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.02,
            "power": -6.949569495694961,
            "timeMs": 32220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.02,
            "power": -7.810578105781066,
            "timeMs": 32280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.02,
            "power": -6.457564575645761,
            "timeMs": 32340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.02,
            "power": -7.810578105781066,
            "timeMs": 32400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.02,
            "power": -7.287822878228796,
            "timeMs": 32460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 32520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 32580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 32640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.02,
            "power": -5.596555965559666,
            "timeMs": 32700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 32760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 32820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.02,
            "power": -5.750307503075044,
            "timeMs": 32880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 32940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.02,
            "power": -6.457564575645761,
            "timeMs": 33000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 33060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 33120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.26,
            "patientTemp": 33.02,
            "power": -8.487084870848713,
            "timeMs": 33180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 33240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.01,
            "power": -7.443863426637952,
            "timeMs": 33300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 33360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 33420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 33480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 33540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 33600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 33660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 33720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 33.02,
            "power": -7.134071340713419,
            "timeMs": 33780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.02,
            "power": -5.9348093480934905,
            "timeMs": 33840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 33900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 33960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.02,
            "power": -6.088560885608868,
            "timeMs": 34020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 34080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.01,
            "power": -7.259304829283296,
            "timeMs": 34140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 34200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 34260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 34320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 34380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 34440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 34500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 34560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.02,
            "power": -7.810578105781066,
            "timeMs": 34620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 34680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 34740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 34800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.01,
            "power": -7.96677945247615,
            "timeMs": 34860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 34920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 34980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 35040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 35100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 35160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 35220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 35280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 35340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 35400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.01,
            "power": -7.597662257766838,
            "timeMs": 35460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.02,
            "power": -6.088560885608868,
            "timeMs": 35520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.02,
            "power": -7.810578105781066,
            "timeMs": 35580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.02,
            "power": -6.611316113161138,
            "timeMs": 35640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.37,
            "patientTemp": 33.02,
            "power": -8.14883148831489,
            "timeMs": 35700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.02,
            "power": -6.795817958179596,
            "timeMs": 35760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.02,
            "power": -8.67158671586717,
            "timeMs": 35820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 35880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 35940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 36000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 36060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 36120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 36180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 36240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 36300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 36360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 36420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 36480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 36540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 36600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33.01,
            "power": -2.153183635804355,
            "timeMs": 36660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 36720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33.01,
            "power": -3.6911719470932005,
            "timeMs": 36780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 36840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 36900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.01,
            "power": -7.443863426637952,
            "timeMs": 36960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.02,
            "power": -5.9348093480934905,
            "timeMs": 37020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": -7.782220855121494,
            "timeMs": 37080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 37140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 37200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33.01,
            "power": -1.9686250384497097,
            "timeMs": 37260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.01,
            "power": -6.767148569670869,
            "timeMs": 37320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.02,
            "power": -5.596555965559666,
            "timeMs": 37380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.42,
            "patientTemp": 33.02,
            "power": -7.9950799507995125,
            "timeMs": 37440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 37500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.02,
            "power": -5.9348093480934905,
            "timeMs": 37560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": -7.782220855121494,
            "timeMs": 37620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 37680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": -7.782220855121494,
            "timeMs": 37740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 37800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.76,
            "patientTemp": 33.01,
            "power": -6.920947400799743,
            "timeMs": 37860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 37920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 37980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.01,
            "power": -7.597662257766838,
            "timeMs": 38040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 38100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": -7.782220855121494,
            "timeMs": 38160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 38220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.01,
            "power": -7.597662257766838,
            "timeMs": 38280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 38340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 38400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 38460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 38520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 38580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 38640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 38700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 38760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 38820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33.02,
            "power": -7.62607626076262,
            "timeMs": 38880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.02,
            "power": -6.457564575645761,
            "timeMs": 38940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.04,
            "patientTemp": 33.02,
            "power": -9.16359163591637,
            "timeMs": 39000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.59,
            "patientTemp": 33.02,
            "power": -7.472324723247242,
            "timeMs": 39060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.15,
            "patientTemp": 33.02,
            "power": -8.825338253382547,
            "timeMs": 39120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.7,
            "patientTemp": 33.02,
            "power": -10.20910209102092,
            "timeMs": 39180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.26,
            "patientTemp": 33.03,
            "power": -8.5152167230249,
            "timeMs": 39240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.42,
            "patientTemp": 33.03,
            "power": -11.097448509068549,
            "timeMs": 39300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.98,
            "patientTemp": 33.03,
            "power": -9.37596065170612,
            "timeMs": 39360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.42,
            "patientTemp": 33.03,
            "power": -11.097448509068549,
            "timeMs": 39420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.04,
            "patientTemp": 33.02,
            "power": -9.16359163591637,
            "timeMs": 39480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.31,
            "patientTemp": 33.02,
            "power": -8.333333333333346,
            "timeMs": 39540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.59,
            "patientTemp": 33.03,
            "power": -10.574853980940674,
            "timeMs": 39600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.09,
            "patientTemp": 33.03,
            "power": -12.111896710728562,
            "timeMs": 39660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.54,
            "patientTemp": 33.03,
            "power": -10.728558253919465,
            "timeMs": 39720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.04,
            "patientTemp": 33.03,
            "power": -12.265600983707353,
            "timeMs": 39780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.59,
            "patientTemp": 33.04,
            "power": -13.675476336816224,
            "timeMs": 39840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.09,
            "patientTemp": 33.04,
            "power": -12.138905961893052,
            "timeMs": 39900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.15,
            "patientTemp": 33.04,
            "power": -15.02765826674862,
            "timeMs": 39960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.81,
            "patientTemp": 33.04,
            "power": -16.072526121696377,
            "timeMs": 40020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.37,
            "patientTemp": 33.05,
            "power": -14.377880184331786,
            "timeMs": 40080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.48,
            "patientTemp": 33.05,
            "power": -17.112135176651297,
            "timeMs": 40140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.09,
            "patientTemp": 33.05,
            "power": -15.238095238095232,
            "timeMs": 40200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.2,
            "patientTemp": 33.05,
            "power": -17.97235023041474,
            "timeMs": 40260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.65,
            "patientTemp": 33.06,
            "power": -16.615479115479125,
            "timeMs": 40320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.65,
            "patientTemp": 33.06,
            "power": -19.686732186732197,
            "timeMs": 40380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.26,
            "patientTemp": 33.06,
            "power": -20.884520884520885,
            "timeMs": 40440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.04,
            "patientTemp": 33.06,
            "power": -21.56019656019657,
            "timeMs": 40500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.65,
            "patientTemp": 33.07,
            "power": -22.781700951796136,
            "timeMs": 40560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.37,
            "patientTemp": 33.07,
            "power": -23.641387780165797,
            "timeMs": 40620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.65,
            "patientTemp": 33.07,
            "power": -22.781700951796136,
            "timeMs": 40680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.7,
            "patientTemp": 33.08,
            "power": -22.651933701657455,
            "timeMs": 40740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.76,
            "patientTemp": 33.08,
            "power": -25.537139349294037,
            "timeMs": 40800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.09,
            "patientTemp": 33.07,
            "power": -24.501074608535465,
            "timeMs": 40860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.26,
            "patientTemp": 33.07,
            "power": -23.979121891311017,
            "timeMs": 40920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.37,
            "patientTemp": 33.07,
            "power": -23.641387780165797,
            "timeMs": 40980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.42,
            "patientTemp": 33.07,
            "power": -23.48787227509978,
            "timeMs": 41040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.87,
            "patientTemp": 33.07,
            "power": -25.17654283082591,
            "timeMs": 41100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.26,
            "patientTemp": 33.07,
            "power": -23.979121891311017,
            "timeMs": 41160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.65,
            "patientTemp": 33.07,
            "power": -22.781700951796136,
            "timeMs": 41220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.26,
            "patientTemp": 33.08,
            "power": -24.002455494168192,
            "timeMs": 41280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.81,
            "patientTemp": 33.08,
            "power": -25.383670963781462,
            "timeMs": 41340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.15,
            "patientTemp": 33.08,
            "power": -27.409453652547576,
            "timeMs": 41400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 23.92,
            "patientTemp": 33.09,
            "power": -28.137465480208657,
            "timeMs": 41460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 23.7,
            "patientTemp": 33.09,
            "power": -28.812519177661873,
            "timeMs": 41520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 23.54,
            "patientTemp": 33.09,
            "power": -29.303467321264204,
            "timeMs": 41580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 23.42,
            "patientTemp": 33.09,
            "power": -29.67167842896594,
            "timeMs": 41640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.2,
            "patientTemp": 33.08,
            "power": -27.255985267034987,
            "timeMs": 41700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.37,
            "patientTemp": 33.08,
            "power": -26.734192756292195,
            "timeMs": 41760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.76,
            "patientTemp": 33.08,
            "power": -25.537139349294037,
            "timeMs": 41820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.98,
            "patientTemp": 33.07,
            "power": -24.83880871968069,
            "timeMs": 41880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.31,
            "patientTemp": 33.07,
            "power": -23.825606386245017,
            "timeMs": 41940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.48,
            "patientTemp": 33.08,
            "power": -26.396562308164512,
            "timeMs": 42000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.09,
            "patientTemp": 33.08,
            "power": -24.524248004910984,
            "timeMs": 42060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.26,
            "patientTemp": 33.08,
            "power": -27.071823204419882,
            "timeMs": 42120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.81,
            "patientTemp": 33.08,
            "power": -25.383670963781462,
            "timeMs": 42180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 24.81,
            "patientTemp": 33.07,
            "power": -25.360761436905133,
            "timeMs": 42240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.31,
            "patientTemp": 33.07,
            "power": -23.825606386245017,
            "timeMs": 42300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.2,
            "patientTemp": 33.07,
            "power": -24.16334049739024,
            "timeMs": 42360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.7,
            "patientTemp": 33.06,
            "power": -22.60442260442261,
            "timeMs": 42420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.15,
            "patientTemp": 33.05,
            "power": -21.198156682027648,
            "timeMs": 42480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.92,
            "patientTemp": 33.05,
            "power": -18.832565284178173,
            "timeMs": 42540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.59,
            "patientTemp": 33.04,
            "power": -16.748617086662566,
            "timeMs": 42600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.2,
            "patientTemp": 33.03,
            "power": -14.847832769751005,
            "timeMs": 42660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.7,
            "patientTemp": 33.02,
            "power": -13.284132841328425,
            "timeMs": 42720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.2,
            "patientTemp": 33.02,
            "power": -11.746617466174673,
            "timeMs": 42780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.7,
            "patientTemp": 33.01,
            "power": -10.18148262073208,
            "timeMs": 42840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 33.01,
            "power": -8.643494309443245,
            "timeMs": 42900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.65,
            "patientTemp": 33.01,
            "power": -7.259304829283296,
            "timeMs": 42960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 43020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33,
            "power": -4.676923076923075,
            "timeMs": 43080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 43140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 43200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 43260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.98,
            "power": 0,
            "timeMs": 43320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 43380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 43440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 43500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 43560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 43620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 43680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 43740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 43800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 43860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 43920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 43980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.99,
            "power": 4.217536071032137,
            "timeMs": 44040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 44100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 44160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 44220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 44280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 44340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 44400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 44460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 44520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 44580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 44640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 44700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 44760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 44820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 44880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 44940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 45000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 45060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 45120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 45180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 45240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 45300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 45360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 45420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 45480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 45540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 45600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.92,
            "patientTemp": 33.01,
            "power": -6.428791141187316,
            "timeMs": 45660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 45720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.81,
            "patientTemp": 33.01,
            "power": -6.767148569670869,
            "timeMs": 45780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 45840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 45900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 45960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.87,
            "patientTemp": 33.01,
            "power": -6.582589972316201,
            "timeMs": 46020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 46080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 46140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 46200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 46260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 46320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33.01,
            "power": -4.890802829898481,
            "timeMs": 46380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 46440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 46500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 46560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 46620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.98,
            "patientTemp": 33.01,
            "power": -6.24423254383266,
            "timeMs": 46680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 46740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 46800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 46860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 46920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 46980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 47040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 47100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 47160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 47220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 47280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 47340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 47400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 47460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 47520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 47580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 47640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33.01,
            "power": -5.5675176868655765,
            "timeMs": 47700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 47760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 47820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 47880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 47940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 48000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 48060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33.01,
            "power": -3.6911719470932005,
            "timeMs": 48120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 48180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 48240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 48300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 48360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 48420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 48480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33.01,
            "power": -1.9686250384497097,
            "timeMs": 48540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 48600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 48660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33.01,
            "power": -2.153183635804355,
            "timeMs": 48720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 48780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 48840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 48900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 48960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 49020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 49080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 49140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 49200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 49260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 49320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 49380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 49440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 49500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 49560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 49620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 49680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33.01,
            "power": -2.3069824669332517,
            "timeMs": 49740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 49800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 49860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33,
            "power": -4.861538461538456,
            "timeMs": 49920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 49980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 50040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 50100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 50160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33.01,
            "power": -2.3069824669332517,
            "timeMs": 50220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 50280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 50340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.26,
            "patientTemp": 33.01,
            "power": -5.382959089510909,
            "timeMs": 50400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 50460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 50520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 50580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 50640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 50700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 50760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.31,
            "patientTemp": 33.01,
            "power": -5.229160258382034,
            "timeMs": 50820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 50880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 50940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 51000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 51060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33.01,
            "power": -4.183328206705628,
            "timeMs": 51120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.09,
            "patientTemp": 33.01,
            "power": -5.905875115349118,
            "timeMs": 51180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 51240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": -6.059673946478004,
            "timeMs": 51300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33,
            "power": -4.861538461538456,
            "timeMs": 51360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 51420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 51480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 51540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 51600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 51660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 51720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 33,
            "power": -4.153846153846158,
            "timeMs": 51780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 51840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 51900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 51960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.42,
            "patientTemp": 33,
            "power": -4.861538461538456,
            "timeMs": 52020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 52080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 52140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 52200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 32.99,
            "power": -0.030778701138827688,
            "timeMs": 52260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 52320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 52380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 52440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": -0.5540166204986141,
            "timeMs": 52500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.98,
            "power": 2.4390243902439677,
            "timeMs": 52560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.98,
            "power": 6.762749445676346,
            "timeMs": 52620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 52680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.98,
            "power": 6.208425720620866,
            "timeMs": 52740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 52800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.98,
            "power": 4.878048780487857,
            "timeMs": 52860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 52920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 52980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 53040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 53100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 53160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 53220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 53280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.92,
            "patientTemp": 32.97,
            "power": 21.594684385382088,
            "timeMs": 53340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.2,
            "patientTemp": 32.98,
            "power": 24.611973392461255,
            "timeMs": 53400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 53460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 53520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 53580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 53640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 53700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 53760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 53820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 53880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 53940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 54000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 54060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 54120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 54180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 54240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 54300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 54360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 54420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 54480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 54540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 54600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 54660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 54720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 54780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 54840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 54900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 54960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 55020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 55080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 55140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 55200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 55260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 55320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 55380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 55440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 55500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 55560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 55620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 55680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.99,
            "power": 9.766925638179751,
            "timeMs": 55740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 55800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 55860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 55920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 55980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.98,
            "power": 4.878048780487857,
            "timeMs": 56040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 56100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.97,
            "power": 12.403100775193847,
            "timeMs": 56160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.97,
            "power": 15.503875968992231,
            "timeMs": 56220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.97,
            "power": 18.60465116279069,
            "timeMs": 56280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.92,
            "patientTemp": 32.97,
            "power": 21.594684385382088,
            "timeMs": 56340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.15,
            "patientTemp": 32.97,
            "power": 24.141749723145065,
            "timeMs": 56400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.42,
            "patientTemp": 32.96,
            "power": 27.212389380530986,
            "timeMs": 56460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.59,
            "patientTemp": 32.97,
            "power": 29.014396456256968,
            "timeMs": 56520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.87,
            "patientTemp": 32.97,
            "power": 21.040974529346602,
            "timeMs": 56580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.09,
            "patientTemp": 32.97,
            "power": 23.477297895902595,
            "timeMs": 56640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 56700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.98,
            "patientTemp": 32.98,
            "power": 22.172949002217287,
            "timeMs": 56760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 56820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 56880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.92,
            "patientTemp": 32.98,
            "power": 21.507760532150822,
            "timeMs": 56940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.15,
            "patientTemp": 32.98,
            "power": 24.057649667405776,
            "timeMs": 57000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 57060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 57120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 57180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 57240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 57300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.87,
            "patientTemp": 32.98,
            "power": 20.953436807095343,
            "timeMs": 57360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 57420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 57480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 57540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.87,
            "patientTemp": 32.98,
            "power": 20.953436807095343,
            "timeMs": 57600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 57660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 57720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 57780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 57840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 57900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 57960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 58020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 58080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 58140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 58200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 58260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 58320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 58380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 58440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 58500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 58560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 58620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 58680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 58740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 58800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 58860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 58920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 58980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 59040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 59100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 59160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 59220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 59280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 59340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 59400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 59460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 59520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 59580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 59640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.99,
            "power": 11.65371809100996,
            "timeMs": 59700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.99,
            "power": 11.65371809100996,
            "timeMs": 59760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.98,
            "power": 6.762749445676346,
            "timeMs": 59820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 59880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 59940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 60000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 60060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.99,
            "power": 4.217536071032137,
            "timeMs": 60120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 60180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 60240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 60300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 60360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 60420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 60480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.99,
            "power": 13.429522752497238,
            "timeMs": 60540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 60600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 60660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.99,
            "power": 2.996670366259668,
            "timeMs": 60720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.99,
            "power": 7.325194228634814,
            "timeMs": 60780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.99,
            "power": 9.766925638179751,
            "timeMs": 60840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.98,
            "power": 4.323725055432377,
            "timeMs": 60900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 60960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 61020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 61080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 61140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 61200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 61260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 61320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 61380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 61440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 61500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.99,
            "power": 12.87458379578243,
            "timeMs": 61560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.99,
            "power": 4.217536071032137,
            "timeMs": 61620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 61680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 61740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.99,
            "power": 2.996670366259668,
            "timeMs": 61800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.99,
            "power": 7.325194228634814,
            "timeMs": 61860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 61920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 61980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 62040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.99,
            "power": 9.766925638179751,
            "timeMs": 62100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 62160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 62220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 62280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.99,
            "power": 7.325194228634814,
            "timeMs": 62340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0.5549389567147299,
            "timeMs": 62400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 62460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 62520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 62580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 62640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 62700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 62760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 62820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 62880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 62940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 63000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.98,
            "power": 5.543237250554322,
            "timeMs": 63060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 63120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.99,
            "power": 13.429522752497238,
            "timeMs": 63180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.98,
            "power": 1.8847006651884883,
            "timeMs": 63240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 63300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 63360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 63420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 63480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 63540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.99,
            "power": 9.100998890122092,
            "timeMs": 63600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.98,
            "power": 2.4390243902439677,
            "timeMs": 63660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 63720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 63780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 63840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 63900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 63960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 64020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 64080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 64140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 64200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 64260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 64320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 64380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 64440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 64500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 64560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 64620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 64680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 64740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 64800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 64860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.99,
            "power": 11.65371809100996,
            "timeMs": 64920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 64980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 65040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.99,
            "power": 11.65371809100996,
            "timeMs": 65100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.98,
            "power": 2.4390243902439677,
            "timeMs": 65160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.99,
            "power": 7.325194228634814,
            "timeMs": 65220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 65280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.98,
            "power": 2.4390243902439677,
            "timeMs": 65340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 65400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 65460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.98,
            "power": 6.762749445676346,
            "timeMs": 65520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 65580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 65640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 65700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 65760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 65820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 65880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 65940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 0,
            "timeMs": 66000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.65,
            "patientTemp": 32.98,
            "power": 29.600886917960096,
            "timeMs": 66060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 66120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 66180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 66240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 66300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 66360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 66420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 66480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 66540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.98,
            "power": 5.543237250554322,
            "timeMs": 66600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 66660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 66720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 66780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 66840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 66900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 66960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 67020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 67080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": -0.3693444136657573,
            "timeMs": 67140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.99,
            "power": 2.996670366259668,
            "timeMs": 67200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 67260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": -0.3693444136657573,
            "timeMs": 67320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 67380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 67440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.99,
            "power": 11.65371809100996,
            "timeMs": 67500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 32.98,
            "power": 0,
            "timeMs": 67560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.98,
            "power": 4.323725055432377,
            "timeMs": 67620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 67680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 67740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 67800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 67860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 67920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 67980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 68040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 68100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 68160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.42,
            "patientTemp": 32.98,
            "power": 15.964523281596502,
            "timeMs": 68220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 68280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 68340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 68400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 68460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.81,
            "patientTemp": 32.98,
            "power": 9.201773835920234,
            "timeMs": 68520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 68580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 68640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 68700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 68760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 68820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.99,
            "power": 8.546059933407282,
            "timeMs": 68880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 68940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 69120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 69540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 69600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.98,
            "power": 3.104212860310433,
            "timeMs": 69660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.98,
            "power": 5.543237250554322,
            "timeMs": 69720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 69780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.98,
            "patientTemp": 32.98,
            "power": 11.086474501108643,
            "timeMs": 69840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 69900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 69960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 70020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 70080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 70140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 70200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 70260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.26,
            "patientTemp": 32.98,
            "power": 14.190687361419076,
            "timeMs": 70320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 70380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.87,
            "patientTemp": 32.98,
            "power": 20.953436807095343,
            "timeMs": 70440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 70500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 70560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 70620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.92,
            "patientTemp": 32.97,
            "power": 21.594684385382088,
            "timeMs": 70680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.15,
            "patientTemp": 32.97,
            "power": 24.141749723145065,
            "timeMs": 70740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.97,
            "power": 18.60465116279069,
            "timeMs": 70800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.87,
            "patientTemp": 32.97,
            "power": 21.040974529346602,
            "timeMs": 70860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.15,
            "patientTemp": 32.97,
            "power": 24.141749723145065,
            "timeMs": 70920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.42,
            "patientTemp": 32.96,
            "power": 27.212389380530986,
            "timeMs": 70980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.65,
            "patientTemp": 32.97,
            "power": 29.678848283499438,
            "timeMs": 71040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.87,
            "patientTemp": 32.97,
            "power": 32.11517165005535,
            "timeMs": 71100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.54,
            "patientTemp": 32.96,
            "power": 28.53982300884954,
            "timeMs": 71160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.42,
            "patientTemp": 32.97,
            "power": 27.13178294573646,
            "timeMs": 71220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.65,
            "patientTemp": 32.96,
            "power": 29.75663716814157,
            "timeMs": 71280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.81,
            "patientTemp": 32.96,
            "power": 31.526548672566392,
            "timeMs": 71340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.98,
            "patientTemp": 32.95,
            "power": 33.48066298342536,
            "timeMs": 71400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.15,
            "patientTemp": 32.95,
            "power": 35.359116022099414,
            "timeMs": 71460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.31,
            "patientTemp": 32.95,
            "power": 37.127071823204425,
            "timeMs": 71520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.2,
            "patientTemp": 32.95,
            "power": 46.96132596685084,
            "timeMs": 71580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.92,
            "patientTemp": 32.95,
            "power": 43.867403314917134,
            "timeMs": 71640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.7,
            "patientTemp": 32.94,
            "power": 52.53863134657841,
            "timeMs": 71700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.42,
            "patientTemp": 32.94,
            "power": 49.44812362030908,
            "timeMs": 71760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.15,
            "patientTemp": 32.94,
            "power": 57.5055187637969,
            "timeMs": 71820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.04,
            "patientTemp": 32.94,
            "power": 56.29139072847682,
            "timeMs": 71880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.2,
            "patientTemp": 32.94,
            "power": 58.0573951434879,
            "timeMs": 71940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.42,
            "patientTemp": 32.94,
            "power": 60.48565121412807,
            "timeMs": 72000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.87,
            "patientTemp": 32.94,
            "power": 54.41501103752757,
            "timeMs": 72060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.59,
            "patientTemp": 32.94,
            "power": 62.36203090507731,
            "timeMs": 72120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.7,
            "patientTemp": 32.94,
            "power": 52.53863134657841,
            "timeMs": 72180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.94,
            "power": 53.7527593818985,
            "timeMs": 72240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.2,
            "patientTemp": 32.94,
            "power": 58.0573951434879,
            "timeMs": 72300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.94,
            "power": 53.7527593818985,
            "timeMs": 72360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.95,
            "power": 53.701657458563545,
            "timeMs": 72420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.7,
            "patientTemp": 32.95,
            "power": 52.48618784530389,
            "timeMs": 72480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.04,
            "patientTemp": 32.94,
            "power": 56.29139072847682,
            "timeMs": 72540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.94,
            "power": 53.7527593818985,
            "timeMs": 72600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.76,
            "patientTemp": 32.95,
            "power": 53.1491712707182,
            "timeMs": 72660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.2,
            "patientTemp": 32.94,
            "power": 58.0573951434879,
            "timeMs": 72720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.37,
            "patientTemp": 32.94,
            "power": 48.89624724061809,
            "timeMs": 72780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.31,
            "patientTemp": 32.94,
            "power": 59.27152317880798,
            "timeMs": 72840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.37,
            "patientTemp": 32.94,
            "power": 48.89624724061809,
            "timeMs": 72900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.42,
            "patientTemp": 32.94,
            "power": 60.48565121412807,
            "timeMs": 72960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.37,
            "patientTemp": 32.94,
            "power": 48.89624724061809,
            "timeMs": 73020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.42,
            "patientTemp": 32.95,
            "power": 60.44198895027625,
            "timeMs": 73080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 38.09,
            "patientTemp": 32.95,
            "power": 56.795580110497255,
            "timeMs": 73140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.7,
            "patientTemp": 32.95,
            "power": 52.48618784530389,
            "timeMs": 73200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.95,
            "power": 53.701657458563545,
            "timeMs": 73260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.59,
            "patientTemp": 32.95,
            "power": 51.270718232044224,
            "timeMs": 73320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 37.81,
            "patientTemp": 32.95,
            "power": 53.701657458563545,
            "timeMs": 73380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.98,
            "patientTemp": 32.95,
            "power": 44.53038674033144,
            "timeMs": 73440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.65,
            "patientTemp": 32.96,
            "power": 40.81858407079644,
            "timeMs": 73500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.7,
            "patientTemp": 32.96,
            "power": 41.37168141592923,
            "timeMs": 73560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.54,
            "patientTemp": 32.96,
            "power": 39.601769911504405,
            "timeMs": 73620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.92,
            "patientTemp": 32.96,
            "power": 32.74336283185842,
            "timeMs": 73680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.09,
            "patientTemp": 32.96,
            "power": 34.62389380530976,
            "timeMs": 73740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.2,
            "patientTemp": 32.96,
            "power": 35.8407079646018,
            "timeMs": 73800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.26,
            "patientTemp": 32.96,
            "power": 36.504424778761035,
            "timeMs": 73860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.37,
            "patientTemp": 32.96,
            "power": 37.72123893805306,
            "timeMs": 73920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.48,
            "patientTemp": 32.96,
            "power": 38.93805309734509,
            "timeMs": 73980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.26,
            "patientTemp": 32.96,
            "power": 36.504424778761035,
            "timeMs": 74040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.87,
            "patientTemp": 32.96,
            "power": 32.19026548672563,
            "timeMs": 74100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.04,
            "patientTemp": 32.96,
            "power": 34.07079646017698,
            "timeMs": 74160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.92,
            "patientTemp": 32.97,
            "power": 32.668881506090834,
            "timeMs": 74220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.42,
            "patientTemp": 32.97,
            "power": 27.13178294573646,
            "timeMs": 74280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.65,
            "patientTemp": 32.96,
            "power": 29.75663716814157,
            "timeMs": 74340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.81,
            "patientTemp": 32.96,
            "power": 31.526548672566392,
            "timeMs": 74400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.92,
            "patientTemp": 32.96,
            "power": 32.74336283185842,
            "timeMs": 74460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.04,
            "patientTemp": 32.96,
            "power": 34.07079646017698,
            "timeMs": 74520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.2,
            "patientTemp": 32.96,
            "power": 35.8407079646018,
            "timeMs": 74580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.09,
            "patientTemp": 32.96,
            "power": 34.62389380530976,
            "timeMs": 74640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.54,
            "patientTemp": 32.96,
            "power": 28.53982300884954,
            "timeMs": 74700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.76,
            "patientTemp": 32.96,
            "power": 30.973451327433597,
            "timeMs": 74760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.92,
            "patientTemp": 32.96,
            "power": 32.74336283185842,
            "timeMs": 74820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.09,
            "patientTemp": 32.96,
            "power": 34.62389380530976,
            "timeMs": 74880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 36.2,
            "patientTemp": 32.96,
            "power": 35.8407079646018,
            "timeMs": 74940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.42,
            "patientTemp": 32.97,
            "power": 27.13178294573646,
            "timeMs": 75000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.65,
            "patientTemp": 32.97,
            "power": 29.678848283499438,
            "timeMs": 75060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.81,
            "patientTemp": 32.97,
            "power": 31.45071982281288,
            "timeMs": 75120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.04,
            "patientTemp": 32.97,
            "power": 22.92358803986711,
            "timeMs": 75180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.37,
            "patientTemp": 32.97,
            "power": 26.578073089700975,
            "timeMs": 75240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.59,
            "patientTemp": 32.97,
            "power": 29.014396456256968,
            "timeMs": 75300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.09,
            "patientTemp": 32.97,
            "power": 23.477297895902595,
            "timeMs": 75360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.26,
            "patientTemp": 32.98,
            "power": 25.27716186252772,
            "timeMs": 75420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.98,
            "patientTemp": 32.98,
            "power": 22.172949002217287,
            "timeMs": 75480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 35.15,
            "patientTemp": 32.98,
            "power": 24.057649667405776,
            "timeMs": 75540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 75600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 75660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 75720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.7,
            "patientTemp": 32.98,
            "power": 19.068736141906932,
            "timeMs": 75780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 75840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 75900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.65,
            "patientTemp": 32.98,
            "power": 7.4279379157428105,
            "timeMs": 75960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.04,
            "patientTemp": 32.98,
            "power": 11.751662971175188,
            "timeMs": 76020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.98,
            "power": 7.982261640798289,
            "timeMs": 76080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 76140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.98,
            "power": 5.543237250554322,
            "timeMs": 76200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 76260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.98,
            "power": 6.208425720620866,
            "timeMs": 76320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 76380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.31,
            "patientTemp": 32.98,
            "power": 14.745011086474555,
            "timeMs": 76440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.59,
            "patientTemp": 32.98,
            "power": 17.84922394678499,
            "timeMs": 76500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 76560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 76620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 76680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 76740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 76800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.54,
            "patientTemp": 32.98,
            "power": 17.29490022172951,
            "timeMs": 76860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.76,
            "patientTemp": 32.98,
            "power": 19.733924611973396,
            "timeMs": 76920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.37,
            "patientTemp": 32.98,
            "power": 15.410199556541022,
            "timeMs": 76980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.65,
            "patientTemp": 32.98,
            "power": 18.514412416851453,
            "timeMs": 77040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.81,
            "patientTemp": 32.98,
            "power": 20.28824833702888,
            "timeMs": 77100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.98,
            "power": 10.421286031042179,
            "timeMs": 77160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.15,
            "patientTemp": 32.98,
            "power": 12.971175166297133,
            "timeMs": 77220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 77280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 77340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 77400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.7,
            "patientTemp": 32.99,
            "power": 7.8801331853496235,
            "timeMs": 77460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.98,
            "power": 4.323725055432377,
            "timeMs": 77520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.76,
            "patientTemp": 32.98,
            "power": 8.647450110864755,
            "timeMs": 77580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.98,
            "power": 12.305986696230667,
            "timeMs": 77640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.98,
            "power": 5.543237250554322,
            "timeMs": 77700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 77760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 77820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.48,
            "patientTemp": 32.98,
            "power": 16.629711751662963,
            "timeMs": 77880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.87,
            "patientTemp": 32.98,
            "power": 9.866962305986698,
            "timeMs": 77940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.2,
            "patientTemp": 32.98,
            "power": 13.52549889135261,
            "timeMs": 78000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.98,
            "power": 6.762749445676346,
            "timeMs": 78060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.92,
            "patientTemp": 32.99,
            "power": 10.32186459489456,
            "timeMs": 78120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 78180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 78240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": -0.5540166204986141,
            "timeMs": 78300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 78360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 32.99,
            "power": -1.0464758387196165,
            "timeMs": 78420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 78480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 78540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 78600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 78660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": -0.3693444136657573,
            "timeMs": 78720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 78780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0.5549389567147299,
            "timeMs": 78840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 78900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 78960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 79020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 79080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 79140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 79200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 79260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 79320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 32.99,
            "power": -1.0464758387196165,
            "timeMs": 79380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 79440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 32.99,
            "power": -0.7079101261926869,
            "timeMs": 79500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 79560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.59,
            "patientTemp": 32.99,
            "power": 6.659267480577154,
            "timeMs": 79620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 32.99,
            "power": -0.21545090797168445,
            "timeMs": 79680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.37,
            "patientTemp": 32.99,
            "power": 4.217536071032137,
            "timeMs": 79740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 32.99,
            "power": -1.0464758387196165,
            "timeMs": 79800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 79860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": -0.5540166204986141,
            "timeMs": 79920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 79980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 80040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 33,
            "power": 0.444444444444435,
            "timeMs": 80100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 80160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 33,
            "power": 0.444444444444435,
            "timeMs": 80220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 80280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 80340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 80400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 34.09,
            "patientTemp": 32.99,
            "power": 12.208657047724769,
            "timeMs": 80460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 32.99,
            "power": -0.8925823330255437,
            "timeMs": 80520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 80580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 80640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 33,
            "power": 1.0000000000000377,
            "timeMs": 80700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 80760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 33,
            "power": -0.5846153846153777,
            "timeMs": 80820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 80880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 80940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 81000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 81060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 81120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 81180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 81240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 81300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 81360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 81420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 81480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 81540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 81600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 81660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 81720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 32.99,
            "power": -0.21545090797168445,
            "timeMs": 81780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 81840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 33,
            "power": -0.06153846153847116,
            "timeMs": 81900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 81960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 82020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 82080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 33,
            "power": -0.5846153846153777,
            "timeMs": 82140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 82200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 82260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 82320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 82380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 82440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 82500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 82560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 82620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33,
            "power": -4.338461538461539,
            "timeMs": 82680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 82740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 82800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 82860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 82920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 82980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 83040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 83100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 83160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 83220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 83280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 83340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 83400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 83460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 83520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 83580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 83640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 83700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 83760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 83820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 83880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 83940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 84000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 84060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 84120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 84180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 84240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33,
            "power": -4.338461538461539,
            "timeMs": 84300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 84360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 84420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 84480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 84540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 84600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 84660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 84720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 84780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 84840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 84900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 84960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 85020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 85080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 85140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 85200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 85260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33,
            "power": -4.676923076923075,
            "timeMs": 85320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33.01,
            "power": -3.352814518609647,
            "timeMs": 85380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33.01,
            "power": -2.3069824669332517,
            "timeMs": 85440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 85500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 85560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 85620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 85680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 85740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 85800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 85860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 85920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 85980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 86040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 86100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 86160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 86220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33.01,
            "power": -3.844970778222075,
            "timeMs": 86280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": -4.367886804060284,
            "timeMs": 86340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.2,
            "patientTemp": 33,
            "power": -5.538461538461541,
            "timeMs": 86400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33,
            "power": -4.492307692307695,
            "timeMs": 86460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 86520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 86580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 86640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 86700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 86760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 32.99,
            "power": -0.7079101261926869,
            "timeMs": 86820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 86880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 86940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 87000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 87060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 87120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 87180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0.5549389567147299,
            "timeMs": 87240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 87300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 87360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 87420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 87480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 87540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 33,
            "power": -0.24615384615384092,
            "timeMs": 87600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 87660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 33,
            "power": -0.24615384615384092,
            "timeMs": 87720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 87780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 87840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 87900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 33,
            "power": -0.06153846153847116,
            "timeMs": 87960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 88020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 88080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 88140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 88200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 88260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 88320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 88380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 88440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 88500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 88560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 88620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 88680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 88740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 88800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 88860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 88920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 88980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 89040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 89100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 89160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 89220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 89280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 89340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 89400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 89460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33,
            "power": -3.476923076923074,
            "timeMs": 89520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 89580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 89640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 89700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 89760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 89820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 89880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 89940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 90000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 90060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 90120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 33,
            "power": -0.06153846153847116,
            "timeMs": 90180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 90240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 33,
            "power": -0.24615384615384092,
            "timeMs": 90300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 90360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 33,
            "power": -0.24615384615384092,
            "timeMs": 90420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 90480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 90540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 90600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 90660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 90720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 90780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 90840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 90900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 90960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 91020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 91080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 91140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 91200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 91260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 91320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 91380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 91440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 91500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 91560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 91620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 91680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": -0.3693444136657573,
            "timeMs": 91740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.99,
            "power": 2.996670366259668,
            "timeMs": 91800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 91860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 91920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 91980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 92040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 92100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 33,
            "power": -0.5846153846153777,
            "timeMs": 92160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 92220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 33,
            "power": -1.6000000000000099,
            "timeMs": 92280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 92340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 33,
            "power": -2.123076923076916,
            "timeMs": 92400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 92460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 92520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.76,
            "patientTemp": 33,
            "power": -3.8153846153846107,
            "timeMs": 92580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 92640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 92700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 92760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 33,
            "power": -0.9230769230769144,
            "timeMs": 92820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 92880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 92940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 33,
            "power": -0.5846153846153777,
            "timeMs": 93000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 33,
            "power": 1.0000000000000377,
            "timeMs": 93060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 93120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 93180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 93240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 93300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 93360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 93420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 93480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 93540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 33,
            "power": -0.06153846153847116,
            "timeMs": 93600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.31,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 93660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 93720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 93780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 93840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 93900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 93960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 94020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 94080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.76,
            "patientTemp": 33,
            "power": -0.7384615384615446,
            "timeMs": 94140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 94200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 94260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33,
            "power": -3.6615384615384654,
            "timeMs": 94320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33.01,
            "power": -2.8298984927714383,
            "timeMs": 94380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 94440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33.01,
            "power": -2.9836973239003353,
            "timeMs": 94500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 94560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 94620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 94680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.04,
            "patientTemp": 33,
            "power": -2.9538461538461567,
            "timeMs": 94740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 94800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.54,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 94860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.42,
            "patientTemp": 33,
            "power": 0,
            "timeMs": 94920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.15,
            "patientTemp": 33,
            "power": -21.07692307692308,
            "timeMs": 94980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 25.87,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 26.59,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.2,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 27.76,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.26,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 28.76,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.26,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 29.76,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.2,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 30.7,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.65,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 95880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.54,
            "patientTemp": 32.99,
            "power": 6.104328523862345,
            "timeMs": 95940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 32.99,
            "power": -0.21545090797168445,
            "timeMs": 96000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.92,
            "patientTemp": 32.99,
            "power": -0.21545090797168445,
            "timeMs": 96060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 96120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.31,
            "patientTemp": 32.99,
            "power": 3.551609322974477,
            "timeMs": 96180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 96240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.26,
            "patientTemp": 32.99,
            "power": 2.996670366259668,
            "timeMs": 96300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 32.99,
            "power": -1.2311480455524733,
            "timeMs": 96360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.04,
            "patientTemp": 32.99,
            "power": 0.5549389567147299,
            "timeMs": 96420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.7,
            "patientTemp": 32.99,
            "power": -0.8925823330255437,
            "timeMs": 96480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 96540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.48,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 96600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.98,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 96660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 0,
            "timeMs": 96720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 32.99,
            "power": -0.3693444136657573,
            "timeMs": 96780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.15,
            "patientTemp": 32.99,
            "power": 1.775804661487199,
            "timeMs": 96840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.48,
            "patientTemp": 32.99,
            "power": 5.438401775804605,
            "timeMs": 96900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.42,
            "patientTemp": 32.99,
            "power": 4.772475027746946,
            "timeMs": 96960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.09,
            "patientTemp": 32.99,
            "power": 1.1098779134295387,
            "timeMs": 97020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.81,
            "patientTemp": 32.99,
            "power": -0.5540166204986141,
            "timeMs": 97080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 33.2,
            "patientTemp": 32.99,
            "power": 2.330743618202008,
            "timeMs": 97140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 97200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.87,
            "patientTemp": 33,
            "power": -0.4000000000000079,
            "timeMs": 97260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 97320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 97380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 97440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 97500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 97560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33.01,
            "power": -3.1682559212549912,
            "timeMs": 97620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 97680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.81,
            "patientTemp": 33.01,
            "power": -3.6911719470932005,
            "timeMs": 97740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 97800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 97860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 97920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.48,
            "patientTemp": 33.01,
            "power": -4.706244232543826,
            "timeMs": 97980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33.01,
            "power": -4.521685635189169,
            "timeMs": 98040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.37,
            "patientTemp": 33.01,
            "power": -5.044601661027367,
            "timeMs": 98100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.87,
            "patientTemp": 33.01,
            "power": -3.5066133497385334,
            "timeMs": 98160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.54,
            "patientTemp": 33,
            "power": -4.492307692307695,
            "timeMs": 98220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 98280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 98340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33,
            "power": -4.000000000000002,
            "timeMs": 98400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 98460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 98520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 98580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 98640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.09,
            "patientTemp": 33,
            "power": -2.7999999999999896,
            "timeMs": 98700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 98760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.42,
            "patientTemp": 33,
            "power": -1.7846153846153794,
            "timeMs": 98820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 98880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.65,
            "patientTemp": 33,
            "power": -1.0769230769230813,
            "timeMs": 98940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 99000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.54,
            "patientTemp": 33,
            "power": -1.415384615384618,
            "timeMs": 99060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.26,
            "patientTemp": 33,
            "power": -2.276923076923083,
            "timeMs": 99120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.59,
            "patientTemp": 33,
            "power": -1.261538461538451,
            "timeMs": 99180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.92,
            "patientTemp": 33,
            "power": -3.3230769230769175,
            "timeMs": 99240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.2,
            "patientTemp": 33,
            "power": -2.461538461538453,
            "timeMs": 99300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 99360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.15,
            "patientTemp": 33,
            "power": -2.61538461538462,
            "timeMs": 99420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 32.37,
            "patientTemp": 33,
            "power": -1.9384615384615462,
            "timeMs": 99480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.98,
            "patientTemp": 33,
            "power": -3.138461538461537,
            "timeMs": 99540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.7,
            "patientTemp": 33.01,
            "power": -4.029529375576741,
            "timeMs": 99600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.15,
            "patientTemp": 33.01,
            "power": -5.721316517994461,
            "timeMs": 99660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 33,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 99720000,
            "state": "Standby",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.48,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 99780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.04,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 99840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.59,
            "patientTemp": 33.01,
            "power": 0,
            "timeMs": 99900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.15,
            "patientTemp": 33.02,
            "power": 0,
            "timeMs": 99960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.7,
            "patientTemp": 33.02,
            "power": 0,
            "timeMs": 100020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 33.02,
            "power": 2.0044543429844075,
            "timeMs": 100080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 33.02,
            "power": 5.790645879732697,
            "timeMs": 100140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 33.02,
            "power": 7.57238307349666,
            "timeMs": 100200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 33.02,
            "power": 10.022271714922036,
            "timeMs": 100260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 33.02,
            "power": 13.140311804008912,
            "timeMs": 100320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 33.02,
            "power": 16.258351893095703,
            "timeMs": 100380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 33.03,
            "power": 27.313266443701185,
            "timeMs": 100440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 33.03,
            "power": 46.48829431438129,
            "timeMs": 100500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 33.03,
            "power": 58.30546265328871,
            "timeMs": 100560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 33.04,
            "power": 60.04464285714288,
            "timeMs": 100620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 33.05,
            "power": 49.49720670391059,
            "timeMs": 100680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 33.06,
            "power": 55.70469798657716,
            "timeMs": 100740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 33.06,
            "power": 45.07829977628638,
            "timeMs": 100800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.07,
            "power": 43.78499440089582,
            "timeMs": 100860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 33.07,
            "power": 45.01679731243004,
            "timeMs": 100920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 33.08,
            "power": 37.44394618834084,
            "timeMs": 100980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 33.09,
            "power": 35.578002244668866,
            "timeMs": 101040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 33.1,
            "power": 36.74157303370782,
            "timeMs": 101100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 33.11,
            "power": 29.808773903262075,
            "timeMs": 101160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 33.11,
            "power": 27.334083239595046,
            "timeMs": 101220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 33.12,
            "power": 24.099099099099096,
            "timeMs": 101280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.12,
            "power": 22.184684684684747,
            "timeMs": 101340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.13,
            "power": 25.25366403607661,
            "timeMs": 101400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.14,
            "power": 22.009029345372493,
            "timeMs": 101460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 33.14,
            "power": 19.525959367945788,
            "timeMs": 101520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 33.15,
            "power": 22.598870056497173,
            "timeMs": 101580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 33.15,
            "power": 19.435028248587553,
            "timeMs": 101640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 33.16,
            "power": 20.588235294117645,
            "timeMs": 101700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 33.16,
            "power": 23.07692307692314,
            "timeMs": 101760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.17,
            "power": 25.481313703284265,
            "timeMs": 101820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 33.17,
            "power": 24.235560588901485,
            "timeMs": 101880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.18,
            "power": 21.655328798185984,
            "timeMs": 101940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 33.18,
            "power": 24.149659863945608,
            "timeMs": 102000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 33.19,
            "power": 20.31782065834278,
            "timeMs": 102060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 33.2,
            "power": 20.909090909090875,
            "timeMs": 102120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 33.2,
            "power": 17.727272727272677,
            "timeMs": 102180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 33.21,
            "power": 14.44823663253693,
            "timeMs": 102240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 33.22,
            "power": 11.845102505694749,
            "timeMs": 102300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 33.22,
            "power": 11.161731207289339,
            "timeMs": 102360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 33.23,
            "power": 11.744583808437865,
            "timeMs": 102420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 33.23,
            "power": 6.613454960091279,
            "timeMs": 102480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 33.24,
            "power": 10.958904109589053,
            "timeMs": 102540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 33.24,
            "power": 14.155251141552455,
            "timeMs": 102600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 33.24,
            "power": 14.155251141552455,
            "timeMs": 102660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 33.25,
            "power": 12.11428571428574,
            "timeMs": 102720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 33.25,
            "power": 15.314285714285752,
            "timeMs": 102780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 33.26,
            "power": 18.421052631578938,
            "timeMs": 102840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 33.26,
            "power": 17.734553775743752,
            "timeMs": 102900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 33.26,
            "power": 13.958810068649868,
            "timeMs": 102960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 33.27,
            "power": 17.640320733104232,
            "timeMs": 103020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.27,
            "power": 20.84765177548684,
            "timeMs": 103080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 33.27,
            "power": 23.367697594501717,
            "timeMs": 103140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 33.28,
            "power": 25.91743119266053,
            "timeMs": 103200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 33.29,
            "power": 25.143513203214667,
            "timeMs": 103260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.29,
            "power": 20.665901262916236,
            "timeMs": 103320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.3,
            "power": 23.793103448275858,
            "timeMs": 103380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 33.3,
            "power": 23.10344827586212,
            "timeMs": 103440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 33.31,
            "power": 18.527042577675488,
            "timeMs": 103500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 33.32,
            "power": 18.43317972350232,
            "timeMs": 103560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 33.32,
            "power": 17.165898617511544,
            "timeMs": 103620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.32,
            "power": 20.39170506912446,
            "timeMs": 103680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 33.33,
            "power": 22.837370242214575,
            "timeMs": 103740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 33.33,
            "power": 25.490196078431378,
            "timeMs": 103800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 33.34,
            "power": 17.667436489607326,
            "timeMs": 103860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 33.34,
            "power": 20.900692840646602,
            "timeMs": 103920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.35,
            "power": 23.930635838150298,
            "timeMs": 103980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 33.35,
            "power": 25.89595375722546,
            "timeMs": 104040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 33.36,
            "power": 21.296296296296337,
            "timeMs": 104100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 33.36,
            "power": 24.537037037037006,
            "timeMs": 104160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 33.37,
            "power": 25.724217844727754,
            "timeMs": 104220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 33.37,
            "power": 17.960602549246858,
            "timeMs": 104280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 33.38,
            "power": 21.11368909512762,
            "timeMs": 104340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.38,
            "power": 23.085846867749368,
            "timeMs": 104400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 33.39,
            "power": 14.634146341463392,
            "timeMs": 104460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 33.39,
            "power": 17.77003484320559,
            "timeMs": 104520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 33.4,
            "power": 20.93023255813958,
            "timeMs": 104580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.4,
            "power": 23.488372093023287,
            "timeMs": 104640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 33.41,
            "power": 24.796274738067538,
            "timeMs": 104700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 33.41,
            "power": 18.27706635622817,
            "timeMs": 104760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 33.42,
            "power": 20.745920745920763,
            "timeMs": 104820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.42,
            "power": 23.310023310023315,
            "timeMs": 104880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 33.43,
            "power": 16.102683780630134,
            "timeMs": 104940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 33.43,
            "power": 20.07001166861142,
            "timeMs": 105000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.44,
            "power": 22.546728971962608,
            "timeMs": 105060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 33.44,
            "power": 25.116822429906605,
            "timeMs": 105120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 33.45,
            "power": 19.883040935672472,
            "timeMs": 105180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.45,
            "power": 23.040935672514614,
            "timeMs": 105240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 33.45,
            "power": 24.44444444444441,
            "timeMs": 105300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 33.46,
            "power": 26.932084309133458,
            "timeMs": 105360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 33.46,
            "power": 28.805620608899314,
            "timeMs": 105420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 33.47,
            "power": 25.556858147713946,
            "timeMs": 105480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 33.47,
            "power": 28.135990621336436,
            "timeMs": 105540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 33.47,
            "power": 30.71512309495902,
            "timeMs": 105600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 33.48,
            "power": 31.92488262910804,
            "timeMs": 105660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 33.49,
            "power": 28.55464159811986,
            "timeMs": 105720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 33.49,
            "power": 30.552291421856665,
            "timeMs": 105780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 33.5,
            "power": 22.588235294117666,
            "timeMs": 105840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 33.51,
            "power": 25.206124852767964,
            "timeMs": 105900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 33.51,
            "power": 18.02120141342757,
            "timeMs": 105960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.52,
            "power": 21.816037735848997,
            "timeMs": 106020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 33.52,
            "power": 24.410377358490578,
            "timeMs": 106080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 33.52,
            "power": 27.004716981132077,
            "timeMs": 106140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 33.53,
            "power": 18.417945690672994,
            "timeMs": 106200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 33.53,
            "power": 21.723730814639865,
            "timeMs": 106260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 33.53,
            "power": 24.321133412042535,
            "timeMs": 106320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 33.54,
            "power": 26.241134751773032,
            "timeMs": 106380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 33.54,
            "power": 28.84160756501179,
            "timeMs": 106440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 33.55,
            "power": 30.769230769230777,
            "timeMs": 106500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 33.55,
            "power": 32.662721893491174,
            "timeMs": 106560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 33.55,
            "power": 33.96449704142016,
            "timeMs": 106620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 33.56,
            "power": 35.900473933649316,
            "timeMs": 106680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 33.56,
            "power": 37.20379146919433,
            "timeMs": 106740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 33.57,
            "power": 39.14590747330958,
            "timeMs": 106800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.57,
            "power": 40.45077105575322,
            "timeMs": 106860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 33.58,
            "power": 33.13539192399048,
            "timeMs": 106920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 33.58,
            "power": 35.74821852731597,
            "timeMs": 106980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 33.59,
            "power": 38.28775267538645,
            "timeMs": 107040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 33.59,
            "power": 39.59571938168846,
            "timeMs": 107100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 33.6,
            "power": 41.54761904761908,
            "timeMs": 107160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 33.6,
            "power": 42.85714285714288,
            "timeMs": 107220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 33.6,
            "power": 49.52380952380949,
            "timeMs": 107280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 33.61,
            "power": 38.14064362336118,
            "timeMs": 107340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.61,
            "power": 40.16686531585217,
            "timeMs": 107400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 33.62,
            "power": 48.68735083532225,
            "timeMs": 107460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 33.62,
            "power": 37.47016706443914,
            "timeMs": 107520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 33.63,
            "power": 39.30704898446834,
            "timeMs": 107580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 33.64,
            "power": 40.66985645933013,
            "timeMs": 107640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.64,
            "power": 41.98564593301433,
            "timeMs": 107700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 33.65,
            "power": 43.23353293413172,
            "timeMs": 107760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 33.65,
            "power": 48.50299401197609,
            "timeMs": 107820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 33.65,
            "power": 37.245508982035915,
            "timeMs": 107880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 33.66,
            "power": 49.76019184652283,
            "timeMs": 107940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.66,
            "power": 39.80815347721821,
            "timeMs": 108000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 33.67,
            "power": 51.020408163265316,
            "timeMs": 108060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 33.67,
            "power": 41.05642256902764,
            "timeMs": 108120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 33.67,
            "power": 52.460984393757485,
            "timeMs": 108180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.68,
            "power": 41.70673076923075,
            "timeMs": 108240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 33.68,
            "power": 56.37019230769228,
            "timeMs": 108300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 33.69,
            "power": 60.288808664259975,
            "timeMs": 108360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 33.69,
            "power": 46.32972322503009,
            "timeMs": 108420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 33.69,
            "power": 59.68712394705173,
            "timeMs": 108480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 33.7,
            "power": 46.8674698795181,
            "timeMs": 108540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 33.71,
            "power": 60.19300361881788,
            "timeMs": 108600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 33.71,
            "power": 48.13027744270208,
            "timeMs": 108660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 33.72,
            "power": 58.21256038647342,
            "timeMs": 108720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 33.72,
            "power": 45.41062801932364,
            "timeMs": 108780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 33.73,
            "power": 56.71100362756957,
            "timeMs": 108840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 33.73,
            "power": 44.014510278113654,
            "timeMs": 108900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 33.74,
            "power": 53.99515738498791,
            "timeMs": 108960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 33.75,
            "power": 41.818181818181856,
            "timeMs": 109020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 33.75,
            "power": 51.27272727272724,
            "timeMs": 109080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 33.76,
            "power": 39.80582524271845,
            "timeMs": 109140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 33.76,
            "power": 52.54854368932044,
            "timeMs": 109200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 33.77,
            "power": 39.73268529769134,
            "timeMs": 109260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 33.77,
            "power": 49.81773997569861,
            "timeMs": 109320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 33.78,
            "power": 36.86131386861316,
            "timeMs": 109380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 33.78,
            "power": 51.094890510948865,
            "timeMs": 109440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.79,
            "power": 38.85505481120581,
            "timeMs": 109500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 33.79,
            "power": 51.76613885505481,
            "timeMs": 109560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 33.8,
            "power": 39.51219512195123,
            "timeMs": 109620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 33.8,
            "power": 49.634146341463406,
            "timeMs": 109680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 33.81,
            "power": 37.97313797313798,
            "timeMs": 109740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 33.81,
            "power": 50.1831501831502,
            "timeMs": 109800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 33.82,
            "power": 37.28606356968212,
            "timeMs": 109860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 33.82,
            "power": 49.51100244498774,
            "timeMs": 109920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 33.83,
            "power": 37.8212974296206,
            "timeMs": 109980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 33.83,
            "power": 50.79559363525089,
            "timeMs": 110040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.83,
            "power": 38.55569155446754,
            "timeMs": 110100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 33.84,
            "power": 52.81862745098036,
            "timeMs": 110160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 33.84,
            "power": 41.911764705882305,
            "timeMs": 110220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 33.85,
            "power": 54.110429447852724,
            "timeMs": 110280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.86,
            "power": 40.4176904176904,
            "timeMs": 110340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 33.86,
            "power": 51.96560196560201,
            "timeMs": 110400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.87,
            "power": 38.25338253382532,
            "timeMs": 110460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 33.87,
            "power": 52.64452644526445,
            "timeMs": 110520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.87,
            "power": 40.34440344403444,
            "timeMs": 110580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 33.88,
            "power": 53.20197044334978,
            "timeMs": 110640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.88,
            "power": 40.270935960591096,
            "timeMs": 110700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 33.89,
            "power": 53.14426633785453,
            "timeMs": 110760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 33.89,
            "power": 40.81381011097414,
            "timeMs": 110820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 33.9,
            "power": 54.444444444444485,
            "timeMs": 110880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 33.91,
            "power": 42.027194066749125,
            "timeMs": 110940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 33.91,
            "power": 55.129789864029654,
            "timeMs": 111000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 33.91,
            "power": 42.768850432632874,
            "timeMs": 111060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 33.92,
            "power": 56.43564356435638,
            "timeMs": 111120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 33.92,
            "power": 44.05940594059401,
            "timeMs": 111180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 33.92,
            "power": 57.17821782178216,
            "timeMs": 111240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 33.93,
            "power": 61.21437422552661,
            "timeMs": 111300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 33.94,
            "power": 46.65012406947896,
            "timeMs": 111360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 33.94,
            "power": 58.43672456575681,
            "timeMs": 111420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 33.95,
            "power": 45.21739130434785,
            "timeMs": 111480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 33.96,
            "power": 56.965174129353215,
            "timeMs": 111540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 33.96,
            "power": 43.034825870646785,
            "timeMs": 111600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 33.97,
            "power": 52.67745952677464,
            "timeMs": 111660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.97,
            "power": 37.48443337484431,
            "timeMs": 111720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 33.98,
            "power": 50.62344139650874,
            "timeMs": 111780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 33.98,
            "power": 37.40648379052367,
            "timeMs": 111840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 33.99,
            "power": 50.56179775280897,
            "timeMs": 111900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 33.99,
            "power": 39.45068664169785,
            "timeMs": 111960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34,
            "power": 51.12500000000004,
            "timeMs": 112020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 34,
            "power": 37.24999999999996,
            "timeMs": 112080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34.01,
            "power": 51.06382978723409,
            "timeMs": 112140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 34.01,
            "power": 39.2991239048811,
            "timeMs": 112200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34.02,
            "power": 51.00250626566418,
            "timeMs": 112260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 34.02,
            "power": 39.223057644110234,
            "timeMs": 112320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 34.02,
            "power": 53.13283208020045,
            "timeMs": 112380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 34.03,
            "power": 43.28732747804261,
            "timeMs": 112440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 34.03,
            "power": 55.08155583437894,
            "timeMs": 112500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 34.04,
            "power": 44.59798994974879,
            "timeMs": 112560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 34.04,
            "power": 57.16080402010056,
            "timeMs": 112620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 34.04,
            "power": 46.73366834170852,
            "timeMs": 112680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.05,
            "power": 59.87421383647803,
            "timeMs": 112740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 34.05,
            "power": 48.05031446540879,
            "timeMs": 112800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 34.06,
            "power": 60.57934508816116,
            "timeMs": 112860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 34.07,
            "power": 48.54981084489283,
            "timeMs": 112920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 34.07,
            "power": 59.14249684741485,
            "timeMs": 112980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 34.08,
            "power": 45.707070707070756,
            "timeMs": 113040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 34.08,
            "power": 56.9444444444445,
            "timeMs": 113100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 34.09,
            "power": 42.85714285714279,
            "timeMs": 113160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 34.1,
            "power": 53.29113924050635,
            "timeMs": 113220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 34.1,
            "power": 39.24050632911395,
            "timeMs": 113280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 34.11,
            "power": 51.2040557667934,
            "timeMs": 113340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 34.11,
            "power": 37.13561470215462,
            "timeMs": 113400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 34.12,
            "power": 49.74619289340102,
            "timeMs": 113460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 34.12,
            "power": 39.08629441624371,
            "timeMs": 113520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34.12,
            "power": 50.38071065989853,
            "timeMs": 113580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 34.13,
            "power": 41.16899618805586,
            "timeMs": 113640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 34.13,
            "power": 52.47776365946629,
            "timeMs": 113700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 34.13,
            "power": 45.36213468869125,
            "timeMs": 113760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 34.14,
            "power": 57.379134860050875,
            "timeMs": 113820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 34.14,
            "power": 51.65394402035627,
            "timeMs": 113880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 34.14,
            "power": 62.34096692111958,
            "timeMs": 113940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 34.15,
            "power": 54.39490445859876,
            "timeMs": 114000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 34.16,
            "power": 64.28571428571433,
            "timeMs": 114060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 34.16,
            "power": 53.698979591836725,
            "timeMs": 114120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 34.17,
            "power": 0,
            "timeMs": 114180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 34.17,
            "power": 0,
            "timeMs": 114240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 34.18,
            "power": 0,
            "timeMs": 114300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.42,
            "patientTemp": 34.18,
            "power": 0,
            "timeMs": 114360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.98,
            "patientTemp": 34.18,
            "power": -6.532066508313537,
            "timeMs": 114420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 34.18,
            "power": 2.429667519181556,
            "timeMs": 114480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 34.18,
            "power": 26.598465473145755,
            "timeMs": 114540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 34.18,
            "power": 47.186700767263396,
            "timeMs": 114600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 34.18,
            "power": 64.96163682864447,
            "timeMs": 114660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.54,
            "patientTemp": 34.18,
            "power": 81.32992327365727,
            "timeMs": 114720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 34.18,
            "power": 94.11764705882352,
            "timeMs": 114780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.26,
            "patientTemp": 34.19,
            "power": 100,
            "timeMs": 114840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 34.2,
            "power": 100,
            "timeMs": 114900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 34.2,
            "power": 96.15384615384619,
            "timeMs": 114960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 34.21,
            "power": 96.14890885750967,
            "timeMs": 115020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 34.22,
            "power": 89.0745501285347,
            "timeMs": 115080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.42,
            "patientTemp": 34.23,
            "power": 92.53539253539256,
            "timeMs": 115140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 34.23,
            "power": 83.2689832689833,
            "timeMs": 115200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 34.24,
            "power": 85.43814432989687,
            "timeMs": 115260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 34.25,
            "power": 71.741935483871,
            "timeMs": 115320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 34.26,
            "power": 76.74418604651167,
            "timeMs": 115380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 34.27,
            "power": 63.130659767140976,
            "timeMs": 115440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 34.28,
            "power": 68.78238341968917,
            "timeMs": 115500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 34.29,
            "power": 54.34500648508427,
            "timeMs": 115560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 34.3,
            "power": 59.350649350649334,
            "timeMs": 115620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 34.3,
            "power": 47.012987012987054,
            "timeMs": 115680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 34.31,
            "power": 51.36540962288683,
            "timeMs": 115740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 34.31,
            "power": 42.00260078023404,
            "timeMs": 115800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 34.32,
            "power": 46.87500000000002,
            "timeMs": 115860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 34.32,
            "power": 52.734374999999964,
            "timeMs": 115920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 34.32,
            "power": 43.35937499999998,
            "timeMs": 115980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34.33,
            "power": 49.02216427640162,
            "timeMs": 116040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 34.33,
            "power": 50.45632333767932,
            "timeMs": 116100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 34.33,
            "power": 56.32333767926987,
            "timeMs": 116160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 34.33,
            "power": 68.57887874837031,
            "timeMs": 116220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.33,
            "power": 58.409387222946584,
            "timeMs": 116280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 34.33,
            "power": 63.49413298565846,
            "timeMs": 116340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 34.34,
            "power": 76.50130548302874,
            "timeMs": 116400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 34.34,
            "power": 74.41253263707569,
            "timeMs": 116460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.26,
            "patientTemp": 34.34,
            "power": 77.28459530026106,
            "timeMs": 116520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 34.35,
            "power": 90.98039215686278,
            "timeMs": 116580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.42,
            "patientTemp": 34.35,
            "power": 92.41830065359478,
            "timeMs": 116640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 34.35,
            "power": 93.20261437908492,
            "timeMs": 116700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 34.35,
            "power": 100,
            "timeMs": 116760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 34.35,
            "power": 100,
            "timeMs": 116820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.65,
            "patientTemp": 34.35,
            "power": 95.42483660130718,
            "timeMs": 116880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 34.36,
            "power": 89.52879581151836,
            "timeMs": 116940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 34.36,
            "power": 100,
            "timeMs": 117000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.36,
            "power": 86.64921465968582,
            "timeMs": 117060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 34.36,
            "power": 100,
            "timeMs": 117120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.92,
            "patientTemp": 34.37,
            "power": 85.84534731323726,
            "timeMs": 117180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 34.37,
            "power": 99.73787680209695,
            "timeMs": 117240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.38,
            "power": 88.05774278215227,
            "timeMs": 117300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 34.38,
            "power": 99.73753280839891,
            "timeMs": 117360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.39,
            "power": 88.04204993429701,
            "timeMs": 117420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 34.39,
            "power": 96.84625492772665,
            "timeMs": 117480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 34.4,
            "power": 89.47368421052634,
            "timeMs": 117540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 34.4,
            "power": 93.15789473684207,
            "timeMs": 117600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 34.4,
            "power": 96.05263157894741,
            "timeMs": 117660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 34.41,
            "power": 90.90909090909093,
            "timeMs": 117720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 34.41,
            "power": 100,
            "timeMs": 117780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 34.42,
            "power": 87.33509234828495,
            "timeMs": 117840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 34.42,
            "power": 100,
            "timeMs": 117900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.42,
            "power": 86.54353562005272,
            "timeMs": 117960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 34.43,
            "power": 100,
            "timeMs": 118020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.43,
            "power": 87.97886393659185,
            "timeMs": 118080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 34.43,
            "power": 99.73579920739758,
            "timeMs": 118140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.44,
            "power": 87.962962962963,
            "timeMs": 118200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 34.44,
            "power": 96.82539682539681,
            "timeMs": 118260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 34.45,
            "power": 89.4039735099338,
            "timeMs": 118320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 34.45,
            "power": 93.90728476821191,
            "timeMs": 118380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.65,
            "patientTemp": 34.46,
            "power": 95.35809018567637,
            "timeMs": 118440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 34.47,
            "power": 90.83665338645422,
            "timeMs": 118500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 34.48,
            "power": 100,
            "timeMs": 118560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.48,
            "power": 86.43617021276592,
            "timeMs": 118620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 34.49,
            "power": 100,
            "timeMs": 118680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.49,
            "power": 86.41810918774962,
            "timeMs": 118740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 34.5,
            "power": 100,
            "timeMs": 118800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 34.5,
            "power": 87.19999999999999,
            "timeMs": 118860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 34.51,
            "power": 99.73297730307073,
            "timeMs": 118920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.51,
            "power": 87.85046728971967,
            "timeMs": 118980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 34.52,
            "power": 97.45989304812838,
            "timeMs": 119040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 34.53,
            "power": 90.0937081659973,
            "timeMs": 119100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.59,
            "patientTemp": 34.53,
            "power": 94.51137884872828,
            "timeMs": 119160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 34.54,
            "power": 95.97855227882042,
            "timeMs": 119220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 34.55,
            "power": 90.06711409395972,
            "timeMs": 119280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 34.55,
            "power": 100,
            "timeMs": 119340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.56,
            "power": 86.29032258064512,
            "timeMs": 119400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 34.57,
            "power": 100,
            "timeMs": 119460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.58,
            "power": 87.73584905660383,
            "timeMs": 119520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 34.58,
            "power": 100,
            "timeMs": 119580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 34.59,
            "power": 87.71929824561407,
            "timeMs": 119640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 34.6,
            "power": 99.72972972972968,
            "timeMs": 119700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 34.61,
            "power": 89.98646820027061,
            "timeMs": 119760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 34.62,
            "power": 100,
            "timeMs": 119820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 34.62,
            "power": 86.17886178861784,
            "timeMs": 119880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 34.63,
            "power": 90.63772048846678,
            "timeMs": 119940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 34.64,
            "power": 77.85326086956518,
            "timeMs": 120000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 34.64,
            "power": 83.15217391304344,
            "timeMs": 120060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 34.65,
            "power": 73.33333333333333,
            "timeMs": 120120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 34.66,
            "power": 82.2888283378747,
            "timeMs": 120180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 34.66,
            "power": 70.16348773841966,
            "timeMs": 120240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 34.67,
            "power": 78.44474761255118,
            "timeMs": 120300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 34.68,
            "power": 66.3934426229508,
            "timeMs": 120360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 34.69,
            "power": 70.86183310533514,
            "timeMs": 120420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 34.69,
            "power": 57.865937072503456,
            "timeMs": 120480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 34.7,
            "power": 63.97260273972598,
            "timeMs": 120540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 34.71,
            "power": 50.20576131687239,
            "timeMs": 120600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.71,
            "power": 56.24142661179701,
            "timeMs": 120660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 34.72,
            "power": 43.95604395604399,
            "timeMs": 120720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.73,
            "power": 56.12104539202206,
            "timeMs": 120780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 34.73,
            "power": 43.19119669876203,
            "timeMs": 120840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 34.73,
            "power": 53.094910591471866,
            "timeMs": 120900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 34.74,
            "power": 42.28650137741049,
            "timeMs": 120960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 34.74,
            "power": 56.887052341597744,
            "timeMs": 121020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 34.74,
            "power": 59.91735537190086,
            "timeMs": 121080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 34.75,
            "power": 48.41379310344825,
            "timeMs": 121140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 34.75,
            "power": 65.24137931034478,
            "timeMs": 121200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 34.76,
            "power": 53.7292817679558,
            "timeMs": 121260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 34.76,
            "power": 67.54143646408839,
            "timeMs": 121320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 34.76,
            "power": 54.41988950276249,
            "timeMs": 121380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 34.77,
            "power": 70.53941908713688,
            "timeMs": 121440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.78,
            "power": 55.81717451523548,
            "timeMs": 121500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 34.78,
            "power": 62.04986149584484,
            "timeMs": 121560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 34.79,
            "power": 48.82108183079061,
            "timeMs": 121620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 34.8,
            "power": 58.05555555555553,
            "timeMs": 121680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 34.8,
            "power": 62.638888888888935,
            "timeMs": 121740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 34.81,
            "power": 47.98331015299022,
            "timeMs": 121800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 34.81,
            "power": 57.16272600834493,
            "timeMs": 121860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 34.82,
            "power": 61.83844011142058,
            "timeMs": 121920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 34.82,
            "power": 46.37883008356544,
            "timeMs": 121980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 34.83,
            "power": 59.41422594142265,
            "timeMs": 122040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 34.83,
            "power": 53.97489539748959,
            "timeMs": 122100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 34.84,
            "power": 61.73184357541894,
            "timeMs": 122160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 34.84,
            "power": 46.927374301676,
            "timeMs": 122220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 34.85,
            "power": 57.76223776223771,
            "timeMs": 122280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 34.85,
            "power": 61.678321678321645,
            "timeMs": 122340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 34.86,
            "power": 45.23809523809529,
            "timeMs": 122400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 34.86,
            "power": 60.784313725490236,
            "timeMs": 122460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 34.87,
            "power": 46.70406732117818,
            "timeMs": 122520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 34.88,
            "power": 52.94943820224716,
            "timeMs": 122580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 34.88,
            "power": 38.90449438202243,
            "timeMs": 122640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 34.89,
            "power": 53.58649789029539,
            "timeMs": 122700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 34.89,
            "power": 38.81856540084386,
            "timeMs": 122760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 34.9,
            "power": 49.57746478873243,
            "timeMs": 122820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 34.9,
            "power": 34.78873239436617,
            "timeMs": 122880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 34.91,
            "power": 50.35260930888573,
            "timeMs": 122940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 34.91,
            "power": 37.79971791255297,
            "timeMs": 123000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.92,
            "power": 54.94350282485878,
            "timeMs": 123060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 34.92,
            "power": 40.819209039548035,
            "timeMs": 123120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 34.92,
            "power": 57.34463276836153,
            "timeMs": 123180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 34.92,
            "power": 44.06779661016947,
            "timeMs": 123240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 34.93,
            "power": 60.39603960396044,
            "timeMs": 123300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 34.93,
            "power": 47.80763790664784,
            "timeMs": 123360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 34.94,
            "power": 65.15580736543909,
            "timeMs": 123420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 34.94,
            "power": 50.991501416430594,
            "timeMs": 123480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 34.95,
            "power": 65.81560283687948,
            "timeMs": 123540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 34.95,
            "power": 50.92198581560281,
            "timeMs": 123600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 34.96,
            "power": 63.35227272727275,
            "timeMs": 123660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 34.97,
            "power": 47.510668563300186,
            "timeMs": 123720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 34.98,
            "power": 59.401709401709404,
            "timeMs": 123780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 34.98,
            "power": 42.73504273504272,
            "timeMs": 123840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 34.99,
            "power": 55.34950071326671,
            "timeMs": 123900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 34.99,
            "power": 39.5149786019971,
            "timeMs": 123960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 34.99,
            "power": 54.493580599144096,
            "timeMs": 124020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35,
            "power": 59.28571428571426,
            "timeMs": 124080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35,
            "power": 63.14285714285717,
            "timeMs": 124140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 35.01,
            "power": 48.78397711015741,
            "timeMs": 124200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.01,
            "power": 62.37482117310441,
            "timeMs": 124260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 35.01,
            "power": 48.06866952789697,
            "timeMs": 124320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.02,
            "power": 65.47277936962755,
            "timeMs": 124380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 35.02,
            "power": 51.14613180515762,
            "timeMs": 124440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.03,
            "power": 66.28407460545192,
            "timeMs": 124500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 35.03,
            "power": 51.93687230989954,
            "timeMs": 124560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.04,
            "power": 63.79310344827582,
            "timeMs": 124620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 35.05,
            "power": 46.187050359712224,
            "timeMs": 124680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.05,
            "power": 58.992805755395686,
            "timeMs": 124740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 35.06,
            "power": 42.9394812680115,
            "timeMs": 124800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 35.06,
            "power": 58.069164265129714,
            "timeMs": 124860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 35.07,
            "power": 42.85714285714285,
            "timeMs": 124920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 35.07,
            "power": 56.42135642135637,
            "timeMs": 124980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 35.08,
            "power": 41.907514450867026,
            "timeMs": 125040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.08,
            "power": 59.537572254335316,
            "timeMs": 125100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 35.09,
            "power": 45.007235890014485,
            "timeMs": 125160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 35.09,
            "power": 60.3473227206946,
            "timeMs": 125220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 35.1,
            "power": 47.39130434782604,
            "timeMs": 125280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 35.1,
            "power": 61.014492753623216,
            "timeMs": 125340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 35.1,
            "power": 47.39130434782604,
            "timeMs": 125400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.11,
            "power": 63.42525399129168,
            "timeMs": 125460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 35.11,
            "power": 50.507982583454336,
            "timeMs": 125520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.12,
            "power": 65.84302325581395,
            "timeMs": 125580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 35.12,
            "power": 51.30813953488372,
            "timeMs": 125640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.13,
            "power": 67.39446870451233,
            "timeMs": 125700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 35.13,
            "power": 54.43959243085875,
            "timeMs": 125760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.14,
            "power": 67.34693877551017,
            "timeMs": 125820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 35.15,
            "power": 48.61313868613135,
            "timeMs": 125880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 35.16,
            "power": 60.67251461988309,
            "timeMs": 125940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 35.16,
            "power": 41.22807017543858,
            "timeMs": 126000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 35.17,
            "power": 52.56222547584183,
            "timeMs": 126060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 35.17,
            "power": 37.042459736456834,
            "timeMs": 126120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 35.18,
            "power": 51.61290322580649,
            "timeMs": 126180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 35.18,
            "power": 38.56304985337247,
            "timeMs": 126240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.18,
            "power": 54.83870967741938,
            "timeMs": 126300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35.18,
            "power": 62.17008797653961,
            "timeMs": 126360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.19,
            "power": 67.10719530102787,
            "timeMs": 126420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 35.19,
            "power": 50.80763582966226,
            "timeMs": 126480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.2,
            "power": 64.5588235294118,
            "timeMs": 126540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 35.2,
            "power": 70.29411764705877,
            "timeMs": 126600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 35.21,
            "power": 73.49042709867456,
            "timeMs": 126660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.21,
            "power": 54.63917525773198,
            "timeMs": 126720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.21,
            "power": 69.36671575846836,
            "timeMs": 126780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.22,
            "power": 57.964601769911496,
            "timeMs": 126840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 35.22,
            "power": 71.0914454277286,
            "timeMs": 126900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 35.23,
            "power": 76.66174298375188,
            "timeMs": 126960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.23,
            "power": 58.641063515509664,
            "timeMs": 127020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 35.24,
            "power": 72.63313609467453,
            "timeMs": 127080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.25,
            "power": 58.51851851851856,
            "timeMs": 127140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 35.26,
            "power": 70.91988130563797,
            "timeMs": 127200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 35.26,
            "power": 51.9287833827893,
            "timeMs": 127260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.27,
            "power": 62.55572065378894,
            "timeMs": 127320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 35.28,
            "power": 44.34523809523805,
            "timeMs": 127380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 35.28,
            "power": 55.05952380952376,
            "timeMs": 127440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 35.29,
            "power": 37.555886736214646,
            "timeMs": 127500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 35.29,
            "power": 50.819672131147584,
            "timeMs": 127560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 35.29,
            "power": 36.81073025335318,
            "timeMs": 127620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 35.3,
            "power": 52.388059701492594,
            "timeMs": 127680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 35.3,
            "power": 44.92537313432842,
            "timeMs": 127740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.3,
            "power": 58.20895522388065,
            "timeMs": 127800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 35.31,
            "power": 48.28101644245139,
            "timeMs": 127860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35.31,
            "power": 61.43497757847535,
            "timeMs": 127920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.32,
            "power": 68.86227544910182,
            "timeMs": 127980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 35.32,
            "power": 53.143712574850255,
            "timeMs": 128040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.33,
            "power": 64.76761619190403,
            "timeMs": 128100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 35.33,
            "power": 56.371814092953585,
            "timeMs": 128160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 35.34,
            "power": 67.11711711711713,
            "timeMs": 128220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 35.34,
            "power": 55.55555555555552,
            "timeMs": 128280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.34,
            "power": 68.01801801801797,
            "timeMs": 128340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.35,
            "power": 57.14285714285712,
            "timeMs": 128400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.35,
            "power": 68.72180451127822,
            "timeMs": 128460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.36,
            "power": 57.07831325301203,
            "timeMs": 128520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.36,
            "power": 68.67469879518076,
            "timeMs": 128580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.37,
            "power": 57.01357466063348,
            "timeMs": 128640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.38,
            "power": 67.82477341389723,
            "timeMs": 128700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.38,
            "power": 57.70392749244716,
            "timeMs": 128760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.39,
            "power": 66.11195158850224,
            "timeMs": 128820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 35.4,
            "power": 48.33333333333339,
            "timeMs": 128880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.41,
            "power": 56.752655538694995,
            "timeMs": 128940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 35.42,
            "power": 40.57750759878423,
            "timeMs": 129000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 35.42,
            "power": 50.75987841945284,
            "timeMs": 129060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 35.42,
            "power": 37.23404255319143,
            "timeMs": 129120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 35.43,
            "power": 49.010654490106525,
            "timeMs": 129180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 35.43,
            "power": 42.16133942161344,
            "timeMs": 129240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.43,
            "power": 53.12024353120246,
            "timeMs": 129300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 35.43,
            "power": 48.09741248097418,
            "timeMs": 129360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 35.44,
            "power": 58.99390243902444,
            "timeMs": 129420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.44,
            "power": 53.048780487804926,
            "timeMs": 129480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.44,
            "power": 63.26219512195128,
            "timeMs": 129540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 35.45,
            "power": 58.93129770992368,
            "timeMs": 129600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.45,
            "power": 68.24427480916032,
            "timeMs": 129660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 35.46,
            "power": 75.84097859327218,
            "timeMs": 129720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.46,
            "power": 61.46788990825682,
            "timeMs": 129780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.47,
            "power": 68.14701378254215,
            "timeMs": 129840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.47,
            "power": 59.7243491577335,
            "timeMs": 129900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 35.48,
            "power": 69.01840490797542,
            "timeMs": 129960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.48,
            "power": 59.66257668711654,
            "timeMs": 130020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 35.49,
            "power": 66.35944700460831,
            "timeMs": 130080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35.49,
            "power": 60.368663594470064,
            "timeMs": 130140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 35.5,
            "power": 66.30769230769235,
            "timeMs": 130200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 35.51,
            "power": 73.95993836671806,
            "timeMs": 130260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.51,
            "power": 62.86594761171038,
            "timeMs": 130320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 35.52,
            "power": 66.20370370370372,
            "timeMs": 130380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.52,
            "power": 56.018518518518476,
            "timeMs": 130440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.53,
            "power": 62.751159196290615,
            "timeMs": 130500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 35.53,
            "power": 57.65069551777431,
            "timeMs": 130560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 35.54,
            "power": 61.91950464396284,
            "timeMs": 130620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.54,
            "power": 56.65634674922606,
            "timeMs": 130680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 35.55,
            "power": 61.86046511627907,
            "timeMs": 130740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 35.55,
            "power": 53.178294573643385,
            "timeMs": 130800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.56,
            "power": 59.16149068322976,
            "timeMs": 130860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 35.56,
            "power": 53.10559006211174,
            "timeMs": 130920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 35.57,
            "power": 58.16485225505447,
            "timeMs": 130980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.58,
            "power": 52.024922118380104,
            "timeMs": 131040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 35.59,
            "power": 57.25429017160681,
            "timeMs": 131100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 35.59,
            "power": 48.51794071762872,
            "timeMs": 131160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 35.6,
            "power": 51.875000000000014,
            "timeMs": 131220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 35.61,
            "power": 38.0281690140845,
            "timeMs": 131280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 35.61,
            "power": 43.192488262910764,
            "timeMs": 131340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 35.62,
            "power": 37.93103448275863,
            "timeMs": 131400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 35.62,
            "power": 40.4388714733543,
            "timeMs": 131460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 35.62,
            "power": 42.163009404388774,
            "timeMs": 131520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 35.62,
            "power": 44.82758620689653,
            "timeMs": 131580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.63,
            "power": 60.43956043956037,
            "timeMs": 131640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.63,
            "power": 63.108320251177354,
            "timeMs": 131700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.63,
            "power": 67.34693877551021,
            "timeMs": 131760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 35.64,
            "power": 61.32075471698112,
            "timeMs": 131820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 35.64,
            "power": 61.32075471698112,
            "timeMs": 131880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.09,
            "patientTemp": 35.65,
            "power": 69.92125984251975,
            "timeMs": 131940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.65,
            "power": 64.72440944881888,
            "timeMs": 132000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.66,
            "power": 63.72239747634075,
            "timeMs": 132060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.66,
            "power": 62.93375394321766,
            "timeMs": 132120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.67,
            "power": 64.61295418641386,
            "timeMs": 132180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 35.67,
            "power": 73.30173775671409,
            "timeMs": 132240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.67,
            "power": 66.35071090047389,
            "timeMs": 132300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.68,
            "power": 67.08860759493673,
            "timeMs": 132360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.69,
            "power": 66.2440570522979,
            "timeMs": 132420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.7,
            "power": 62.69841269841267,
            "timeMs": 132480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.7,
            "power": 59.99999999999993,
            "timeMs": 132540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 35.71,
            "power": 52.94117647058821,
            "timeMs": 132600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 35.71,
            "power": 56.438791732909344,
            "timeMs": 132660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 35.72,
            "power": 48.40764331210189,
            "timeMs": 132720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.72,
            "power": 59.87261146496811,
            "timeMs": 132780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.73,
            "power": 59.808612440191354,
            "timeMs": 132840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 35.73,
            "power": 52.79106858054228,
            "timeMs": 132900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 35.73,
            "power": 65.07177033492827,
            "timeMs": 132960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.74,
            "power": 55.271565495207696,
            "timeMs": 133020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.74,
            "power": 65.97444089456864,
            "timeMs": 133080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.75,
            "power": 57.91999999999996,
            "timeMs": 133140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 35.75,
            "power": 70.39999999999998,
            "timeMs": 133200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 35.76,
            "power": 60.57692307692307,
            "timeMs": 133260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 35.76,
            "power": 70.35256410256409,
            "timeMs": 133320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.76,
            "power": 63.14102564102569,
            "timeMs": 133380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 35.77,
            "power": 70.30497592295342,
            "timeMs": 133440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.77,
            "power": 63.08186195826649,
            "timeMs": 133500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.09,
            "patientTemp": 35.78,
            "power": 69.29260450160777,
            "timeMs": 133560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 35.79,
            "power": 61.19162640901777,
            "timeMs": 133620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.8,
            "power": 62.09677419354838,
            "timeMs": 133680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 35.8,
            "power": 49.51612903225804,
            "timeMs": 133740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.81,
            "power": 62.84329563812604,
            "timeMs": 133800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 35.81,
            "power": 49.434571890145335,
            "timeMs": 133860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.82,
            "power": 61.97411003236244,
            "timeMs": 133920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 35.82,
            "power": 48.38187702265376,
            "timeMs": 133980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.83,
            "power": 62.72285251215565,
            "timeMs": 134040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 35.83,
            "power": 51.05348460291731,
            "timeMs": 134100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 35.83,
            "power": 67.26094003241488,
            "timeMs": 134160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 35.84,
            "power": 57.30519480519474,
            "timeMs": 134220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 35.84,
            "power": 70.77922077922081,
            "timeMs": 134280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35.84,
            "power": 58.11688311688312,
            "timeMs": 134340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 35.85,
            "power": 72.52032520325207,
            "timeMs": 134400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.85,
            "power": 61.788617886178834,
            "timeMs": 134460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 35.86,
            "power": 73.45276872964166,
            "timeMs": 134520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 35.86,
            "power": 77.03583061889256,
            "timeMs": 134580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.65,
            "patientTemp": 35.87,
            "power": 77.97716150081565,
            "timeMs": 134640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.87,
            "power": 63.45840130505708,
            "timeMs": 134700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.65,
            "patientTemp": 35.88,
            "power": 77.9411764705882,
            "timeMs": 134760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 35.89,
            "power": 63.33878887070373,
            "timeMs": 134820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 35.89,
            "power": 72.34042553191493,
            "timeMs": 134880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.9,
            "power": 54.098360655737764,
            "timeMs": 134940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 35.91,
            "power": 62.23316912972092,
            "timeMs": 135000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 35.92,
            "power": 43.09210526315786,
            "timeMs": 135060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.93,
            "power": 53.04777594728169,
            "timeMs": 135120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 35.93,
            "power": 36.57331136738054,
            "timeMs": 135180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 35.93,
            "power": 51.235584843492575,
            "timeMs": 135240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 35.94,
            "power": 35.47854785478556,
            "timeMs": 135300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 35.94,
            "power": 52.97029702970296,
            "timeMs": 135360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 35.94,
            "power": 57.42574257425746,
            "timeMs": 135420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 35.95,
            "power": 43.63636363636367,
            "timeMs": 135480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 35.95,
            "power": 65.61983471074382,
            "timeMs": 135540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.09,
            "patientTemp": 35.95,
            "power": 68.42975206611574,
            "timeMs": 135600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.2,
            "patientTemp": 35.95,
            "power": 53.71900826446283,
            "timeMs": 135660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 35.96,
            "power": 73.84105960264903,
            "timeMs": 135720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 35.96,
            "power": 58.27814569536418,
            "timeMs": 135780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 35.97,
            "power": 78.44112769485909,
            "timeMs": 135840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 35.97,
            "power": 64.67661691542284,
            "timeMs": 135900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 35.98,
            "power": 79.40199335548171,
            "timeMs": 135960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.98,
            "power": 60.963455149501655,
            "timeMs": 136020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 35.99,
            "power": 79.36772046589014,
            "timeMs": 136080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 35.99,
            "power": 60.89850249584023,
            "timeMs": 136140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36,
            "power": 72.8333333333333,
            "timeMs": 136200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.65,
            "patientTemp": 36,
            "power": 77.49999999999999,
            "timeMs": 136260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.01,
            "power": 61.60267111853095,
            "timeMs": 136320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.01,
            "power": 81.135225375626,
            "timeMs": 136380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 36.01,
            "power": 67.2787979966611,
            "timeMs": 136440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.92,
            "patientTemp": 36.02,
            "power": 81.9397993311037,
            "timeMs": 136500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.02,
            "power": 85.7859531772575,
            "timeMs": 136560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.37,
            "patientTemp": 36.03,
            "power": 89.44723618090448,
            "timeMs": 136620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.03,
            "power": 71.6917922948074,
            "timeMs": 136680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 36.03,
            "power": 91.28978224455606,
            "timeMs": 136740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36.04,
            "power": 72.65100671140937,
            "timeMs": 136800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.59,
            "patientTemp": 36.04,
            "power": 93.12080536912758,
            "timeMs": 136860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.54,
            "patientTemp": 36.05,
            "power": 75.46218487394958,
            "timeMs": 136920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.59,
            "patientTemp": 36.05,
            "power": 93.10924369747904,
            "timeMs": 136980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 36.06,
            "power": 78.11447811447816,
            "timeMs": 137040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.65,
            "patientTemp": 36.07,
            "power": 94.09780775716692,
            "timeMs": 137100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.54,
            "patientTemp": 36.07,
            "power": 75.37942664418212,
            "timeMs": 137160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 36.08,
            "power": 88.34459459459464,
            "timeMs": 137220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 36.08,
            "power": 91.21621621621617,
            "timeMs": 137280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.09,
            "power": 71.40439932318107,
            "timeMs": 137340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.09,
            "power": 86.46362098138752,
            "timeMs": 137400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 36.1,
            "power": 65.76271186440673,
            "timeMs": 137460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.1,
            "power": 85.59322033898302,
            "timeMs": 137520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 36.11,
            "power": 65.70458404074698,
            "timeMs": 137580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.11,
            "power": 84.55008488964351,
            "timeMs": 137640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.12,
            "power": 64.62585034013611,
            "timeMs": 137700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 36.12,
            "power": 82.65306122448976,
            "timeMs": 137760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.13,
            "power": 64.56558773424193,
            "timeMs": 137820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.14,
            "power": 80.71672354948801,
            "timeMs": 137880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.14,
            "power": 64.50511945392495,
            "timeMs": 137940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.15,
            "power": 78.80341880341878,
            "timeMs": 138000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.15,
            "power": 61.70940170940169,
            "timeMs": 138060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.81,
            "patientTemp": 36.16,
            "power": 79.62328767123293,
            "timeMs": 138120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.17,
            "power": 58.66209262435682,
            "timeMs": 138180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 36.18,
            "power": 72.85223367697597,
            "timeMs": 138240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.18,
            "power": 53.78006872852238,
            "timeMs": 138300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 36.18,
            "power": 68.21305841924396,
            "timeMs": 138360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.19,
            "power": 50.946643717728044,
            "timeMs": 138420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36.19,
            "power": 71.94492254733215,
            "timeMs": 138480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 36.2,
            "power": 57.58620689655169,
            "timeMs": 138540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.42,
            "patientTemp": 36.2,
            "power": 72.75862068965519,
            "timeMs": 138600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.2,
            "power": 78.62068965517237,
            "timeMs": 138660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.21,
            "power": 60.276338514680525,
            "timeMs": 138720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.81,
            "patientTemp": 36.21,
            "power": 79.44732297063906,
            "timeMs": 138780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.22,
            "power": 61.245674740484404,
            "timeMs": 138840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.92,
            "patientTemp": 36.22,
            "power": 81.3148788927336,
            "timeMs": 138900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 36.23,
            "power": 63.08492201039859,
            "timeMs": 138960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.23,
            "power": 80.41594454072786,
            "timeMs": 139020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.24,
            "power": 63.88888888888891,
            "timeMs": 139080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.24,
            "power": 80.3819444444444,
            "timeMs": 139140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 36.25,
            "power": 62.95652173913039,
            "timeMs": 139200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.25,
            "power": 80.34782608695647,
            "timeMs": 139260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.26,
            "power": 60.97560975609754,
            "timeMs": 139320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.26,
            "power": 80.31358885017418,
            "timeMs": 139380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.27,
            "power": 59.860383944153604,
            "timeMs": 139440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.27,
            "power": 78.35951134380448,
            "timeMs": 139500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.28,
            "power": 59.790209790209836,
            "timeMs": 139560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.28,
            "power": 78.32167832167828,
            "timeMs": 139620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.29,
            "power": 59.71978984238184,
            "timeMs": 139680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.81,
            "patientTemp": 36.29,
            "power": 79.1593695271454,
            "timeMs": 139740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.3,
            "power": 60.701754385964904,
            "timeMs": 139800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.81,
            "patientTemp": 36.3,
            "power": 79.1228070175439,
            "timeMs": 139860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.31,
            "power": 59.57820738137086,
            "timeMs": 139920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.31,
            "power": 78.20738137082597,
            "timeMs": 139980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 36.32,
            "power": 62.49999999999996,
            "timeMs": 140040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.32,
            "power": 80.10563380281685,
            "timeMs": 140100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.33,
            "power": 63.31569664903003,
            "timeMs": 140160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.33,
            "power": 78.13051146384477,
            "timeMs": 140220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.34,
            "power": 83.922261484099,
            "timeMs": 140280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 36.34,
            "power": 61.30742049469966,
            "timeMs": 140340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.35,
            "power": 79.99999999999994,
            "timeMs": 140400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 36.35,
            "power": 65.30973451327431,
            "timeMs": 140460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 36.35,
            "power": 83.0088495575221,
            "timeMs": 140520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 36.35,
            "power": 94.6902654867257,
            "timeMs": 140580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.92,
            "patientTemp": 36.36,
            "power": 98.58156028368798,
            "timeMs": 140640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.36,
            "power": 100,
            "timeMs": 140700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.92,
            "patientTemp": 36.37,
            "power": 80.81705150976913,
            "timeMs": 140760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 36.37,
            "power": 95.73712255772642,
            "timeMs": 140820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.37,
            "power": 84.90230905861455,
            "timeMs": 140880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.92,
            "patientTemp": 36.38,
            "power": 98.57651245551604,
            "timeMs": 140940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.39,
            "power": 84.84848484848482,
            "timeMs": 141000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 36.4,
            "power": 95.71428571428568,
            "timeMs": 141060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.48,
            "patientTemp": 36.41,
            "power": 72.8085867620751,
            "timeMs": 141120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.41,
            "power": 84.7942754919499,
            "timeMs": 141180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.42,
            "power": 62.72401433691758,
            "timeMs": 141240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.43,
            "power": 77.73788150807897,
            "timeMs": 141300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 36.43,
            "power": 55.83482944344702,
            "timeMs": 141360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36.44,
            "power": 70.68345323741005,
            "timeMs": 141420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.44,
            "power": 52.69784172661868,
            "timeMs": 141480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.45,
            "power": 69.54954954954957,
            "timeMs": 141540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 36.45,
            "power": 74.59459459459464,
            "timeMs": 141600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.46,
            "power": 52.52707581227432,
            "timeMs": 141660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.46,
            "power": 69.49458483754516,
            "timeMs": 141720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.46,
            "power": 77.61732851985556,
            "timeMs": 141780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 36.47,
            "power": 81.55515370705238,
            "timeMs": 141840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 36.47,
            "power": 61.48282097649182,
            "timeMs": 141900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.48,
            "power": 77.53623188405795,
            "timeMs": 141960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.48,
            "power": 62.3188405797102,
            "timeMs": 142020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.81,
            "patientTemp": 36.49,
            "power": 78.4029038112523,
            "timeMs": 142080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 36.49,
            "power": 63.33938294010883,
            "timeMs": 142140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.49,
            "power": 77.4954627949183,
            "timeMs": 142200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.5,
            "power": 69.27272727272732,
            "timeMs": 142260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 36.5,
            "power": 82.54545454545453,
            "timeMs": 142320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.54,
            "patientTemp": 36.51,
            "power": 73.40619307832422,
            "timeMs": 142380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 36.51,
            "power": 86.52094717668484,
            "timeMs": 142440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.92,
            "patientTemp": 36.51,
            "power": 98.54280510018218,
            "timeMs": 142500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.52,
            "power": 100,
            "timeMs": 142560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.52,
            "power": 83.39416058394166,
            "timeMs": 142620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 36.52,
            "power": 96.5328467153285,
            "timeMs": 142680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 36.53,
            "power": 86.47166361974402,
            "timeMs": 142740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.65,
            "patientTemp": 36.53,
            "power": 93.6014625228519,
            "timeMs": 142800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.7,
            "patientTemp": 36.53,
            "power": 94.5155393053017,
            "timeMs": 142860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.37,
            "patientTemp": 36.54,
            "power": 88.46153846153841,
            "timeMs": 142920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.54,
            "power": 0,
            "timeMs": 142980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.55,
            "power": 0,
            "timeMs": 143040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 36.55,
            "power": 0,
            "timeMs": 143100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 36.55,
            "power": 0,
            "timeMs": 143160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 36.55,
            "power": -7.295423023578351,
            "timeMs": 143220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 36.56,
            "power": -0.6932889628397115,
            "timeMs": 143280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.56,
            "power": 29.227941176470534,
            "timeMs": 143340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.56,
            "power": 57.72058823529416,
            "timeMs": 143400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 36.56,
            "power": 82.35294117647057,
            "timeMs": 143460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.56,
            "power": 99.63235294117642,
            "timeMs": 143520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 36.56,
            "power": 81.24999999999993,
            "timeMs": 143580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.56,
            "power": 100,
            "timeMs": 143640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 36.56,
            "power": 82.35294117647057,
            "timeMs": 143700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.57,
            "power": 100,
            "timeMs": 143760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.57,
            "power": 83.24125230202584,
            "timeMs": 143820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.57,
            "power": 99.63167587476974,
            "timeMs": 143880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.58,
            "power": 84.31734317343171,
            "timeMs": 143940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 36.58,
            "power": 95.57195571955715,
            "timeMs": 144000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 36.59,
            "power": 90.3881700554528,
            "timeMs": 144060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 36.59,
            "power": 91.49722735674675,
            "timeMs": 144120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.6,
            "power": 100,
            "timeMs": 144180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 36.6,
            "power": 86.29629629629626,
            "timeMs": 144240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.61,
            "power": 100,
            "timeMs": 144300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.61,
            "power": 83.11688311688317,
            "timeMs": 144360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.62,
            "power": 100,
            "timeMs": 144420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.62,
            "power": 83.08550185873614,
            "timeMs": 144480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.63,
            "power": 100,
            "timeMs": 144540000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.63,
            "power": 83.05400372439485,
            "timeMs": 144600000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.63,
            "power": 99.62756052141522,
            "timeMs": 144660000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.64,
            "power": 85.0746268656717,
            "timeMs": 144720000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.76,
            "patientTemp": 36.64,
            "power": 95.52238805970146,
            "timeMs": 144780000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.42,
            "patientTemp": 36.64,
            "power": 89.17910447761197,
            "timeMs": 144840000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 36.65,
            "power": 91.4018691588785,
            "timeMs": 144900000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.65,
            "power": 99.62616822429901,
            "timeMs": 144960000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.66,
            "power": 85.01872659176037,
            "timeMs": 145020000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.67,
            "power": 100,
            "timeMs": 145080000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.67,
            "power": 82.92682926829275,
            "timeMs": 145140000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.09,
            "patientTemp": 36.67,
            "power": 100,
            "timeMs": 145200000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.68,
            "power": 82.89473684210533,
            "timeMs": 145260000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.68,
            "power": 100,
            "timeMs": 145320000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.69,
            "power": 82.86252354048972,
            "timeMs": 145380000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.7,
            "power": 99.6226415094339,
            "timeMs": 145440000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.7,
            "power": 83.96226415094335,
            "timeMs": 145500000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 36.71,
            "power": 96.40831758034031,
            "timeMs": 145560000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.42,
            "patientTemp": 36.71,
            "power": 89.03591682419662,
            "timeMs": 145620000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 36.72,
            "power": 91.28787878787877,
            "timeMs": 145680000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 36.72,
            "power": 100,
            "timeMs": 145740000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.73,
            "power": 84.81973434535111,
            "timeMs": 145800000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.74,
            "power": 100,
            "timeMs": 145860000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.75,
            "power": 82.66666666666673,
            "timeMs": 145920000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.2,
            "patientTemp": 36.75,
            "power": 100,
            "timeMs": 145980000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.76,
            "power": 82.63358778625961,
            "timeMs": 146040000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.77,
            "power": 100,
            "timeMs": 146100000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.78,
            "power": 83.71647509578541,
            "timeMs": 146160000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 36.79,
            "power": 100,
            "timeMs": 146220000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.79,
            "power": 84.64491362763921,
            "timeMs": 146280000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 36.8,
            "power": 96.3461538461539,
            "timeMs": 146340000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.48,
            "patientTemp": 36.81,
            "power": 89.9807321772639,
            "timeMs": 146400000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.59,
            "patientTemp": 36.81,
            "power": 92.10019267822742,
            "timeMs": 146460000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 36.82,
            "power": 100,
            "timeMs": 146520000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 36.83,
            "power": 85.68665377176012,
            "timeMs": 146580000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.83,
            "power": 100,
            "timeMs": 146640000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.84,
            "power": 82.36434108527138,
            "timeMs": 146700000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.84,
            "power": 100,
            "timeMs": 146760000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.85,
            "power": 82.3300970873787,
            "timeMs": 146820000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.2,
            "patientTemp": 36.86,
            "power": 100,
            "timeMs": 146880000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.87,
            "power": 82.26120857699813,
            "timeMs": 146940000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.98,
            "patientTemp": 36.87,
            "power": 99.61013645224166,
            "timeMs": 147000000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.88,
            "power": 83.39843749999997,
            "timeMs": 147060000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 36.89,
            "power": 96.28180039138947,
            "timeMs": 147120000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.42,
            "patientTemp": 36.89,
            "power": 88.64970645792567,
            "timeMs": 147180000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.59,
            "patientTemp": 36.9,
            "power": 91.96078431372557,
            "timeMs": 147240000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.04,
            "patientTemp": 36.9,
            "power": 100,
            "timeMs": 147300000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.26,
            "patientTemp": 36.91,
            "power": 85.46168958742629,
            "timeMs": 147360000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.91,
            "power": 100,
            "timeMs": 147420000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.92,
            "power": 82.08661417322841,
            "timeMs": 147480000,
            "state": "Run",
            "controlMode": "0.30"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.93,
            "power": 100,
            "timeMs": 147540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.15,
            "patientTemp": 36.93,
            "power": 83.23471400394475,
            "timeMs": 147600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 42.15,
            "patientTemp": 36.94,
            "power": 100,
            "timeMs": 147660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.54,
            "patientTemp": 36.95,
            "power": 90.89108910891088,
            "timeMs": 147720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.81,
            "patientTemp": 36.95,
            "power": 96.23762376237627,
            "timeMs": 147780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.2,
            "patientTemp": 36.96,
            "power": 84.12698412698418,
            "timeMs": 147840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.96,
            "power": 77.57936507936503,
            "timeMs": 147900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.97,
            "power": 66.4015904572565,
            "timeMs": 147960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 36.97,
            "power": 63.22067594433398,
            "timeMs": 148020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 36.97,
            "power": 42.147117296222746,
            "timeMs": 148080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 36.98,
            "power": 36.454183266932354,
            "timeMs": 148140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 36.98,
            "power": 38.645418326693296,
            "timeMs": 148200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.98,
            "power": 24.302788844621617,
            "timeMs": 148260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 148320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.99,
            "power": 11.97604790419165,
            "timeMs": 148380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.99,
            "power": 27.54491017964064,
            "timeMs": 148440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 36.99,
            "power": 7.584830339321269,
            "timeMs": 148500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 37,
            "power": 17.39999999999995,
            "timeMs": 148560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 148620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 148680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37.01,
            "power": -0.6847439057792386,
            "timeMs": 148740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.02,
            "power": -4.983570646221249,
            "timeMs": 148800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 148860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.03,
            "power": -6.214070626882023,
            "timeMs": 148920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.03,
            "power": -9.252669039145914,
            "timeMs": 148980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.03,
            "power": -8.349301943608005,
            "timeMs": 149040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 149100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.02,
            "power": -8.625410733844484,
            "timeMs": 149160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.02,
            "power": -9.529025191675803,
            "timeMs": 149220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 149280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 149340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 149400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 149460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37,
            "power": -4.767123287671239,
            "timeMs": 149520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 149580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 149640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 149700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 149760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 149820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 149880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 149940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 150000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 150060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 150120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 150180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 150240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 150300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.98,
            "power": 0,
            "timeMs": 150360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.98,
            "power": 21.11553784860561,
            "timeMs": 150420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.98,
            "power": 25.498007968127496,
            "timeMs": 150480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 36.98,
            "power": 7.768924302788852,
            "timeMs": 150540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 150600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 150660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 36.98,
            "power": 41.035856573705196,
            "timeMs": 150720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.97,
            "power": 22.266401590457342,
            "timeMs": 150780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.97,
            "power": 46.52087475149111,
            "timeMs": 150840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.81,
            "patientTemp": 36.97,
            "power": 56.46123260437381,
            "timeMs": 150900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 36.96,
            "power": 37.896825396825335,
            "timeMs": 150960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.26,
            "patientTemp": 36.96,
            "power": 65.47619047619044,
            "timeMs": 151020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 36.96,
            "power": 72.02380952380959,
            "timeMs": 151080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.96,
            "power": 52.18253968253974,
            "timeMs": 151140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 36.96,
            "power": 72.02380952380959,
            "timeMs": 151200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 36.96,
            "power": 48.80952380952384,
            "timeMs": 151260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36.96,
            "power": 67.65873015873011,
            "timeMs": 151320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.96,
            "power": 46.62698412698416,
            "timeMs": 151380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.37,
            "patientTemp": 36.96,
            "power": 67.65873015873011,
            "timeMs": 151440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.96,
            "power": 46.62698412698416,
            "timeMs": 151500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 36.96,
            "power": 64.28571428571433,
            "timeMs": 151560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 36.96,
            "power": 72.02380952380959,
            "timeMs": 151620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.87,
            "patientTemp": 36.96,
            "power": 77.57936507936503,
            "timeMs": 151680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.04,
            "patientTemp": 36.96,
            "power": 80.95238095238093,
            "timeMs": 151740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 36.96,
            "power": 59.92063492063485,
            "timeMs": 151800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.97,
            "power": 75.34791252485086,
            "timeMs": 151860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.48,
            "patientTemp": 36.97,
            "power": 49.90059642147112,
            "timeMs": 151920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 36.97,
            "power": 36.58051689860841,
            "timeMs": 151980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.97,
            "power": 52.087475149105444,
            "timeMs": 152040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 152100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 36.98,
            "power": 42.03187250996026,
            "timeMs": 152160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 152220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 36.98,
            "power": 41.035856573705196,
            "timeMs": 152280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.98,
            "power": 21.11553784860561,
            "timeMs": 152340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 36.98,
            "power": 39.84063745019918,
            "timeMs": 152400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 152460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 36.98,
            "power": 36.454183266932354,
            "timeMs": 152520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.97,
            "power": 30.01988071570572,
            "timeMs": 152580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.98,
            "power": 46.41434262948215,
            "timeMs": 152640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.98,
            "power": 24.302788844621617,
            "timeMs": 152700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.98,
            "power": 43.227091633466145,
            "timeMs": 152760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 36.98,
            "power": 18.72509960159371,
            "timeMs": 152820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.98,
            "power": 29.880478087649383,
            "timeMs": 152880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 36.98,
            "power": 9.960159362549796,
            "timeMs": 152940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.98,
            "power": 29.880478087649383,
            "timeMs": 153000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.98,
            "power": 12.15139442231088,
            "timeMs": 153060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 153120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 36.99,
            "power": 8.582834331337324,
            "timeMs": 153180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 153240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 36.98,
            "power": 7.768924302788852,
            "timeMs": 153300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.98,
            "power": 29.880478087649383,
            "timeMs": 153360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 36.98,
            "power": 9.960159362549796,
            "timeMs": 153420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 153480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 153540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.98,
            "power": 29.880478087649383,
            "timeMs": 153600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 36.98,
            "power": 7.768924302788852,
            "timeMs": 153660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 153720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 36.98,
            "power": 9.960159362549796,
            "timeMs": 153780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 153840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.98,
            "power": 14.342629482071823,
            "timeMs": 153900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 153960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.98,
            "power": 13.34661354581676,
            "timeMs": 154020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 154080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.98,
            "power": 15.537848605577704,
            "timeMs": 154140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.97,
            "power": 28.827037773359894,
            "timeMs": 154200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 36.97,
            "power": 45.52683896620275,
            "timeMs": 154260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.98,
            "power": 25.498007968127496,
            "timeMs": 154320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 36.98,
            "power": 42.03187250996026,
            "timeMs": 154380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.99,
            "power": 11.97604790419165,
            "timeMs": 154440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.99,
            "power": 23.1536926147704,
            "timeMs": 154500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.99,
            "power": 1.9960079840319653,
            "timeMs": 154560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.98,
            "power": 21.11553784860561,
            "timeMs": 154620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 154680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 36.98,
            "power": 8.764940239043915,
            "timeMs": 154740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 36.98,
            "power": 32.07171314741047,
            "timeMs": 154800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.98,
            "power": 16.533864541832767,
            "timeMs": 154860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 36.98,
            "power": 39.84063745019918,
            "timeMs": 154920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.98,
            "power": 17.729083665338646,
            "timeMs": 154980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 36.98,
            "power": 34.26294820717141,
            "timeMs": 155040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.98,
            "power": 13.34661354581676,
            "timeMs": 155100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 155160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 36.98,
            "power": 18.72509960159371,
            "timeMs": 155220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.99,
            "power": 25.34930139720552,
            "timeMs": 155280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 36.99,
            "power": 6.387225548902204,
            "timeMs": 155340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.99,
            "power": 19.760479041916074,
            "timeMs": 155400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 155460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 155520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 155580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 155640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 155700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 155760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 155820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 155880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 155940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 156000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 156060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 36.98,
            "power": 18.72509960159371,
            "timeMs": 156120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 156180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 36.98,
            "power": 34.26294820717141,
            "timeMs": 156240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.98,
            "power": 13.34661354581676,
            "timeMs": 156300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 156360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.98,
            "power": 12.15139442231088,
            "timeMs": 156420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 156480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 36.98,
            "power": 9.960159362549796,
            "timeMs": 156540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 156600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.99,
            "power": 13.173652694610716,
            "timeMs": 156660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.99,
            "power": 24.151696606786455,
            "timeMs": 156720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 36.99,
            "power": 4.191616766467084,
            "timeMs": 156780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 156840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 36.98,
            "power": 35.45816733067729,
            "timeMs": 156900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.98,
            "power": 16.533864541832767,
            "timeMs": 156960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 157020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 157080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 157140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.97,
            "power": 46.52087475149111,
            "timeMs": 157200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.97,
            "power": 27.833001988071537,
            "timeMs": 157260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 36.97,
            "power": 45.52683896620275,
            "timeMs": 157320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.04,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.42,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 36.97,
            "power": 0,
            "timeMs": 157740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.97,
            "power": 31.21272365805169,
            "timeMs": 157800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.97,
            "power": 54.27435387673963,
            "timeMs": 157860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.96,
            "power": 58.730158730158756,
            "timeMs": 157920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.96,
            "power": 66.468253968254,
            "timeMs": 157980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.09,
            "patientTemp": 36.96,
            "power": 81.94444444444451,
            "timeMs": 158040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.96,
            "power": 66.468253968254,
            "timeMs": 158100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 36.96,
            "power": 74.20634920634926,
            "timeMs": 158160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.96,
            "power": 55.555555555555515,
            "timeMs": 158220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.2,
            "patientTemp": 36.97,
            "power": 64.21471172962234,
            "timeMs": 158280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.54,
            "patientTemp": 36.97,
            "power": 51.0934393638171,
            "timeMs": 158340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.92,
            "patientTemp": 36.97,
            "power": 58.64811133200799,
            "timeMs": 158400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.97,
            "power": 43.33996023856857,
            "timeMs": 158460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.97,
            "power": 52.087475149105444,
            "timeMs": 158520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 36.98,
            "power": 32.07171314741047,
            "timeMs": 158580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.98,
            "power": 43.227091633466145,
            "timeMs": 158640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 158700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 36.98,
            "power": 32.07171314741047,
            "timeMs": 158760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.98,
            "power": 14.342629482071823,
            "timeMs": 158820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 158880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 158940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.99,
            "power": 24.151696606786455,
            "timeMs": 159000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 37,
            "power": 8.400000000000034,
            "timeMs": 159060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 159120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 159180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 159240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 159300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 159360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 159420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 159480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 159540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 159600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 159660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 159720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.37,
            "patientTemp": 37,
            "power": 7.399999999999948,
            "timeMs": 159780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 159840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 159900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 159960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 160020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 160080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 160140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 36.99,
            "power": 6.387225548902204,
            "timeMs": 160200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 36.99,
            "power": 5.38922155688615,
            "timeMs": 160260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 160320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 160380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 36.97,
            "power": 38.76739562624259,
            "timeMs": 160440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.98,
            "power": 43.227091633466145,
            "timeMs": 160500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.87,
            "patientTemp": 36.98,
            "power": 37.64940239043823,
            "timeMs": 160560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 36.98,
            "power": 36.454183266932354,
            "timeMs": 160620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 160680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.98,
            "power": 24.302788844621617,
            "timeMs": 160740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.99,
            "power": 24.151696606786455,
            "timeMs": 160800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 160860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.99,
            "power": 14.171656686626768,
            "timeMs": 160920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.99,
            "power": 17.564870259480955,
            "timeMs": 160980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.99,
            "power": 1.9960079840319653,
            "timeMs": 161040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 36.98,
            "power": 11.155378486055815,
            "timeMs": 161100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.98,
            "power": 17.729083665338646,
            "timeMs": 161160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.98,
            "power": 23.30677290836655,
            "timeMs": 161220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.99,
            "power": 13.173652694610716,
            "timeMs": 161280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.99,
            "power": 19.760479041916074,
            "timeMs": 161340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.99,
            "power": 14.171656686626768,
            "timeMs": 161400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 161460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 36.99,
            "power": 8.582834331337324,
            "timeMs": 161520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 161580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": -0.054794520547953765,
            "timeMs": 161640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 1.8000000000000682,
            "timeMs": 161700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 161760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 161820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 161880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 161940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37.01,
            "power": -1.9172829361818564,
            "timeMs": 162000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 162060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 162120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 162180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 162240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 162300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 162360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 162420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 162480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 162540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 162600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 162660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 162720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 162780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 162840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 162900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 162960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 163020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 163080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 163140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 163200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 163260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 163320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 163380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 163440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 36.98,
            "power": 0,
            "timeMs": 163500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 163560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.98,
            "power": 25.498007968127496,
            "timeMs": 163620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.98,
            "power": 12.15139442231088,
            "timeMs": 163680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 36.98,
            "power": 29.880478087649383,
            "timeMs": 163740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.98,
            "power": 13.34661354581676,
            "timeMs": 163800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 163860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 36.98,
            "power": 39.84063745019918,
            "timeMs": 163920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.97,
            "power": 22.266401590457342,
            "timeMs": 163980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.97,
            "power": 47.71371769383694,
            "timeMs": 164040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.97,
            "power": 54.27435387673963,
            "timeMs": 164100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.59,
            "patientTemp": 36.97,
            "power": 32.20675944334005,
            "timeMs": 164160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 36.96,
            "power": 53.37301587301584,
            "timeMs": 164220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.7,
            "patientTemp": 36.96,
            "power": 74.20634920634926,
            "timeMs": 164280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 41.31,
            "patientTemp": 36.96,
            "power": 86.30952380952385,
            "timeMs": 164340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.15,
            "patientTemp": 36.96,
            "power": 63.293650793650755,
            "timeMs": 164400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.59,
            "patientTemp": 36.97,
            "power": 71.96819085487085,
            "timeMs": 164460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 36.97,
            "power": 61.033797216699796,
            "timeMs": 164520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.98,
            "patientTemp": 36.97,
            "power": 39.960238568588416,
            "timeMs": 164580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.04,
            "patientTemp": 36.97,
            "power": 61.033797216699796,
            "timeMs": 164640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 36.97,
            "power": 38.76739562624259,
            "timeMs": 164700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.97,
            "power": 55.467196819085466,
            "timeMs": 164760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 36.98,
            "power": 35.45816733067729,
            "timeMs": 164820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.97,
            "power": 46.52087475149111,
            "timeMs": 164880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.97,
            "power": 27.833001988071537,
            "timeMs": 164940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 36.97,
            "power": 48.707753479125294,
            "timeMs": 165000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 165060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 36.98,
            "power": 41.035856573705196,
            "timeMs": 165120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 36.99,
            "power": 10.978043912175597,
            "timeMs": 165180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.15,
            "patientTemp": 36.99,
            "power": 23.1536926147704,
            "timeMs": 165240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 36.99,
            "power": 3.1936127744510308,
            "timeMs": 165300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 37,
            "power": 21.800000000000068,
            "timeMs": 165360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 165420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 165480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 165540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 165600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 165660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 165720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.99,
            "power": 17.564870259480955,
            "timeMs": 165780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 165840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37.01,
            "power": -0.38345658723637516,
            "timeMs": 165900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 165960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 166020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 166080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 166140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 166200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37,
            "power": -4.000000000000002,
            "timeMs": 166260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 166320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37.01,
            "power": -2.355519035880579,
            "timeMs": 166380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37,
            "power": -4.164383561643844,
            "timeMs": 166440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 166500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 166560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 166620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 166680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 166740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 166800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": -0.35616438356165087,
            "timeMs": 166860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 1.8000000000000682,
            "timeMs": 166920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 166980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 167040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.48,
            "patientTemp": 36.99,
            "power": 9.78043912175639,
            "timeMs": 167100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.99,
            "power": 19.760479041916074,
            "timeMs": 167160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 36.99,
            "power": -0.02740476842972079,
            "timeMs": 167220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 37,
            "power": 15.19999999999996,
            "timeMs": 167280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 167340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": -0.35616438356165087,
            "timeMs": 167400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0.7999999999999831,
            "timeMs": 167460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 167520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 167580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 167640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.98,
            "power": 17.729083665338646,
            "timeMs": 167700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 167760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 36.99,
            "power": 6.387225548902204,
            "timeMs": 167820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.99,
            "power": 20.95808383233528,
            "timeMs": 167880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": -0.054794520547953765,
            "timeMs": 167940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 168000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 168060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 168120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 168180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 168240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 168300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 168360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 168420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 168480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 168540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 168600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 168660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 168720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 168780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 168840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 168900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 168960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 169020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": -1.424657534246584,
            "timeMs": 169080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 169140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 169200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 169260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 169320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 169380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 169440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 169500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 169560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 169620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 169680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 169740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 169800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 169860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 169920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 169980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37,
            "power": -4.000000000000002,
            "timeMs": 170040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 170100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 170160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 170220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 170280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 170340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 170400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 170460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 170520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 170580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 170640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 170700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 170760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 170820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 170880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 170940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.02,
            "power": -4.518072289156642,
            "timeMs": 171000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 171060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 171120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 171180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.03,
            "power": -6.378319189707085,
            "timeMs": 171240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.03,
            "power": -8.349301943608005,
            "timeMs": 171300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.02,
            "power": -8.488499452354876,
            "timeMs": 171360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 171420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 171480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 171540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 171600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 171660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 171720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 171780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 171840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 171900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 171960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.37,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 172020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.48,
            "patientTemp": 37,
            "power": -17.863013698630137,
            "timeMs": 172080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.31,
            "patientTemp": 37,
            "power": -15.589041095890416,
            "timeMs": 172140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.04,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 172200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 172260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 36.98,
            "power": 0,
            "timeMs": 172320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 36.98,
            "power": 11.155378486055815,
            "timeMs": 172380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 36.98,
            "power": 35.45816733067729,
            "timeMs": 172440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.98,
            "power": 15.537848605577704,
            "timeMs": 172500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.98,
            "power": 25.498007968127496,
            "timeMs": 172560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 172620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.99,
            "power": 30.938123752494967,
            "timeMs": 172680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 36.99,
            "power": 10.978043912175597,
            "timeMs": 172740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 36.99,
            "power": 18.562874251497007,
            "timeMs": 172800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 36.99,
            "power": 3.1936127744510308,
            "timeMs": 172860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 36.99,
            "power": 10.978043912175597,
            "timeMs": 172920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.99,
            "power": 13.173652694610716,
            "timeMs": 172980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 36.99,
            "power": 3.1936127744510308,
            "timeMs": 173040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 36.98,
            "power": 1.1952191235060206,
            "timeMs": 173100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 36.99,
            "power": 18.562874251497007,
            "timeMs": 173160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 36.99,
            "power": 8.582834331337324,
            "timeMs": 173220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 173280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 173340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 173400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 173460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 173520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 173580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 173640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 173700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 173760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 173820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": -1.424657534246584,
            "timeMs": 173880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 173940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 174000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 174060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 174120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 174180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 174240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.02,
            "power": -4.682365826944142,
            "timeMs": 174300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 174360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 174420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 174480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 174540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 174600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 174660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 174720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 174780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 174840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 174900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 174960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 175020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 175080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 175140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 175200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 175260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 175320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 175380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 175440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 175500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 175560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 175620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37.01,
            "power": -1.9172829361818564,
            "timeMs": 175680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.02,
            "power": -3.9156626506024086,
            "timeMs": 175740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 175800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 175860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 175920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 175980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 176040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.01,
            "power": -6.929608326485898,
            "timeMs": 176100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 176160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 176220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 176280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 176340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 176400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 176460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 176520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 176580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37,
            "power": -4.6301369863013635,
            "timeMs": 176640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37,
            "power": -5.232876712328758,
            "timeMs": 176700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 176760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 176820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 176880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 176940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 177000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 177060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 177120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 177180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 177240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.01,
            "power": -7.9978088195014925,
            "timeMs": 177300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37,
            "power": -6.438356164383566,
            "timeMs": 177360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 177420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 177480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.02,
            "power": -3.450164293537801,
            "timeMs": 177540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 177600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 177660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 177720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 177780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 177840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.02,
            "power": -9.227820372398696,
            "timeMs": 177900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 177960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 178020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 178080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 178140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.02,
            "power": -8.789704271631985,
            "timeMs": 178200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 178260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 178320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.02,
            "power": -8.926615553121591,
            "timeMs": 178380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.01,
            "power": -7.395234182415765,
            "timeMs": 178440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 178500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37,
            "power": -5.068493150684936,
            "timeMs": 178560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37,
            "power": -4.000000000000002,
            "timeMs": 178620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 178680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 178740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 178800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 178860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 178920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 178980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 179040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 179100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 179160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 179220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 179280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 179340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 179400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 179460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 179520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": -0.35616438356165087,
            "timeMs": 179580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": -0.054794520547953765,
            "timeMs": 179640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 179700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 179760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 179820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 179880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 179940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 180000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 180060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 180120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 180180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 180240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 180300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37.01,
            "power": -1.7529443987948523,
            "timeMs": 180360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 180420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 180480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 180540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 180600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 180660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 180720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 180780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 180840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37,
            "power": -5.068493150684936,
            "timeMs": 180900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37,
            "power": -4.000000000000002,
            "timeMs": 180960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 181020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 181080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 181140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 181200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 181260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 181320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 181380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 181440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 181500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37.01,
            "power": -0.9860312243221021,
            "timeMs": 181560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.02,
            "power": -4.216867469879535,
            "timeMs": 181620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 181680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 181740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 181800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37,
            "power": -5.369863013698632,
            "timeMs": 181860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 181920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 181980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 182040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 182100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37,
            "power": -4.465753424657541,
            "timeMs": 182160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 182220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 182280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 182340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 182400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 182460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 182520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 182580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 182640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 182700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 182760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37,
            "power": -5.068493150684936,
            "timeMs": 182820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37,
            "power": -4.164383561643844,
            "timeMs": 182880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 182940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37.01,
            "power": -2.355519035880579,
            "timeMs": 183000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 183060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.02,
            "power": -3.9156626506024086,
            "timeMs": 183120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 183180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 183240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.01,
            "power": -7.532182963571625,
            "timeMs": 183300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 183360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 183420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 183480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 183540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 183600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 183660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 183720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 183780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 183840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 183900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 183960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 184020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 184080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 184140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 184200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 184260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 184320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 184380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 184440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 184500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.02,
            "power": -4.983570646221249,
            "timeMs": 184560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 184620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 184680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 184740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 184800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 184860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 184920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 184980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 185040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.01,
            "power": -7.696521500958628,
            "timeMs": 185100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 185160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 185220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 185280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 185340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 185400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 185460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 185520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 185580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 185640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 185700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 185760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 185820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 185880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 185940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 186000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 186060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 186120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 186180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37.02,
            "power": -1.4786418400876402,
            "timeMs": 186240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.02,
            "power": -5.284775465498356,
            "timeMs": 186300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 186360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 186420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 186480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.02,
            "power": -8.789704271631985,
            "timeMs": 186540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 186600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 186660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 186720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 186780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 186840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 186900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 186960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 187020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 187080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 187140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 187200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 187260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 187320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 187380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 187440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 187500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.01,
            "power": -6.929608326485898,
            "timeMs": 187560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 187620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 187680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 187740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37,
            "power": -5.5342465753424746,
            "timeMs": 187800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37,
            "power": -4.465753424657541,
            "timeMs": 187860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 187920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 187980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 188040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 188100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 188160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 188220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 188280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 188340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 188400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 188460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 188520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 188580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 188640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.02,
            "power": -4.518072289156642,
            "timeMs": 188700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.03,
            "power": -7.445934848070077,
            "timeMs": 188760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.03,
            "power": -9.553791404325217,
            "timeMs": 188820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 188880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.03,
            "power": -9.252669039145914,
            "timeMs": 188940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.26,
            "patientTemp": 37.03,
            "power": -10.320284697508905,
            "timeMs": 189000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.02,
            "power": -9.090909090909092,
            "timeMs": 189060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 189120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.03,
            "power": -8.048179578428682,
            "timeMs": 189180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.03,
            "power": -8.650424308787308,
            "timeMs": 189240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 189300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 189360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.03,
            "power": -9.115795236791673,
            "timeMs": 189420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 189480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.02,
            "power": -10.15881708652793,
            "timeMs": 189540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.01,
            "power": -8.600383456587238,
            "timeMs": 189600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.01,
            "power": -7.395234182415765,
            "timeMs": 189660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 189720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37,
            "power": -5.232876712328758,
            "timeMs": 189780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37,
            "power": -4.328767123287666,
            "timeMs": 189840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 189900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 189960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 190020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 190080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 190140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 190200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 190260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 190320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 190380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 190440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 190500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 190560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 190620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 190680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 190740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 190800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 190860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37,
            "power": -5.835616438356171,
            "timeMs": 190920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 190980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 191040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 191100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 191160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 191220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.02,
            "power": -2.8477546549835875,
            "timeMs": 191280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 191340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 191400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 191460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 191520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37,
            "power": -4.931506849315061,
            "timeMs": 191580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 191640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 191700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37.01,
            "power": -2.2185702547247197,
            "timeMs": 191760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 191820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 191880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 191940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 192000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 192060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 192120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 192180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.02,
            "power": -3.7513691128149085,
            "timeMs": 192240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 192300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.03,
            "power": -8.048179578428682,
            "timeMs": 192360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.03,
            "power": -9.718039967150299,
            "timeMs": 192420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.02,
            "power": -9.99452354874043,
            "timeMs": 192480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.03,
            "power": -8.513550506433067,
            "timeMs": 192540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 192600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37,
            "power": -6.438356164383566,
            "timeMs": 192660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37,
            "power": -5.232876712328758,
            "timeMs": 192720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 192780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.02,
            "power": -3.313253012048195,
            "timeMs": 192840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 192900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.01,
            "power": -7.2308956450287605,
            "timeMs": 192960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 193020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 193080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 193140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 193200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 193260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 193320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 193380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 193440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37.01,
            "power": -2.0542317173377156,
            "timeMs": 193500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 193560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 193620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 193680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 193740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 193800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 193860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 193920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37,
            "power": -4.6301369863013635,
            "timeMs": 193980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 194040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 194100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37.01,
            "power": -1.7529443987948523,
            "timeMs": 194160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.02,
            "power": -5.284775465498356,
            "timeMs": 194220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 194280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 194340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.01,
            "power": -7.395234182415765,
            "timeMs": 194400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 194460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 194520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 194580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 194640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 194700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.03,
            "power": -7.883931015603621,
            "timeMs": 194760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 194820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 194880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.02,
            "power": -9.693318729463323,
            "timeMs": 194940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.01,
            "power": -8.134757600657352,
            "timeMs": 195000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 195060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 195120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 195180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 195240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 195300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 195360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 195420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 195480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 195540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 195600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 195660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 195720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37.01,
            "power": -1.615995617638993,
            "timeMs": 195780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 195840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 195900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 195960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 196020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 196080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 196140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 196200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 196260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 196320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 196380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 196440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 196500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.01,
            "power": -7.2308956450287605,
            "timeMs": 196560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 196620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 196680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 196740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.02,
            "power": -5.284775465498356,
            "timeMs": 196800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.01,
            "power": -7.395234182415765,
            "timeMs": 196860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37,
            "power": -5.835616438356171,
            "timeMs": 196920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37,
            "power": -4.6301369863013635,
            "timeMs": 196980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 197040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 197100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37.01,
            "power": -1.9172829361818564,
            "timeMs": 197160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 197220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 197280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 197340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 197400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 197460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 197520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 197580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 197640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 197700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 197760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.02,
            "power": -3.7513691128149085,
            "timeMs": 197820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 197880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.02,
            "power": -9.529025191675803,
            "timeMs": 197940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.03,
            "power": -8.048179578428682,
            "timeMs": 198000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.03,
            "power": -10.019162332329602,
            "timeMs": 198060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 198120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.59,
            "patientTemp": 37.03,
            "power": -9.416917601970978,
            "timeMs": 198180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 198240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 198300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.03,
            "power": -8.513550506433067,
            "timeMs": 198360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 198420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.03,
            "power": -8.650424308787308,
            "timeMs": 198480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 198540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.03,
            "power": -9.718039967150299,
            "timeMs": 198600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 198660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 198720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.15,
            "patientTemp": 37.03,
            "power": -10.621407062688208,
            "timeMs": 198780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 198840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.65,
            "patientTemp": 37.03,
            "power": -11.990145086230502,
            "timeMs": 198900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.04,
            "patientTemp": 37.03,
            "power": -10.922529427867511,
            "timeMs": 198960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 199020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 199080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 199140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.03,
            "power": -11.552148918696957,
            "timeMs": 199200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.03,
            "power": -10.019162332329602,
            "timeMs": 199260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.87,
            "patientTemp": 37.04,
            "power": -11.412151067323487,
            "timeMs": 199320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.31,
            "patientTemp": 37.03,
            "power": -12.920886942239251,
            "timeMs": 199380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.04,
            "power": -11.576354679802947,
            "timeMs": 199440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.26,
            "patientTemp": 37.03,
            "power": -13.057760744593494,
            "timeMs": 199500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.76,
            "patientTemp": 37.02,
            "power": -11.664841182913484,
            "timeMs": 199560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.01,
            "power": -9.969871268145715,
            "timeMs": 199620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.01,
            "power": -8.600383456587238,
            "timeMs": 199680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 199740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 199800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.98,
            "power": 14.342629482071823,
            "timeMs": 199860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 199920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 36.97,
            "power": 38.76739562624259,
            "timeMs": 199980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.97,
            "power": 47.71371769383694,
            "timeMs": 200040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.97,
            "power": 28.827037773359894,
            "timeMs": 200100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.92,
            "patientTemp": 36.98,
            "power": 38.645418326693296,
            "timeMs": 200160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.37,
            "patientTemp": 36.98,
            "power": 27.689243027888438,
            "timeMs": 200220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 36.98,
            "power": 41.035856573705196,
            "timeMs": 200280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.99,
            "power": 20.95808383233528,
            "timeMs": 200340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 36.99,
            "power": -0.02740476842972079,
            "timeMs": 200400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 200460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 36.99,
            "power": -0.3288572211564937,
            "timeMs": 200520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 200580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.48,
            "patientTemp": 37,
            "power": 29.599999999999937,
            "timeMs": 200640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": -0.5205479452054732,
            "timeMs": 200700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 200760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 36.99,
            "power": -0.3288572211564937,
            "timeMs": 200820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 200880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 36.99,
            "power": -0.493285831734721,
            "timeMs": 200940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.99,
            "power": 17.564870259480955,
            "timeMs": 201000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.98,
            "power": 2.1912350597610852,
            "timeMs": 201060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 201120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.98,
            "power": 16.533864541832767,
            "timeMs": 201180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.42,
            "patientTemp": 36.98,
            "power": 28.685258964143507,
            "timeMs": 201240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 36.98,
            "power": 35.45816733067729,
            "timeMs": 201300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 201360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 201420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 201480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 201540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.03,
            "power": -7.582808650424318,
            "timeMs": 201600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.05,
            "power": -10.232558139534872,
            "timeMs": 201660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.92,
            "patientTemp": 37.06,
            "power": -14.059080962800877,
            "timeMs": 201720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.54,
            "patientTemp": 37.06,
            "power": -17.833698030634583,
            "timeMs": 201780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.76,
            "patientTemp": 37.06,
            "power": -17.231947483588623,
            "timeMs": 201840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.15,
            "patientTemp": 37.06,
            "power": -18.900437636761495,
            "timeMs": 201900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.42,
            "patientTemp": 37.06,
            "power": -18.161925601750546,
            "timeMs": 201960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.37,
            "patientTemp": 37.05,
            "power": -18.276333789329676,
            "timeMs": 202020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.65,
            "patientTemp": 37.05,
            "power": -17.510259917920653,
            "timeMs": 202080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.26,
            "patientTemp": 37.05,
            "power": -15.841313269493835,
            "timeMs": 202140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.09,
            "patientTemp": 37.06,
            "power": -16.329321663019698,
            "timeMs": 202200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 37.07,
            "power": -17.418649165983048,
            "timeMs": 202260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.76,
            "patientTemp": 37.06,
            "power": -19.967177242888404,
            "timeMs": 202320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.04,
            "patientTemp": 37.06,
            "power": -19.20131291028447,
            "timeMs": 202380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.2,
            "patientTemp": 37.06,
            "power": -18.763676148796506,
            "timeMs": 202440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.65,
            "patientTemp": 37.05,
            "power": -17.510259917920653,
            "timeMs": 202500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.26,
            "patientTemp": 37.05,
            "power": -15.841313269493835,
            "timeMs": 202560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.7,
            "patientTemp": 37.04,
            "power": -14.614121510673234,
            "timeMs": 202620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.92,
            "patientTemp": 37.05,
            "power": -14.035567715458264,
            "timeMs": 202680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.92,
            "patientTemp": 37.04,
            "power": -14.012041598248487,
            "timeMs": 202740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.31,
            "patientTemp": 37.03,
            "power": -12.920886942239251,
            "timeMs": 202800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.76,
            "patientTemp": 37.03,
            "power": -11.689022721051199,
            "timeMs": 202860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.26,
            "patientTemp": 37.02,
            "power": -10.295728368017537,
            "timeMs": 202920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.02,
            "power": -9.090909090909092,
            "timeMs": 202980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.01,
            "power": -7.9978088195014925,
            "timeMs": 203040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.01,
            "power": -6.929608326485898,
            "timeMs": 203100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 203160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 203220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 203280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 203340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 203400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 203460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 203520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 203580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 203640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 203700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 203760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": -1.424657534246584,
            "timeMs": 203820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 203880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 203940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 204000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 204060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": -0.5205479452054732,
            "timeMs": 204120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 204180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 204240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 204300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 204360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 204420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 204480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 204540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 204600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 204660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 204720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 204780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 204840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 204900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 37,
            "power": 12.999999999999973,
            "timeMs": 204960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 205020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 205080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 37,
            "power": 11.800000000000068,
            "timeMs": 205140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 205200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": -0.35616438356165087,
            "timeMs": 205260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 1.8000000000000682,
            "timeMs": 205320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 205740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37.01,
            "power": -2.355519035880579,
            "timeMs": 205800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 205860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 205920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 205980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 206040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 206100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 206160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 206220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 206280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 206340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 206400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 206460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 206520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 206580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 206640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 206700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 206760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 206820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 206880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 206940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 207000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 207060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 207120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 207180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 207240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 207300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.02,
            "power": -4.052573932092015,
            "timeMs": 207360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 207420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.02,
            "power": -5.284775465498356,
            "timeMs": 207480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.01,
            "power": -6.929608326485898,
            "timeMs": 207540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 207600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 207660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 207720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 207780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 207840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 207900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.02,
            "power": -3.9156626506024086,
            "timeMs": 207960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 208020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 208080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 208140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 208200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 208260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 208320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 208380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37,
            "power": -4.767123287671239,
            "timeMs": 208440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 208500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.02,
            "power": -3.012048192771088,
            "timeMs": 208560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 208620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 208680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.02,
            "power": -5.1204819277108555,
            "timeMs": 208740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 208800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 208860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 208920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 208980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 209040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 209100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 209160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 209220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37.01,
            "power": -2.0542317173377156,
            "timeMs": 209280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 209340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 209400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 209460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 209520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 209580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 209640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 209700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 209760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 209820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": -0.35616438356165087,
            "timeMs": 209880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 36.99,
            "power": 3.1936127744510308,
            "timeMs": 209940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 210000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.99,
            "power": 15.369261477045834,
            "timeMs": 210060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 36.99,
            "power": -0.19183337900794817,
            "timeMs": 210120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.99,
            "power": 14.171656686626768,
            "timeMs": 210180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 210240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0.7999999999999831,
            "timeMs": 210300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 210360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 210420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 210480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 36.99,
            "power": -0.19183337900794817,
            "timeMs": 210540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.99,
            "power": 19.760479041916074,
            "timeMs": 210600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 36.99,
            "power": -0.19183337900794817,
            "timeMs": 210660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 210720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": -0.5205479452054732,
            "timeMs": 210780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0.7999999999999831,
            "timeMs": 210840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 210900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 210960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 211020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 211080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 211140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 211200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 211260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 211320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 211380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 211440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 211500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 211560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 211620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 211680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37,
            "power": -4.000000000000002,
            "timeMs": 211740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 211800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37.01,
            "power": -2.2185702547247197,
            "timeMs": 211860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 211920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 211980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.02,
            "power": -4.983570646221249,
            "timeMs": 212040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 212100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 212160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 212220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 212280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 212340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 212400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 212460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 212520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 212580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 212640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.02,
            "power": -5.284775465498356,
            "timeMs": 212700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 212760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 212820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 212880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.01,
            "power": -6.162695152013147,
            "timeMs": 212940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 213000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 213060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 213120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 213180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 213240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 213300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 213360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 213420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.02,
            "power": -8.488499452354876,
            "timeMs": 213480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 213540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 213600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 213660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 213720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 213780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 213840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.01,
            "power": -7.532182963571625,
            "timeMs": 213900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 213960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 214020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 214080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 214140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 214200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 214260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 214320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 214380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 214440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 214500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 214560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 214620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 214680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 214740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 214800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 214860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 214920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": 0,
            "timeMs": 214980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37.01,
            "power": -2.6568063544234426,
            "timeMs": 215040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 215100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37,
            "power": -4.767123287671239,
            "timeMs": 215160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 215220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 215280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 215340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.02,
            "power": -4.682365826944142,
            "timeMs": 215400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 215460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 215520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 215580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 215640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.01,
            "power": -7.833470282114488,
            "timeMs": 215700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.01,
            "power": -6.929608326485898,
            "timeMs": 215760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 215820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 215880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 215940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 216000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 216060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 216120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 216180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 216240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 216300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 216360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37,
            "power": -5.369863013698632,
            "timeMs": 216420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37,
            "power": -4.328767123287666,
            "timeMs": 216480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 216540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 216600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 216660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.03,
            "power": -9.115795236791673,
            "timeMs": 216720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.03,
            "power": -8.185053380782923,
            "timeMs": 216780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.03,
            "power": -10.019162332329602,
            "timeMs": 216840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.04,
            "power": -11.111111111111118,
            "timeMs": 216900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.54,
            "patientTemp": 37.03,
            "power": -12.291267451409805,
            "timeMs": 216960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.54,
            "patientTemp": 37.04,
            "power": -12.31527093596059,
            "timeMs": 217020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.59,
            "patientTemp": 37.04,
            "power": -12.178434592227685,
            "timeMs": 217080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.42,
            "patientTemp": 37.03,
            "power": -12.61976457705995,
            "timeMs": 217140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 217200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.03,
            "power": -9.718039967150299,
            "timeMs": 217260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.02,
            "power": -8.488499452354876,
            "timeMs": 217320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.01,
            "power": -7.696521500958628,
            "timeMs": 217380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.01,
            "power": -6.463982470556011,
            "timeMs": 217440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 217500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 217560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 217620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 217680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 217740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 217800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 217860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 217920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 217980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37,
            "power": -5.068493150684936,
            "timeMs": 218040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 218100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 218160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 218220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 218280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 218340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 218400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 218460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.02,
            "power": -8.625410733844484,
            "timeMs": 218520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 218580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 218640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37,
            "power": -6.739726027397262,
            "timeMs": 218700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 218760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37,
            "power": -4.328767123287666,
            "timeMs": 218820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 218880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37,
            "power": -2.4931506849314977,
            "timeMs": 218940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 219000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 219060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 219120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 219180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 219240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 219300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 219360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 219420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 219480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 219540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 219600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 219660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 219720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37.01,
            "power": -2.2185702547247197,
            "timeMs": 219780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37.01,
            "power": -1.9172829361818564,
            "timeMs": 219840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 219900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 219960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 220020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 220080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.76,
            "patientTemp": 36.98,
            "power": 15.537848605577704,
            "timeMs": 220140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 220200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 2.9999999999999716,
            "timeMs": 220260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 2.9999999999999716,
            "timeMs": 220320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": -1.424657534246584,
            "timeMs": 220380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 220440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 220500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 220560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 220620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 220680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 220740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 220800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 220860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 220920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 220980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 221040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 221100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 221160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 221220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 221280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 221340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": -0.6575342465753479,
            "timeMs": 221400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 221460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 221520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 221580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37.01,
            "power": -3.1224322103533297,
            "timeMs": 221640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.09,
            "patientTemp": 37.01,
            "power": -2.519857573267583,
            "timeMs": 221700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 221760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 221820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 221880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37.01,
            "power": -2.355519035880579,
            "timeMs": 221940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 222000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 222060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 222120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.02,
            "power": -4.518072289156642,
            "timeMs": 222180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 222240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 222300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 222360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 222420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 222480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 222540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 222600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 222660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37.01,
            "power": -3.2867707477403334,
            "timeMs": 222720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 222780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.02,
            "power": -4.3811610076670355,
            "timeMs": 222840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 222900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 222960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 223020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 223080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 223140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37,
            "power": -6.301369863013691,
            "timeMs": 223200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37.01,
            "power": -5.094494658997534,
            "timeMs": 223260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 223320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 223380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 223440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 223500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 223560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 223620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 37,
            "power": -3.095890410958911,
            "timeMs": 223680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 223740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 223800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 223860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 223920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 223980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.15,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 37,
            "power": 17.39999999999995,
            "timeMs": 224100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 224160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 224220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 224280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 224520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 224580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 224640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 36.99,
            "power": -0.794738284461494,
            "timeMs": 224700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.92,
            "patientTemp": 37,
            "power": 18.400000000000034,
            "timeMs": 224760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.76,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 224880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 224940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 225000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 225060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 225120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 225180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 225240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 36.99,
            "power": -0.493285831734721,
            "timeMs": 225300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.99,
            "power": 14.171656686626768,
            "timeMs": 225360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 225420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.98,
            "patientTemp": 37,
            "power": -0.054794520547953765,
            "timeMs": 225480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37.01,
            "power": -2.8211448918104662,
            "timeMs": 225540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 225600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": -3.260273972602733,
            "timeMs": 225660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 225720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 225780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 225840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 225900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 225960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.15,
            "patientTemp": 37,
            "power": -2.328767123287675,
            "timeMs": 226020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 226080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": -0.8219178082191704,
            "timeMs": 226140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37,
            "power": -3.6986301369863055,
            "timeMs": 226200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 226260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 226320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 37,
            "power": -1.260273972602742,
            "timeMs": 226380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 226440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 226500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.37,
            "patientTemp": 37,
            "power": -1.726027397260281,
            "timeMs": 226560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 226620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 226680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 37,
            "power": -2.1917808219178005,
            "timeMs": 226740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 226800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 226860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 226920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 226980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 227040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 227100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 227160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.98,
            "power": 17.729083665338646,
            "timeMs": 227220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 227280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.42,
            "patientTemp": 36.98,
            "power": 8.764940239043915,
            "timeMs": 227340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.54,
            "patientTemp": 36.98,
            "power": 31.075697211155408,
            "timeMs": 227400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.65,
            "patientTemp": 36.98,
            "power": 13.34661354581676,
            "timeMs": 227460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 227520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.99,
            "power": 11.97604790419165,
            "timeMs": 227580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.2,
            "patientTemp": 36.99,
            "power": 24.151696606786455,
            "timeMs": 227640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.09,
            "patientTemp": 36.99,
            "power": 1.9960079840319653,
            "timeMs": 227700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 227760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.7,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 227820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.48,
            "patientTemp": 37,
            "power": -1.424657534246584,
            "timeMs": 227880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37,
            "power": -4.164383561643844,
            "timeMs": 227940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37,
            "power": -3.3972602739726083,
            "timeMs": 228000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 228060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 228120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 228180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.81,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 228240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.2,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 228300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.59,
            "patientTemp": 36.99,
            "power": 11.97604790419165,
            "timeMs": 228360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 36.98,
            "power": -0.1644736842105131,
            "timeMs": 228420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.98,
            "power": 19.92031872509959,
            "timeMs": 228480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 36.98,
            "power": 34.26294820717141,
            "timeMs": 228540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.15,
            "patientTemp": 36.98,
            "power": 43.227091633466145,
            "timeMs": 228600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.04,
            "patientTemp": 36.97,
            "power": 21.272365805168985,
            "timeMs": 228660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.09,
            "patientTemp": 36.97,
            "power": 42.147117296222746,
            "timeMs": 228720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.31,
            "patientTemp": 36.97,
            "power": 26.64015904572571,
            "timeMs": 228780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.31,
            "patientTemp": 36.97,
            "power": 46.52087475149111,
            "timeMs": 228840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 36.97,
            "power": 34.39363817097423,
            "timeMs": 228900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.96,
            "power": 52.18253968253974,
            "timeMs": 228960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.48,
            "patientTemp": 36.96,
            "power": 69.84126984126976,
            "timeMs": 229020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.76,
            "patientTemp": 36.96,
            "power": 75.39682539682535,
            "timeMs": 229080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.76,
            "patientTemp": 36.96,
            "power": 55.555555555555515,
            "timeMs": 229140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.26,
            "patientTemp": 36.96,
            "power": 65.47619047619044,
            "timeMs": 229200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 36.96,
            "power": 53.37301587301584,
            "timeMs": 229260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.31,
            "patientTemp": 36.97,
            "power": 66.4015904572565,
            "timeMs": 229320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.42,
            "patientTemp": 36.97,
            "power": 48.707753479125294,
            "timeMs": 229380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.87,
            "patientTemp": 36.97,
            "power": 57.65407554671964,
            "timeMs": 229440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.7,
            "patientTemp": 36.98,
            "power": 34.26294820717141,
            "timeMs": 229500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.26,
            "patientTemp": 36.98,
            "power": 45.41832669322709,
            "timeMs": 229560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 229620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.81,
            "patientTemp": 36.98,
            "power": 36.454183266932354,
            "timeMs": 229680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.7,
            "patientTemp": 36.99,
            "power": 14.171656686626768,
            "timeMs": 229740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.26,
            "patientTemp": 36.99,
            "power": 25.34930139720552,
            "timeMs": 229800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.2,
            "patientTemp": 36.99,
            "power": 4.191616766467084,
            "timeMs": 229860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.87,
            "patientTemp": 36.99,
            "power": 17.564870259480955,
            "timeMs": 229920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.92,
            "patientTemp": 37,
            "power": -0.21917808219177617,
            "timeMs": 229980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.42,
            "patientTemp": 37,
            "power": -1.5890410958904064,
            "timeMs": 230040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 230100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 230160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 230220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 230280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 230340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 230400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 230460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 230520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.15,
            "patientTemp": 37,
            "power": -5.068493150684936,
            "timeMs": 230580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 230640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 230700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 230760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 230820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 230880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 230940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 231000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 231060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 231120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.01,
            "power": -7.2308956450287605,
            "timeMs": 231180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 231240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 231300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.02,
            "power": -4.819277108433749,
            "timeMs": 231360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 231420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 231480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 231540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 231600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.03,
            "power": -7.883931015603621,
            "timeMs": 231660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 231720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 231780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.02,
            "power": -9.529025191675803,
            "timeMs": 231840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 231900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 231960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 232020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 232080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 232140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 232200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 232260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 232320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 232380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 232440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 232500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 232560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 232620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.02,
            "power": -6.352683461117197,
            "timeMs": 232680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 232740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.01,
            "power": -7.093946863872902,
            "timeMs": 232800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 232860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 232920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 232980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 233040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 233100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 233160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 233220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 233280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 233340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 233400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 233460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 233520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 233580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 233640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 233700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 233760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 233820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 233880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 233940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 234000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 234060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.02,
            "power": -5.750273822562982,
            "timeMs": 234120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 234180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 234240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 234300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 234360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 234420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 234480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 234540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.01,
            "power": -5.56012051492742,
            "timeMs": 234600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 234660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.01,
            "power": -5.395781977540397,
            "timeMs": 234720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 234780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 234840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 234900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 234960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.01,
            "power": -6.025746370857288,
            "timeMs": 235020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 235080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 235140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 235200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 235260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 235320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 235380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.54,
            "patientTemp": 37.01,
            "power": -4.02629416598192,
            "timeMs": 235440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 235500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.02,
            "power": -4.3811610076670355,
            "timeMs": 235560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 235620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.98,
            "patientTemp": 37.02,
            "power": -5.5859802847754825,
            "timeMs": 235680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 235740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.01,
            "power": -5.861407833470285,
            "timeMs": 235800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 235860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 235920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 235980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.02,
            "power": -4.3811610076670355,
            "timeMs": 236040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 236100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.03,
            "power": -6.378319189707085,
            "timeMs": 236160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.02,
            "power": -8.789704271631985,
            "timeMs": 236220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.03,
            "power": -7.74705721324938,
            "timeMs": 236280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.03,
            "power": -9.115795236791673,
            "timeMs": 236340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.03,
            "power": -8.349301943608005,
            "timeMs": 236400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.02,
            "power": -9.693318729463323,
            "timeMs": 236460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 236520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.02,
            "power": -7.4205914567360365,
            "timeMs": 236580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.02,
            "power": -9.227820372398696,
            "timeMs": 236640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.02,
            "power": -7.8587075575027505,
            "timeMs": 236700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.01,
            "power": -6.765269789098874,
            "timeMs": 236760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 236820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 236880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 236940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.04,
            "patientTemp": 37.02,
            "power": -5.421686746987962,
            "timeMs": 237000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 237060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 237120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 237180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.03,
            "power": -7.445934848070077,
            "timeMs": 237240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 237300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.03,
            "power": -8.349301943608005,
            "timeMs": 237360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.09,
            "patientTemp": 37.02,
            "power": -10.761226725082144,
            "timeMs": 237420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.02,
            "power": -9.090909090909092,
            "timeMs": 237480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 237540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 237600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.87,
            "patientTemp": 37.02,
            "power": -5.887185104052589,
            "timeMs": 237660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 237720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 237780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.03,
            "power": -8.185053380782923,
            "timeMs": 237840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.03,
            "power": -9.718039967150299,
            "timeMs": 237900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.09,
            "patientTemp": 37.03,
            "power": -10.78565562551327,
            "timeMs": 237960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 238020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.76,
            "patientTemp": 37.04,
            "power": -11.713191023535854,
            "timeMs": 238080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.42,
            "patientTemp": 37.04,
            "power": -12.643678160919533,
            "timeMs": 238140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.26,
            "patientTemp": 37.04,
            "power": -13.081554460864808,
            "timeMs": 238200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.03,
            "power": -11.552148918696957,
            "timeMs": 238260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.31,
            "patientTemp": 37.03,
            "power": -12.920886942239251,
            "timeMs": 238320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 238380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 238440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 238500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.48,
            "patientTemp": 37.03,
            "power": -9.718039967150299,
            "timeMs": 238560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 238620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 238680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.37,
            "patientTemp": 37.04,
            "power": -12.78051450465244,
            "timeMs": 238740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.04,
            "power": -11.576354679802947,
            "timeMs": 238800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.26,
            "patientTemp": 37.03,
            "power": -13.057760744593494,
            "timeMs": 238860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.03,
            "power": -11.552148918696957,
            "timeMs": 238920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 238980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.03,
            "power": -9.553791404325217,
            "timeMs": 239040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 239100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.03,
            "power": -10.019162332329602,
            "timeMs": 239160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 239220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 239280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.03,
            "power": -9.115795236791673,
            "timeMs": 239340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 239400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.04,
            "power": -10.207991242473993,
            "timeMs": 239460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.76,
            "patientTemp": 37.03,
            "power": -11.689022721051199,
            "timeMs": 239520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.04,
            "power": -10.509031198686362,
            "timeMs": 239580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.31,
            "patientTemp": 37.04,
            "power": -12.9447181171319,
            "timeMs": 239640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.87,
            "patientTemp": 37.03,
            "power": -11.387900355871896,
            "timeMs": 239700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 239760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.03,
            "power": -9.553791404325217,
            "timeMs": 239820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.87,
            "patientTemp": 37.03,
            "power": -8.650424308787308,
            "timeMs": 239880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.92,
            "patientTemp": 37.03,
            "power": -11.251026553517656,
            "timeMs": 239940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 240000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.87,
            "patientTemp": 37.03,
            "power": -11.387900355871896,
            "timeMs": 240060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.03,
            "power": -10.019162332329602,
            "timeMs": 240120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.03,
            "power": -11.552148918696957,
            "timeMs": 240180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 240240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.65,
            "patientTemp": 37.04,
            "power": -12.014230979748223,
            "timeMs": 240300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.54,
            "patientTemp": 37.03,
            "power": -12.291267451409805,
            "timeMs": 240360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.04,
            "patientTemp": 37.03,
            "power": -10.922529427867511,
            "timeMs": 240420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.54,
            "patientTemp": 37.04,
            "power": -12.31527093596059,
            "timeMs": 240480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.04,
            "patientTemp": 37.04,
            "power": -10.946907498631637,
            "timeMs": 240540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.26,
            "patientTemp": 37.04,
            "power": -13.081554460864808,
            "timeMs": 240600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.2,
            "patientTemp": 37.04,
            "power": -13.245758073344271,
            "timeMs": 240660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.59,
            "patientTemp": 37.04,
            "power": -12.178434592227685,
            "timeMs": 240720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.87,
            "patientTemp": 37.04,
            "power": -11.412151067323487,
            "timeMs": 240780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.2,
            "patientTemp": 37.04,
            "power": -13.245758073344271,
            "timeMs": 240840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.04,
            "power": -11.576354679802947,
            "timeMs": 240900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.48,
            "patientTemp": 37.04,
            "power": -12.479474548440074,
            "timeMs": 240960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.98,
            "patientTemp": 37.04,
            "power": -13.847837985769019,
            "timeMs": 241020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.59,
            "patientTemp": 37.04,
            "power": -14.915161466885603,
            "timeMs": 241080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.15,
            "patientTemp": 37.04,
            "power": -13.38259441707718,
            "timeMs": 241140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.87,
            "patientTemp": 37.04,
            "power": -14.148877941981386,
            "timeMs": 241200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.37,
            "patientTemp": 37.04,
            "power": -12.78051450465244,
            "timeMs": 241260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.92,
            "patientTemp": 37.04,
            "power": -14.012041598248487,
            "timeMs": 241320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.37,
            "patientTemp": 37.04,
            "power": -12.78051450465244,
            "timeMs": 241380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.65,
            "patientTemp": 37.04,
            "power": -12.014230979748223,
            "timeMs": 241440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.65,
            "patientTemp": 37.05,
            "power": -14.77428180574555,
            "timeMs": 241500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.98,
            "patientTemp": 37.05,
            "power": -13.871409028727763,
            "timeMs": 241560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.31,
            "patientTemp": 37.05,
            "power": -15.704514363885085,
            "timeMs": 241620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.76,
            "patientTemp": 37.05,
            "power": -14.473324213406283,
            "timeMs": 241680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.98,
            "patientTemp": 37.05,
            "power": -16.607387140902865,
            "timeMs": 241740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.37,
            "patientTemp": 37.05,
            "power": -15.540355677154574,
            "timeMs": 241800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.54,
            "patientTemp": 37.04,
            "power": -15.0519978106185,
            "timeMs": 241860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.81,
            "patientTemp": 37.04,
            "power": -14.313081554460865,
            "timeMs": 241920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.65,
            "patientTemp": 37.04,
            "power": -14.750957854406133,
            "timeMs": 241980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.09,
            "patientTemp": 37.04,
            "power": -13.546798029556639,
            "timeMs": 242040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.48,
            "patientTemp": 37.03,
            "power": -12.455516014234886,
            "timeMs": 242100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.87,
            "patientTemp": 37.02,
            "power": -11.36363636363638,
            "timeMs": 242160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.37,
            "patientTemp": 37.01,
            "power": -9.969871268145715,
            "timeMs": 242220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.02,
            "power": -8.488499452354876,
            "timeMs": 242280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 242340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.09,
            "patientTemp": 37.02,
            "power": -8.023001095290251,
            "timeMs": 242400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.59,
            "patientTemp": 37.03,
            "power": -9.416917601970978,
            "timeMs": 242460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 242520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 242580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 242640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.76,
            "patientTemp": 37.03,
            "power": -8.951546673966611,
            "timeMs": 242700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.2,
            "patientTemp": 37.03,
            "power": -10.484533260333967,
            "timeMs": 242760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.03,
            "power": -9.252669039145914,
            "timeMs": 242820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.04,
            "power": -11.576354679802947,
            "timeMs": 242880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.31,
            "patientTemp": 37.04,
            "power": -12.9447181171319,
            "timeMs": 242940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.76,
            "patientTemp": 37.04,
            "power": -11.713191023535854,
            "timeMs": 243000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.87,
            "patientTemp": 37.05,
            "power": -14.172366621067024,
            "timeMs": 243060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.37,
            "patientTemp": 37.04,
            "power": -15.51724137931034,
            "timeMs": 243120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.04,
            "patientTemp": 37.05,
            "power": -13.70725034199726,
            "timeMs": 243180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.26,
            "patientTemp": 37.05,
            "power": -15.841313269493835,
            "timeMs": 243240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.42,
            "patientTemp": 37.05,
            "power": -15.403556771545817,
            "timeMs": 243300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.65,
            "patientTemp": 37.05,
            "power": -17.510259917920653,
            "timeMs": 243360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.2,
            "patientTemp": 37.06,
            "power": -16.028446389496725,
            "timeMs": 243420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.37,
            "patientTemp": 37.06,
            "power": -18.298687089715536,
            "timeMs": 243480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.92,
            "patientTemp": 37.07,
            "power": -19.551544982225863,
            "timeMs": 243540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.54,
            "patientTemp": 37.07,
            "power": -20.590648072190323,
            "timeMs": 243600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 28.98,
            "patientTemp": 37.07,
            "power": -22.121957888980038,
            "timeMs": 243660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.48,
            "patientTemp": 37.07,
            "power": -20.754716981132074,
            "timeMs": 243720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.09,
            "patientTemp": 37.07,
            "power": 0,
            "timeMs": 243780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.59,
            "patientTemp": 37.07,
            "power": -17.719442165709598,
            "timeMs": 243840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.7,
            "patientTemp": 37.07,
            "power": -20.153130981678974,
            "timeMs": 243900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.76,
            "patientTemp": 37.07,
            "power": -19.989062072737212,
            "timeMs": 243960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.87,
            "patientTemp": 37.07,
            "power": -19.688269073010662,
            "timeMs": 244020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.04,
            "patientTemp": 37.07,
            "power": -19.22340716434236,
            "timeMs": 244080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.65,
            "patientTemp": 37.07,
            "power": -20.289855072463773,
            "timeMs": 244140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 29.59,
            "patientTemp": 37.07,
            "power": -20.453923981405524,
            "timeMs": 244200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.09,
            "patientTemp": 37.06,
            "power": -19.064551422319482,
            "timeMs": 244260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.37,
            "patientTemp": 37.05,
            "power": -18.276333789329676,
            "timeMs": 244320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 30.7,
            "patientTemp": 37.05,
            "power": -17.373461012311896,
            "timeMs": 244380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.42,
            "patientTemp": 37.04,
            "power": -15.380405035577443,
            "timeMs": 244440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 31.98,
            "patientTemp": 37.04,
            "power": -13.847837985769019,
            "timeMs": 244500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.48,
            "patientTemp": 37.03,
            "power": -12.455516014234886,
            "timeMs": 244560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.04,
            "power": -11.111111111111118,
            "timeMs": 244620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.59,
            "patientTemp": 37.04,
            "power": -12.178434592227685,
            "timeMs": 244680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.54,
            "patientTemp": 37.04,
            "power": -12.31527093596059,
            "timeMs": 244740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.42,
            "patientTemp": 37.03,
            "power": -12.61976457705995,
            "timeMs": 244800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.81,
            "patientTemp": 37.03,
            "power": -11.552148918696957,
            "timeMs": 244860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 244920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.02,
            "power": -8.789704271631985,
            "timeMs": 244980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.02,
            "power": -7.8587075575027505,
            "timeMs": 245040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.01,
            "power": -6.628321007943015,
            "timeMs": 245100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 245160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37,
            "power": -4.6301369863013635,
            "timeMs": 245220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 245280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.98,
            "patientTemp": 37,
            "power": -2.794520547945214,
            "timeMs": 245340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.26,
            "patientTemp": 37,
            "power": -2.027397260273978,
            "timeMs": 245400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 245460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 245520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 245580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 245640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37.01,
            "power": -4.3549712407559475,
            "timeMs": 245700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.48,
            "patientTemp": 37.01,
            "power": -4.1906327033689434,
            "timeMs": 245760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.2,
            "patientTemp": 37.01,
            "power": -4.9575458778416746,
            "timeMs": 245820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37,
            "power": -3.863013698630128,
            "timeMs": 245880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37,
            "power": -2.9589041095890365,
            "timeMs": 245940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.76,
            "patientTemp": 37.01,
            "power": -3.423719528896193,
            "timeMs": 246000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 246060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.92,
            "patientTemp": 37.01,
            "power": -2.9854834291974703,
            "timeMs": 246120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.65,
            "patientTemp": 37.01,
            "power": -3.7250068474390567,
            "timeMs": 246180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.26,
            "patientTemp": 37.01,
            "power": -4.7932073404546705,
            "timeMs": 246240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.59,
            "patientTemp": 37.01,
            "power": -3.8893453848260604,
            "timeMs": 246300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.31,
            "patientTemp": 37.01,
            "power": -4.656258559298811,
            "timeMs": 246360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37.01,
            "power": -5.258833196384537,
            "timeMs": 246420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.92,
            "patientTemp": 37.01,
            "power": -5.724459052314425,
            "timeMs": 246480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.37,
            "patientTemp": 37.01,
            "power": -4.491920021911807,
            "timeMs": 246540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37,
            "power": -3.561643835616431,
            "timeMs": 246600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.04,
            "patientTemp": 37,
            "power": -2.630136986301372,
            "timeMs": 246660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.31,
            "patientTemp": 37,
            "power": -1.8904109589041034,
            "timeMs": 246720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37,
            "power": -1.1232876712328674,
            "timeMs": 246780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": -0.5205479452054732,
            "timeMs": 246840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.81,
            "patientTemp": 37,
            "power": -0.5205479452054732,
            "timeMs": 246900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.65,
            "patientTemp": 37,
            "power": -0.9589041095890449,
            "timeMs": 246960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.59,
            "patientTemp": 37.01,
            "power": -1.150369761709106,
            "timeMs": 247020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 247080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.7,
            "patientTemp": 37.01,
            "power": -3.5880580662831973,
            "timeMs": 247140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 247200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.59,
            "patientTemp": 37.02,
            "power": -6.653888280394303,
            "timeMs": 247260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 247320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.98,
            "patientTemp": 37.02,
            "power": -8.324205914567377,
            "timeMs": 247380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 247440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.04,
            "patientTemp": 37.02,
            "power": -8.159912376779857,
            "timeMs": 247500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.02,
            "power": -9.227820372398696,
            "timeMs": 247560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.02,
            "power": -7.721796276013143,
            "timeMs": 247620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.31,
            "patientTemp": 37.03,
            "power": -7.445934848070077,
            "timeMs": 247680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 247740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.65,
            "patientTemp": 37.03,
            "power": -9.252669039145914,
            "timeMs": 247800000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.31,
            "patientTemp": 37.03,
            "power": -10.183410895154664,
            "timeMs": 247860000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.26,
            "patientTemp": 37.03,
            "power": -10.320284697508905,
            "timeMs": 247920000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 32.98,
            "patientTemp": 37.03,
            "power": -11.086777990692593,
            "timeMs": 247980000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.42,
            "patientTemp": 37.03,
            "power": -9.88228852997536,
            "timeMs": 248040000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.03,
            "power": -9.553791404325217,
            "timeMs": 248100000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.15,
            "patientTemp": 37.02,
            "power": -10.596933187294646,
            "timeMs": 248160000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.7,
            "patientTemp": 37.02,
            "power": -9.090909090909092,
            "timeMs": 248220000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.02,
            "power": -7.8587075575027505,
            "timeMs": 248280000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 248340000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.81,
            "patientTemp": 37.02,
            "power": -6.051478641840089,
            "timeMs": 248400000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.2,
            "patientTemp": 37.03,
            "power": -7.74705721324938,
            "timeMs": 248460000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.03,
            "power": -7.582808650424318,
            "timeMs": 248520000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.54,
            "patientTemp": 37.03,
            "power": -9.553791404325217,
            "timeMs": 248580000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.81,
            "patientTemp": 37.03,
            "power": -8.81467287161237,
            "timeMs": 248640000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.26,
            "patientTemp": 37.03,
            "power": -10.320284697508905,
            "timeMs": 248700000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.59,
            "patientTemp": 37.02,
            "power": -9.392113910186199,
            "timeMs": 248760000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 33.92,
            "patientTemp": 37.02,
            "power": -8.488499452354876,
            "timeMs": 248820000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.02,
            "power": -7.557502738225644,
            "timeMs": 248880000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.48,
            "patientTemp": 37.02,
            "power": -6.955093099671429,
            "timeMs": 248940000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.42,
            "patientTemp": 37.02,
            "power": -7.11938663745893,
            "timeMs": 249000000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.76,
            "patientTemp": 37.02,
            "power": -6.188389923329696,
            "timeMs": 249060000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.37,
            "patientTemp": 37.02,
            "power": -7.256297918948536,
            "timeMs": 249120000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.65,
            "patientTemp": 37.02,
            "power": -6.489594742606802,
            "timeMs": 249180000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.15,
            "patientTemp": 37.02,
            "power": -7.8587075575027505,
            "timeMs": 249240000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.54,
            "patientTemp": 37.02,
            "power": -6.790799561883909,
            "timeMs": 249300000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.26,
            "patientTemp": 37.01,
            "power": -7.532182963571625,
            "timeMs": 249360000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 34.7,
            "patientTemp": 37.01,
            "power": -6.32703368940015,
            "timeMs": 249420000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.09,
            "patientTemp": 37,
            "power": -5.232876712328758,
            "timeMs": 249480000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.42,
            "patientTemp": 37,
            "power": 0,
            "timeMs": 249540000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 35.87,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 249600000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 36.54,
            "patientTemp": 36.99,
            "power": 0,
            "timeMs": 249660000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.98,
            "patientTemp": 36.98,
            "power": 19.92031872509959,
            "timeMs": 249720000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.99,
            "power": 21.956087824351332,
            "timeMs": 249780000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.31,
            "patientTemp": 36.99,
            "power": 6.387225548902204,
            "timeMs": 249840000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.04,
            "patientTemp": 36.99,
            "power": 0.9980039920159117,
            "timeMs": 249900000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.81,
            "patientTemp": 36.99,
            "power": 16.36726546906189,
            "timeMs": 249960000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 37.26,
            "patientTemp": 36.98,
            "power": 5.5776892430279075,
            "timeMs": 250020000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.09,
            "patientTemp": 36.98,
            "power": 22.111553784860675,
            "timeMs": 250080000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.65,
            "patientTemp": 36.98,
            "power": 33.26693227091635,
            "timeMs": 250140000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.04,
            "patientTemp": 36.97,
            "power": 41.15308151093439,
            "timeMs": 250200000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.97,
            "power": 47.71371769383694,
            "timeMs": 250260000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.59,
            "patientTemp": 36.97,
            "power": 52.087475149105444,
            "timeMs": 250320000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 38.76,
            "patientTemp": 36.97,
            "power": 35.586481113320055,
            "timeMs": 250380000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.65,
            "patientTemp": 36.97,
            "power": 53.28031809145127,
            "timeMs": 250440000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.98,
            "patientTemp": 36.96,
            "power": 59.92063492063485,
            "timeMs": 250500000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.26,
            "patientTemp": 36.96,
            "power": 65.47619047619044,
            "timeMs": 250560000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.37,
            "patientTemp": 36.96,
            "power": 47.81746031746026,
            "timeMs": 250620000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.65,
            "patientTemp": 36.96,
            "power": 73.21428571428568,
            "timeMs": 250680000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 40.98,
            "patientTemp": 36.96,
            "power": 79.7619047619047,
            "timeMs": 250740000,
            "state": "Run",
            "controlMode": "MAX"
        },
        {
            "targetTemp": 37,
            "bathTemp": 39.7,
            "patientTemp": 36.97,
            "power": 54.27435387673963,
            "timeMs": 250800000,
            "state": "Run",
            "controlMode": "MAX"
        }
    ],
    "events": [] as any[],
    "phases": [
        {
            "phaseName": "Induction",
            "startIndex": 139,
            "endIndex": 214
        },
        {
            "phaseName": "Maintenance",
            "startIndex": 214,
            "endIndex": 1661
        },
        {
            "phaseName": "Rewarming",
            "startIndex": 1661,
            "endIndex": 2439
        },
        {
            "phaseName": "Normothermia",
            "startIndex": 2439,
            "endIndex": 4180
        }
    ],
    "files": [
        "Q33-008.csv"
    ],
    "headPadConnected": false,
    "torsoPadConnected": false,
    "thighPadConnected": false,
    "id": "d64fca39-637d-4c9a-b5f4-1c04028e6b37",
    "rawCaseMode": "Cooling",
    "documentKeys": []
};

function _convertDateWrapper(rawDateWrapper: {
    _rawValue: string,
    _containsTimeZone: boolean,
    _date: string,
}): DateWrapper {
    return new DateWrapper(rawDateWrapper._rawValue);
}

function _convertPercent(rawPercent: {
    _percentScalar: number
}): Percent {
    return new Percent(rawPercent._percentScalar * 100);
}

export const tgxpDemoCaseData: CaseData = {
    ...rawCaseData,
    caseMode: getCaseMode(rawCaseData.caseMode),
    caseDateTime: _convertDateWrapper(rawCaseData.caseDateTime),
    timeWithinRange02: _convertPercent(rawCaseData.timeWithinRange02),
    timeWithinRange05: _convertPercent(rawCaseData.timeWithinRange05),
    timeWithinRange10: _convertPercent(rawCaseData.timeWithinRange10),
    timeWithinRange20: _convertPercent(rawCaseData.timeWithinRange20),
    events: rawCaseData.events.map((rawEvent) => {
        return {
            ...rawEvent,
            eventType: rawEvent.eventType as PlotEventType
        };
    }),
    phases: rawCaseData.phases.map((rawPhase) => {
        return {
            ...rawPhase,
            phaseName: rawPhase.phaseName as PlotPhaseName
        };
    })
};