import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'ErrorModule', namespaced: true })
export default class ErrorModule extends VuexModule {
  public isErrorState: boolean = false;
  public redirected: boolean = false;
  public statusCode: number = 500;

  @Mutation
  public showErrorPage(data: {
      statusCode: number,
      redirected: boolean
  }): void {
    this.isErrorState = true;
    this.redirected = data.redirected;
    this.statusCode = data.statusCode;
  }

  @Mutation
  public clearErrorPage(): void {
    this.isErrorState = false;
    this.redirected = false;
    this.statusCode = 500;
  }
}