
import { ToastErrorEvent, eventService, EventUnsubscribe, FatalErrorEvent } from '@/services';
import { Component, Vue } from 'vue-property-decorator';
import { errorStore, labelsStore } from '@/store';

@Component({})
export default class AppError extends Vue {

  private _unsubscribeFatalError!: EventUnsubscribe;
  private _unsubscribeToastError!: EventUnsubscribe;

  public errorText: string = '';
  public timeout: number = 2000;
  public snackbar: boolean = false;

  public mounted(): void {
    this._unsubscribeFatalError = eventService.subscribe(FatalErrorEvent, (error) => this._onFatalError(error));
    this._unsubscribeToastError = eventService.subscribe(ToastErrorEvent, (error) => this._onToastError(error));
  }

  public beforeDestroy(): void {
    this._unsubscribeFatalError();
    this._unsubscribeToastError();
  }

  private _onFatalError(error: FatalErrorEvent): void {
    errorStore.showErrorPage({
      statusCode: error.statusCode,
      redirected: error.redirected
    });
  }

  private _onToastError(error: ToastErrorEvent): void {
    this.errorText = error.message || labelsStore.labels.common.text.somethingWentWrong;
    this.snackbar = true;
  }
}
