import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=447c5bca&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=ts"
export * from "./ErrorPage.vue?vue&type=script&lang=ts"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=447c5bca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447c5bca",
  null
  
)

export default component.exports