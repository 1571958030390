export function throttleFunction(method: () => void, throttleTimeMs: number): () => void {
  let lastExecutedTime: number = 0;
  let timeout: number = 0;

  const result: () => void = () => {
    if (timeout) {
      // already waiting for a timeout, don't do anything
      return;
    }
    // might be able to run, check how long it has been
    const now = (new Date()).getTime();
    const timeSinceLastExecuted = now - lastExecutedTime;
    const timeToWait = throttleTimeMs - timeSinceLastExecuted;
    if (timeToWait <= 0) {
      lastExecutedTime = now;
      method();
    } else {
      timeout = setTimeout(() => {
        timeout = 0;
        result();
      }, timeToWait);
    }
  };

  return result;
}