
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AppButton from './AppButton.vue';
import TermsAndConditionsDoc from './TermsAndConditionsDoc.vue';

@Component({
  components: {
    AppButton,
    TermsAndConditionsDoc
  }
})
export default class TermsAndConditionsDialog extends Vue {

    @Prop({ required: true })
    public dialog!: boolean;

    @Emit()
    public accepted(): void {
    }
}

