
const containsTimeZoneRegex = /(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/;
const isDateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

export class DateWrapper {
  private _rawValue: string;
  private _containsTimeZone: boolean;
  private _date: Date;

  constructor(date?: string) {
    date = date ?? getCurrentDateTime();
    this._rawValue = date;
    this._containsTimeZone = containsTimeZoneRegex.test(this._rawValue);
    let dateValue = this._rawValue;
    if (date.endsWith('AM')) {
      const date = new Date(dateValue.replace('AM', ''));
      if (date.getHours() == 12) {
        // 12AM is 0 hours
        date.setHours(0);
      }
      this._date = date;
    } else if (date.endsWith('PM')) {
      const date = new Date(dateValue.replace('PM', ''));
      if (date.getHours() != 12) {
        date.setHours(date.getHours() + 12);
      }
      this._date = date;
    } else {
      if (isDateOnlyRegex.test(dateValue)) {
        dateValue = dateValue + ' 00:00:00';
      }
      this._date = new Date(dateValue);
    }
  }

  public canDisplay(): boolean {
    return !this._containsTimeZone && !this.isNaN();
  }

  public isNaN(): boolean {
    return isNaN(this._date.getTime());
  }

  public plusMilliseconds(msToAdd: number): DateWrapper {
    const newDate = new Date(this._date.getTime() + msToAdd);

    if (this._containsTimeZone) {
      // we have a timezone, use UTC
      return new DateWrapper(newDate.toISOString());
    } else {
      // no timezone, format as such
      const newDateString = formatDateTimeWithoutTimeZone(newDate);
      return new DateWrapper(newDateString);
    }
  }

  public minus(date: DateWrapper): number {
    if (this._containsTimeZone !== date._containsTimeZone) {
      // cannot compare these two values
      return NaN;
    }
    return this._date.getTime() - date._date.getTime();
  }

  public isAfter(date: DateWrapper): boolean {
    return this.minus(date) >= 0;
  }

  public isBefore(date: DateWrapper): boolean {
    return this.minus(date) <= 0;
  }

  public toDisplayDate(): string {
    if (this.isNaN()) {
      return '';
    }
    if (this._containsTimeZone) {
      return 'Invalid';
    }
    const year = this._date.getFullYear();
    const month = (this._date.getMonth() + 1).toString().padStart(2, '0');
    const day = this._date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public toDisplayTime(use12HourClock: boolean): string {
    if (this.isNaN()) {
      return '';
    }
    const rawHours = this._date.getHours();
    let hours = rawHours.toString().padStart(2, '0');
    const minutes = this._date.getMinutes().toString().padStart(2, '0');

    let amPmSuffix = '';
    if (use12HourClock) {
      amPmSuffix = rawHours >= 12 ? ' PM' : ' AM';
      let non24Hours = rawHours % 12;
      non24Hours = non24Hours == 0 ? 12 : non24Hours;
      hours = non24Hours.toString().padStart(2, '0');
    }

    return `${hours}:${minutes}${amPmSuffix}`;
  }

  public toDisplayDateTime(use12HourClock: boolean): string {
    if (this.isNaN()) {
      return '';
    }
    if (this._containsTimeZone) {
      return 'Invalid';
    }
    const year = this._date.getFullYear();
    const month = (this._date.getMonth() + 1).toString().padStart(2, '0');
    const day = this._date.getDate().toString().padStart(2, '0');
    const rawHours = this._date.getHours();
    let hours = rawHours.toString().padStart(2, '0');
    const minutes = this._date.getMinutes().toString().padStart(2, '0');
    let amPmSuffix = '';
    if (use12HourClock) {
      amPmSuffix = rawHours >= 12 ? ' PM' : ' AM';
      let non24Hours = rawHours % 12;
      non24Hours = non24Hours == 0 ? 12 : non24Hours;
      hours = non24Hours.toString().padStart(2, '0');
    }

    return `${year}-${month}-${day} ${hours}:${minutes}${amPmSuffix}`;
  }

  public toSaveDateTime(): string {
    if (this.isNaN()) {
      return '';
    }
    if (this._containsTimeZone) {
      // we have a timezone, use UTC
      return this._date.toISOString();
    } else {
      // no timezone, format as such
      return formatDateTimeWithoutTimeZone(this._date);
    }
  }
}
(<any>window).DateWrapper = DateWrapper;


function getCurrentDateTime(): string {
  return formatDateTimeWithoutTimeZone(new Date());
}

function formatDateTimeWithoutTimeZone(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}