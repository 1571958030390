
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Loader extends Vue {

  @Prop({ default: false })
  public active!: boolean;

  @Prop({ default: 100 })
  public spinnerSize!: number;
}
