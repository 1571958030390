export const brainCoolDemoQuestionnaireData = {
    "caseType": "Neurogenic Fever",
    "peripheralType": "",
    "patientWeight": "200-229 lbs",
    "patientMassUnit": "lbs",
    "patientOutcome": "1 (Good Cerebral Performance)",
    "patientOutcomeUnit": "CPC",
    "customCaseType": "",
    "hospitalArrivalTime": "",
    "documentKeys": []
};