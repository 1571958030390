
import { AllLabels } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ProductNavigation extends Vue {

  // used to trigger a render when the labels change
  public componentKey: number = 0;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  @Watch('labels')
  protected onLabelsChanged() {
    this.componentKey++;
  }
}
