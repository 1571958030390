
import { Component, Vue } from 'vue-property-decorator';
import AppButton from './AppButton.vue';

@Component({
  components: {
    AppButton
  }
})
export default class TermsAndConditionsDoc extends Vue {
}

