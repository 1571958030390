
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { initialiseStores } from './utils/StoreAccessor';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'

async function init() {
  
  Vue.config.productionTip = false;
  Vue.use(VueRouter);
  
  const app = new Vue({
    store: store,
    vuetify: vuetify,
    router: router,
    render: (createElement) => createElement(App),
  });
  initialiseStores(app.$store);
  app.$mount('#app');
}

init();
