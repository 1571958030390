import Vue from "vue";
import { BaseEvent } from "./events/BaseEvent";

class EventService {
    private $eventBus: Vue = new Vue();

    public subscribe<TEvent extends BaseEvent>(
        eventClass: new () => TEvent,
        handler: (x: TEvent) => void
    ): () => void {
        const eventName = (new eventClass()).name;
        this.$eventBus.$on(eventName, handler);
        return () => {
            this.$eventBus.$off(eventName, handler);
        }
    }

    public publish<TEvent extends BaseEvent>(event: TEvent): void {
        this.$eventBus.$emit(event.name, event);
    }
}

export const eventService = new EventService();