import { ApiError } from "@/utils/ApiError";
import { EventName } from "../EventName";
import { BaseEvent } from "./BaseEvent";

export class FatalErrorEvent extends BaseEvent {

    public statusCode: number;
    public redirected: boolean;

    constructor(
        error?: any
    ) {
        super();
        if (error instanceof ApiError) {
            this.statusCode = error.response.status || 500;
            this.redirected = error.redirected;
        } else {
            // unknown exception, just display 500
            this.statusCode = 500;
            this.redirected = false;
        }
    }

    public get name(): EventName {
        return EventName.FatalError;
    }
}