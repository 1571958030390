
import { CaseData, QuestionnaireResult } from '@/interfaces';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';


@Module({ name: 'QuestionnaireModule', namespaced: true })
export default class QuestionnaireModule extends VuexModule {
    public questionnaireResults: { [key: string]: QuestionnaireResult; } = {};

    @Mutation
    public setQuestionnaireResult(data: {
        caseData: CaseData,
        result: QuestionnaireResult
    }): void {
        this.questionnaireResults[data.caseData.id] = data.result;
    }
    
    public get getQuestionnaireResult() {
        return function(caseData: CaseData): QuestionnaireResult | undefined {
            return this.questionnaireResults[caseData.id] || undefined;
        }
    }
}
