import { DemoType } from "@/enums";
import { demoStore, questionnaireStore, uploadStore } from "@/store";

export class DemoService {
  public changeDemoType(demoType: DemoType): void {
    demoStore.setDemoType(demoType);
    questionnaireStore.setQuestionnaireResult({
        caseData: demoStore.caseData,
        result: demoStore.questionnaireResult
    });
    uploadStore.selectCase(demoStore.caseData);
  }

  public showDemo(): void {
    demoStore.setDemoActive(true);
    // call changeDemoType() because it updates the other stores
    this.changeDemoType(demoStore.demoType);
    uploadStore.showResultsPage();
  }
  public hideDemo(): void {
    demoStore.setDemoActive(false);
    uploadStore.showUploadPage();
  }
}

export const demoService = new DemoService();