import { settingsStore, rawStore } from '@/store';
import UploadModule from '@/store/modules/UploadModule';
import { getModule } from 'vuex-module-decorators';

class MonitorService {

  private _initialized: boolean = false;

  public initialize(): void {
    if (this._initialized) {
      return;
    }
    rawStore.watch((_) => getModule(UploadModule, rawStore).selectedCase, async (newSelectedCase, _oldSelectedCase) => {
      settingsStore.setCaseData(newSelectedCase);
    });
    this._initialized = true;
  }

  
}

export const monitorService = new MonitorService();