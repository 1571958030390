import { brainCoolDemoCaseData, brainCoolDemoQuestionnaireData, tgxpDemoCaseData, tgxpDemoQuestionnaireData } from '@/data';
import { DemoType } from '@/enums';
import { CaseData, QuestionnaireResult } from '@/interfaces';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';


@Module({ name: 'DemoModule', namespaced: true })
export default class DemoModule extends VuexModule {
  public isDemoActive: boolean = false;
  public demoType: DemoType = DemoType.TGXP;
  public showDemoDialog: boolean = true;

  public get caseData(): CaseData {
    switch (this.demoType) {
      case DemoType.TGXP:
        return tgxpDemoCaseData;
      case DemoType.BrainCool:
        return brainCoolDemoCaseData;
      default:
        // should not happen, but return tgxp data
        return tgxpDemoCaseData;
    }
  }

  public get questionnaireResult(): QuestionnaireResult {
    switch (this.demoType) {
      case DemoType.TGXP:
        return tgxpDemoQuestionnaireData;
      case DemoType.BrainCool:
        return brainCoolDemoQuestionnaireData;
      default:
        // should not happen, but return tgxp data
        return tgxpDemoQuestionnaireData;
    }
  }

  @Mutation
  public setDemoActive(active: boolean): void {
    this.isDemoActive = active;
  }

  @Mutation
  public setDemoType(demoType: DemoType): void {
    this.demoType = demoType;
  }

  @Mutation
  public setShowDemoDialog(showDemoDialog: boolean): void {
    this.showDemoDialog = showDemoDialog;
  }
}
