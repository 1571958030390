
import { BUTTON_BACKGROUND_COLOR, BUTTON_TEXT_COLOR, BUTTON_HEIGHT} from '@/utils/Constants';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppButton extends Vue {

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public inverted!: boolean;

  @Prop({ default: '' })
  public color!: string;

  @Prop({ default: ''})
  public textColor!: string;

  @Prop({ default: ''})
  public height!: string;

  @Prop({ default: '0px 27px'})
  public padding!: string;

  @Prop({ default: false })
  public captcha!: boolean;

  @Emit()
  public click(): void {
  }

  public onClick(): void {
    if (!this.disabled) {
      this.click();
    }
  }

  public get cssVars(): {[key: string]: string} {
    const result = {
      '--app-button-color': (this.color ? this.color : BUTTON_BACKGROUND_COLOR),
      '--app-button-text-color': (this.textColor ? this.textColor : BUTTON_TEXT_COLOR),
      '--app-button-height': (this.height ? this.height : BUTTON_HEIGHT),
      'padding': this.padding
    };
    return result;
  }
}
