export class ApiError extends Error {
  constructor(
    public response: Response,
    public redirected: boolean
  ) {
    super('api error');
    
    // Set the prototype explicitly
    // Object.setPrototypeOf(this, ApiError.prototype);
  }
}