
// disable other languages for release 2.0

import { CaseMode, PlotEventType, PlotPhaseName } from "@/enums";
import { CaseData } from "@/interfaces";

//const translatedLanguages = ['en', 'de', 'ja', 'zu'];
const translatedLanguages = ['en', 'ja'];

export function delay(timeMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs);
  });
}

export function browserLocale(): string[] {
  let language: string[];
  if (window.navigator.languages) {
    language = window.navigator.languages.slice(0);
  } else {
    // userLanguage is IE only and it's set in windows Control Panel - Regional Options (NOT browser language)
    // https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
    language = [(<any>window.navigator).userLanguage || window.navigator.language];
  }
  return language;
}

export function languagePreference(languageOptions: string[]): string {
  for (let i = 0; i< languageOptions.length; i++) {
    const languageOption = languageOptions[i];
    // also try looking for just the first 2 characters (i.e. 'en' instead of 'en-US')
    const shortLanguageOption = languageOption.substring(0, 2);
    const cmsTranslation = translatedLanguages.find((x) => x === languageOption || x === shortLanguageOption);
    if (cmsTranslation) {
      return cmsTranslation;
    }
  }
  // not found, default to english
  return 'en';
}

export async function retry<T>(method: () => Promise<T>, maxRetryCount: number): Promise<T> {
  let retryCount = 0;
  let shouldRetry = false;
  do
  {
    try {
      return await method();
    } catch (e) {
      retryCount++;
      shouldRetry = retryCount <= maxRetryCount;
      if (!shouldRetry) {
        // already retried the max number of times
        // rethrow the last exception
        throw e;
      }
    }
  } while (shouldRetry);
  // we will never reach here
  throw new Error();
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function getCaseMode(caseModeString: string): CaseMode {
  switch (caseModeString) {
    case 'Cooling':
      return CaseMode.Cooling;
    case 'Warming':
      return CaseMode.Warming;
    case 'Normothermia':
    case 'NormothermiaWarming':
    case 'NormothermiaCooling':
      return CaseMode.Normothermia;
    case 'Unkown':
    default:
      return CaseMode.Unknown;
  }
}

export function getPlotEventType(eventType: string): PlotEventType {

  switch (eventType) {
    case 'Alarm':
      return PlotEventType.Alarm;
    case 'Alert':
      return PlotEventType.Alert;
    case 'AlertClear':
      return PlotEventType.AlertClear;
    case 'Malfunction':
      return PlotEventType.Malfunction;
    case 'TcMalfunction':
      return PlotEventType.TcMalfunction;
    case 'ProbeCalibration':
      return PlotEventType.ProbeCalibration;
    case 'TimeChange':
      return PlotEventType.TimeChange;
    case 'Misc':
      return PlotEventType.Misc;
    case 'Error':
      return PlotEventType.Error;
    default:
      return PlotEventType.Error;
  }
}

export function formatDuration(elapsedMs: number): string {
  const MS_PER_HOUR = 1000 * 60 * 60;
  const MS_PER_MINUTE = 1000 * 60;
  const hours = Math.floor(+elapsedMs / MS_PER_HOUR);
  const minutes = Math.floor((+elapsedMs % MS_PER_HOUR) / MS_PER_MINUTE);
  const pieces: string[] = [];
  pieces.push(hours + 'h');
  if (minutes !== 0) {
    pieces.push(minutes + 'm');
  }
  return pieces.join('');
}

export function graphElapsedTimeFormat(elapsedMs: d3.NumberValue): string {
  const MS_PER_HOUR = 1000 * 60 * 60;
  const MS_PER_MINUTE = 1000 * 60;
  const hours = Math.floor(+elapsedMs / MS_PER_HOUR);
  const minutes = Math.floor((+elapsedMs % MS_PER_HOUR) / MS_PER_MINUTE);
  const pieces: string[] = [];
  pieces.push(hours + 'h');
  if (minutes !== 0) {
    pieces.push(minutes + 'm');
  }
  return pieces.join('');
}

export function convertMsToMinutes(
  valueMs: number,
): string {
  return Math.floor(valueMs / (1000 * 60)).toString();
}

export function getPlotPhaseName(phaseName: string): PlotPhaseName {

  switch (phaseName) {
    case 'Induction':
      return PlotPhaseName.Induction;
    case 'Maintenance':
      return PlotPhaseName.Maintenance;
    case 'Rewarming':
      return PlotPhaseName.Rewarming;
    case 'Normothermia':
      return PlotPhaseName.Normothermia;
    case 'Unknown':
      return PlotPhaseName.Unknown;
    default:
      return PlotPhaseName.Unknown;
  }
}

export function isThermogardDevice(caseData: CaseData): boolean {
  const deviceType = caseData.deviceType;
  return deviceType.includes('Thermogard') || deviceType.includes('TGHQ');
}

export function isBrainCoolDevice(caseData: CaseData): boolean {
  const deviceType = caseData.deviceType;
  return deviceType.includes('BrainCool');
}